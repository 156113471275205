import React, { useEffect, useState } from "react";

// assests
import svgs1 from "../../assests/website/home-back-svg.svg";
import curveWhite from "../../assests/website/curve-bottom-white.svg";
import black from "../../assests/website/black-h-p-w.svg";
import white from "../../assests/website/white-h-p-w.svg";

// imports
import JoinOurGreen from "../../components/website/JoinOurGreen";
import EverySearchYou from "../../components/website/EverySearchYou";
import PapRewards from "../../components/website/PapRewards";
import WeInvestInAllGeo from "../../components/website/WeInvestInAllGeo";
import HowPapWorksHome from "../../components/website/HowPapWorksHome";
import HomePage from "./HomePage";

import curve from "../../assests/website/home-curve.svg";
import { useParams, useSearchParams } from "react-router-dom";
import HandleLanguageChange from "../../globals/handleLanguageChange";

const Home = ({ theme, setIsPlaying }) => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  // handle language change

  HandleLanguageChange();

  // useEffect(() => {
  //   console.log("clearing local storage");
  //   localStorage.clear();
  // }, []);
  // console.log("params", params["*"]);
  // console.log("params2", searchParams.get("q"));
  // const [theme, setTheme] = useState(false);
  return (
    <div className="">
      {/* home */}
      <div>
        {/* hero */}
        <div className="">
          <div
            className={
              theme ? " relative  bg-[#F7F7F7]" : "relative bg-[#1A1C1E]"
            }
          >
            {/* absolute svg */}
            <div className="absolute hidden sm:block right-[0] top-[-50px]">
              <img src={svgs1} alt=""></img>
            </div>
            <HomePage theme={theme} setIsPlaying={setIsPlaying} />
          </div>
          {/* curve */}
          <div className=" w-full">
            {theme ? (
              <img className="w-full" src={curveWhite} alt=""></img>
            ) : (
              <img className="w-full" src={curve} alt=""></img>
            )}
          </div>
        </div>

        <div className="relative border border-[transparent]">
          {/* absolute image */}
          <div className="absolute top-[-70px]  w-full">
            <img
              className="w-full object-cover"
              src={theme ? white : black}
              alt=""
            ></img>
          </div>
          <div className=" max-w-[1200px] mx-[24px]  xl:mx-auto  relative">
            {/*how pap works  */}

            <HowPapWorksHome theme={theme} />
          </div>
        </div>

        <div className=" max-w-[1200px] mx-[24px]  xl:mx-auto  relative">
          {/* we invest in all geographies */}
          <WeInvestInAllGeo theme={theme} />
        </div>

        {/* Every Search You */}
        <EverySearchYou theme={theme} />

        {/* pap rewards */}
        <PapRewards theme={theme} />

        {/* Join Our Green */}
        <JoinOurGreen
          words={
            " Join our Green Revolution and Game Your Way to a Brighter, Greener Future"
          }
          theme={theme}
        />
      </div>
    </div>
  );
};

export default Home;
