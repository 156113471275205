import React, { useState } from "react";
// assets
import image from "../../assests/website/forgot-pass-pic.png";
// components
import LoginFlexComp from "../../components/webapp/LoginFlexComp";
import LoginFlexLeftComp from "../../components/webapp/LoginFlexLeftComp";
import LoginFlexRightComp from "../../components/webapp/LoginFlexRightComp";
import LoginComp from "../../components/webapp/LoginComp";
import InputComp from "../../components/webapp/InputComp";
import FormButtonComp from "../../components/webapp/FormButtonComp";
import ResetPassComp from "../../components/webapp/ResetPassComp";
import CreateAccSuccessComp from "../../components/webapp/CreateAccSuccessComp";
import PasswordInputComp from "../../components/webapp/PasswordInputComp";
import CreateNewPassword from "../../components/webapp/CreateNewPassword";

const ResetPassword = ({ theme }) => {
  const [success, setSuccess] = useState(false);
  return (
    <LoginFlexComp>
      <LoginFlexLeftComp image={image} />
      <LoginFlexRightComp theme={theme}>
        {success ? (
          <CreateAccSuccessComp
            words={"Check your mail and reset your password"}
            button={"yes"}
            theme={theme}
          />
        ) : (
          <ResetPassComp setSuccess={setSuccess} theme={theme} />
        )}
      </LoginFlexRightComp>
    </LoginFlexComp>
  );
};

export default ResetPassword;
