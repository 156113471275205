import React, { useEffect } from "react";

const ClickOustideTwoRefs = (ref, ref1, setOpen) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        ref.current &&
        ref1.current &&
        !ref.current.contains(event.target) &&
        !ref1.current.contains(event.target)
      ) {
        setOpen(false);
        console.log("click outside");
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, ref1]);
};

export default ClickOustideTwoRefs;
