import React, { useState } from "react";
import AppHeadingNav from "../../components/webapp/AppHeadingNav";
import ProjectsNavComp from "../../components/webapp/ProjectsNavComp";
import AntTableComp from "../../components/webapp/AntTableComp";
import ProjectDetails from "../../components/webapp/ProjectDetails";
import ProjectCreate from "../../components/webapp/ProjectCreate";
import { Navigate, Route, Routes } from "react-router-dom";
import ProjectAntTable from "../../components/webapp/ProjectAntTable";
import { useTranslation } from "react-i18next";

const Projects = ({ theme }) => {
  const { t } = useTranslation();
  const [navlink, setNavlink] = useState(t("appHeader.myProjects"));
  return (
    <div className=" ">
      <AppHeadingNav
        left={t("appHeader.project")}
        right={navlink}
        date={false}
        arrow={false}
        theme={theme}
      />
      {/* project nav */}
      <div className="hidden relative md:flex">
        {theme ? (
          <>
            <ProjectsNavComp
              label={t("appHeader.myProjects")}
              link={"../projects/my-projects"}
              theme={theme}
              color={"green"}
            />{" "}
            <ProjectsNavComp
              label={t("appHeader.create")}
              link={"../projects/create-project"}
              theme={theme}
              color={"green"}
            />
          </>
        ) : (
          <>
            <ProjectsNavComp
              label={t("appHeader.myProjects")}
              link={"../projects/my-projects"}
              theme={theme}
            />{" "}
            <ProjectsNavComp
              label={t("appHeader.create")}
              link={"../projects/create-project"}
              theme={theme}
            />
          </>
        )}
      </div>

      {/* Ant Table */}
      <div className="drop-shadow-md mt-[25px]">
        <Routes>
          <Route index element={<Navigate to="my-projects" replace />} />
          <Route
            path="my-projects/*"
            element={<ProjectAntTable setNavlink={setNavlink} />}
          />
          <Route
            path="create-project"
            element={<ProjectCreate setNavlink={setNavlink} />}
          />
        </Routes>

        {/* <ProjectDetails /> */}
        {/* <ProjectCreate /> */}
      </div>
    </div>
  );
};

export default Projects;
