import React from "react";
import clickOne from "../../assests/website/click-one.png";
import clickTwo from "../../assests/website/click-two.png";
import clickThree from "../../assests/website/click-three.png";
import arrow from "../../assests/website/caro-arrow.svg";
import CircleCurrent from "./CircleCurrent";
import CircleNoCurrent from "./CircleNoCurrent";

const CarouselHome = ({ steps, setSteps }) => {
  return (
    <div className="mx-auto lg:mx-0  w-fit flex flex-col items-center justify-center">
      {/* image */}
      <div className="write-current h-[350px] mb-[50px]">
        {steps === 1 && (
          <img
            className="write-current h-full object-cover"
            src={clickOne}
            alt=""
          ></img>
        )}
        {steps === 2 && (
          <img
            className=" write-current h-full object-cover"
            src={clickTwo}
            alt=""
          ></img>
        )}
        {steps === 3 && (
          <img
            className=" write-current h-full object-cover"
            src={clickThree}
            alt=""
          ></img>
        )}
      </div>
      {/* carousel */}
      <div className="mx-auto flex items-center gap-[32px]">
        {/* image left */}
        <div
          className="rotate-180 cursor-pointer"
          onClick={() => {
            if (steps == 1) {
              setSteps(3);
            } else {
              setSteps(steps - 1);
            }
          }}
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.443359"
              width="39.4436"
              height="39.4436"
              rx="6.18723"
              fill="#137C42"
            />
            <path
              d="M9.31836 18.3524V21.0916H25.7532L18.2206 28.6242L20.1654 30.569L31.0123 19.722L20.1654 8.875L18.2206 10.8198L25.7532 18.3524H9.31836Z"
              fill="white"
            />
          </svg>
        </div>

        {/* mid circles */}
        <div className="flex gap-[8px]  w-[99px]">
          {steps === 1 && (
            <>
              <CircleNoCurrent current={true} />
              <CircleNoCurrent current={false} />
              <CircleNoCurrent current={false} />
            </>
          )}

          {steps === 2 && (
            <>
              <CircleNoCurrent current={false} />
              <CircleNoCurrent current={true} />
              <CircleNoCurrent current={false} />
            </>
          )}

          {steps === 3 && (
            <>
              <CircleNoCurrent current={false} />
              <CircleNoCurrent current={false} />
              <CircleNoCurrent current={true} />
            </>
          )}
        </div>

        {/* image right */}
        <div
          className="cursor-pointer "
          onClick={() => {
            if (steps == 3) {
              setSteps(1);
            } else {
              setSteps(steps + 1);
            }
          }}
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.443359"
              width="39.4436"
              height="39.4436"
              rx="6.18723"
              fill="#137C42"
            />
            <path
              d="M9.31836 18.3524V21.0916H25.7532L18.2206 28.6242L20.1654 30.569L31.0123 19.722L20.1654 8.875L18.2206 10.8198L25.7532 18.3524H9.31836Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default CarouselHome;
