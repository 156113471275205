import React, { useState } from "react";
import image from "../../assests/website/forgot-pass-pic.png";
// components
import LoginComp from "./LoginComp";
import PasswordInputComp from "./PasswordInputComp";
import FormButtonComp from "./FormButtonComp";
import CreateAccSuccessComp from "./CreateAccSuccessComp";
import LoginFlexComp from "./LoginFlexComp";
import LoginFlexLeftComp from "./LoginFlexLeftComp";
import LoginFlexRightComp from "./LoginFlexRightComp";

const CreateNewPassword = () => {
  const [success, setSuccess] = useState(false);

  const handleSubmit = () => {
    setSuccess(true);
  };
  return (
    <>
      <LoginFlexComp>
        <LoginFlexLeftComp image={image} />
        <LoginFlexRightComp>
          {success ? (
            <CreateAccSuccessComp words={"Password changed successfully"} />
          ) : (
            <LoginComp header={"Create New Password"}>
              <div className="flex flex-col gap-[24px]">
                <PasswordInputComp label={"New Password"} />
                <div>
                  <p className="text-xs font-medium">
                    Min 8 Characters with a combination of letters and numbers
                  </p>
                  {/* password strength area */}
                  <div className=" flex gap-[24px] mt-[13px]">
                    {/* rec boxes */}
                    <div className=" flex gap-[4px] w-full items-center">
                      {/* box1 */}
                      <div className="bg-[#58BA59] h-[5px] rounded-[10px] w-[25%]"></div>
                      {/* box1 */}
                      <div className="bg-[#58BA59] h-[5px] rounded-[10px] w-[25%]"></div>
                      {/* box1 */}
                      <div className="bg-[#58BA59] h-[5px] rounded-[10px] w-[25%]"></div>
                      {/* box1 */}
                      <div className="bg-[#DCE4E8] h-[5px] rounded-[10px] w-[25%]"></div>
                    </div>
                    <p className="text-xs font-semibold text-[#58BA59]">
                      Strong
                    </p>
                  </div>
                </div>
                <PasswordInputComp label={"Confirm Password"} />
                <FormButtonComp label={"Continue"} onSubmit={handleSubmit} />
              </div>
            </LoginComp>
          )}{" "}
        </LoginFlexRightComp>
      </LoginFlexComp>
    </>
  );
};

export default CreateNewPassword;
