import React, { useState, useRef } from "react";
import arrow from "../../assests/webapp/arrow-p-info.svg";
// import ClickOutsideTwoRefs from "../../globals/clickOutsideTwoRefs";
import ClickOustideTwoRefs from "../../globals/clickOustideTwoRefs";

const SearchRegionComp = ({
  header,
  image,
  view,
  label,
  theme,
  list,
  setValue,
  setChange,
}) => {
  const [open, setOpen] = useState(false);
  const [select, setSelect] = useState("");
  const ref = useRef();
  const ref1 = useRef();

  // ClickOutsideTwoRef();
  ClickOustideTwoRefs(ref, ref1, setOpen);
  return (
    <div className=" flex flex-col md:flex-row md:items-center justify-between gap-y-[8px]">
      <p className="text-[16px] md:text-[24px] leading-[27px] font-medium">
        {header}
      </p>

      {/* form */}
      <div
        className={
          theme
            ? "bg-[#EAEAEA] rounded-[8px] relative w-full sm:w-[317px]   py-[12px] px-[16px]"
            : " relative search-region rounded-[8px] w-full sm:w-[317px]   py-[12px] px-[16px]"
        }
      >
        {/* visible */}
        <div
          ref={ref1}
          className="flex items-center justify-between cursor-pointer"
          onClick={() => {
            {
              open ? setOpen(false) : setOpen(true);
            }
          }}
        >
          {/* left */}
          <div className="flex gap-[16px] items-center">
            {/* image */}
            <div className={view ? "" : "hidden"}>
              <img src={image} alt=""></img>
            </div>
            {/* word */}
            <p className="text-sm font-medium">
              {select ? select.name : label}
            </p>
          </div>
          {/* right */}
          <div
            className={open ? " rotate-180 cursor-pointer" : " cursor-pointer"}
          >
            <img src={arrow} alt=""></img>
          </div>
        </div>
        {/* hidden */}
        <div
          ref={ref}
          className={
            open
              ? " block shadow-box-sm rounded-[8px]   w-full absolute z-20 top-[60px] left-0  "
              : "hidden "
          }
        >
          <div
            className={
              theme
                ? "bg-[#F7F7F7] rounded-[8px] max-h-[300px]  overflow-auto   cursor-pointer"
                : " bg-[#2a2c2e] rounded-[8px] max-h-[300px]  overflow-auto   cursor-pointer"
            }
          >
            {list?.map((data, index) => (
              <div
                key={index}
                onClick={() => {
                  setSelect(data);
                  setChange(true);
                  setValue(data);
                  setOpen(false);
                }}
                className="  px-[24px] py-[18px] curor-pointer select-dark-comp  "
              >
                <div className="flex items-center gap-[10px]">
                  {/* left left */}
                  {data.image && (
                    <div className="w-[18px] h-[18px]">
                      <img
                        src={data.image}
                        alt=""
                        className="w-full h-full"
                      ></img>
                    </div>
                  )}

                  {/* left right */}
                  <div>
                    <p className="font-medium text-[12px]">{data.name}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchRegionComp;
