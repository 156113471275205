import React, { useState } from "react";
import LoginComp from "./LoginComp";
import InputComp from "./InputComp";
import FormButtonComp from "./FormButtonComp";
import { Link } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase/firebaseConfig";
import { toast } from "react-toastify";

const ResetPassComp = ({ setSuccess, theme }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    if (email === "") {
      setLoading(false);
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email.trim().toLowerCase());
      setLoading(false);
      setSuccess(true);
    } catch (e) {
      setLoading(false);
      toast.error("User does not exist with this email.");
    }
  };

  return (
    <LoginComp theme={theme} header={"Reset Password"}>
      <p className="font-normal text-[#ACB5BB] mt-[-28px] mb-[32px]">
        Enter your email address and we’ll send a link to reset your password
      </p>
      <div className="flex flex-col gap-[24px]">
        <div>
          <InputComp label={"Email Address"} setValue={setEmail} />
        </div>

        <Link to={"../login"} className="">
          <p className="text-[#58BA59] font-medium  w-fit cursor-pointer">
            Return to login
          </p>
        </Link>

        <FormButtonComp
          label={"Continue"}
          loading={loading}
          onSubmit={handleSubmit}
        />
      </div>

      {/* dont have an account */}
      <div className=" mt-[32px]">
        <p className="text-center text-base font-semibold">
          Don't have an account?{"   "}
          <Link to={"../signup"}>
            <i className="text-[#58BA59] not-italic cursor-pointer ml-1">
              {" "}
              Register Here
            </i>
          </Link>
        </p>
      </div>
    </LoginComp>
  );
};

export default ResetPassComp;
