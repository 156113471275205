import React, { useEffect, useState } from "react";

// assests
import curvy1 from "../../assests/website/home-back-svg.svg";
import curvy2 from "../../assests/website/search-curve-svg.svg";
import svgs1 from "../../assests/website/home-back-svg.svg";
import curveWhite from "../../assests/website/curve-bottom-white.svg";
import curve from "../../assests/website/home-curve.svg";
import edit from "../../assests/website/edit.svg";
import coins from "../../assests/website/header-coins.svg";
import docc from "../../assests/webapp/doc.svg";
import profiles from "../../assests/website/profile.svg";
import profiles2 from "../../assests/website/profile-svg.svg";

// imports
import ProjectComp from "./ProjectComp";
import profile from "../../assests/website/profile-image.png";
import ProjectMainContainer from "./ProjectMainContainer";
import LeafHeader from "./LeafHeader";
import CoinComp from "./CoinComp";
import DashboardBoxComp from "../webapp/DashboardBoxComp";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { auth, db } from "../../firebase/firebaseConfig";
import { useDispatch } from "react-redux";
import FetchProjects from "./FetchProjects";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const SearchUser = ({ theme }) => {
  // current user
  const [user, setUser] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const user = useSelector((state) => state.user);
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  // const projects = useSelector((state) => state.project.data);

  const fetchUser = async () => {
    // console.log("enter fetchuser");
    if (currentUser) {
      const docRef = doc(db, "users", currentUser.uid);
      try {
        const querySnapshot = await getDoc(docRef);
        // Handle the data or dispatch an action here
        console.log("snapshot userslice", querySnapshot.data());
        setUser(querySnapshot.data());
      } catch (error) {
        // Handle the error or show an error message to the user
        console.log("Error fetching document:", error);
      }
    }
  };

  useEffect(() => {
    fetchUser();

    // console.log("fetchuser", fetchUser());
  }, []);

  // onauthchange signout
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // User is authenticated
      } else {
        // User is not authenticated, navigate to login or another route
        // navigate("/login"); //TODO: Redirect to login page or any other route?
        navigate("/login");
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [onAuthStateChanged]);

  return (
    <div
      className={theme ? "relative text-[#1a1c1e]" : "relative text-[white]"}
    >
      {/* div area */}
      <div
        className={
          theme
            ? "  py-[180px] absolute w-full  bg-[#F7F7F7]"
            : " py-[180px] absolute w-full bg-[#1A1C1E]"
        }
      ></div>
      {/* curve */}
      <div className=" w-full absolute top-[360px]">
        {theme ? (
          <img className="w-full" src={curveWhite} alt=""></img>
        ) : (
          <img className="w-full" src={curve} alt=""></img>
        )}
      </div>

      {/* absolute */}
      <div className="absolute hidden sm:block left-0 top-[-50px] z-20">
        <img src={curvy2} alt=""></img>
      </div>

      {/* absolute */}
      <div className="absolute hidden sm:block right-0 z-20 top-[300px] ">
        <img src={curvy1} alt=""></img>
      </div>

      <div className=" max-w-[1200px] mx-[24px] pt-[80px] md:pt-[100px]  xl:mx-auto  relative  mb-[140px]">
        {/* profile box */}
        {/* <div className="mx-auto w-fit">
          <img src={profile} alt=""></img>
        </div> */}
        <div
          className={
            theme
              ? "bg-[#E8E8E8] max-w-[700px] rounded-[24px] mx-auto py-[64px] px-[24px] sm:px-[40px] mb-[80px] relative z-20"
              : " bg-[#2A2C2E] max-w-[700px] rounded-[24px] mx-auto py-[64px] px-[24px] sm:px-[40px] mb-[80px] relative z-20"
          }
        >
          {/* profile icon */}
          <div
            className={
              theme
                ? "relative cursor-pointer flex justify-center items-center bg-[white]  w-[120px] h-[120px] sm:w-[156px] sm:h-[156px] rounded-full mx-auto mb-[10px]"
                : "relative cursor-pointer flex justify-center items-center  bg-[#3A3A3A] w-[120px] h-[120px] sm:w-[156px] sm:h-[156px] rounded-full mx-auto mb-[10px]"
            }
          >
            {/* put the svg comp here */}
            <div className=" w-[50px] sm:w-auto">
              {theme ? (
                <img className=" w-full" src={profiles2} alt=""></img>
              ) : (
                <img className=" w-full" src={profiles} alt=""></img>
              )}
            </div>

            {/* <div className="absolute bottom-[0px] right-[10px]">
              <img src={edit} alt=""></img>
            </div> */}
          </div>

          {/* name */}
          <p className="text-center mb-[21px] text-[28px] md:text-[42px] leading-[31px] md:leading-[62px]">
            {user?.user_name}
          </p>

          {/* coin */}
          <div className=" w-fit mx-auto mb-[54px]">
            {theme ? (
              <CoinComp
                word={user?.total_coins}
                image={coins}
                bgC={"#F7F7F7"}
                bg={true}
                // theme={theme}
              />
            ) : (
              <CoinComp
                word={user?.total_coins}
                image={coins}
                bgC={"#3A3A3A"}
                bg={true}
                // theme={theme}
              />
            )}
          </div>

          {/* details comp */}
          {/* <div className=" w-full grid grid-cols-1 sm:grid-cols-2 gap-[24px] w-fit mx-auto">
          
            <DashboardBoxComp
              image={doc}
              header={"Number of Projects"}
            
              number={"24"}
              bg={"dash-bg-one"}
            />

        
            <DashboardBoxComp
              image={""}
              header={"Supported Projects"}
            
              number={"34"}
              bg={"dash-bg-two"}
            />
          </div> */}
        </div>

        {/* other */}
        <div className=" w-fit mx-auto ">
          <LeafHeader label={"Other Projects"} />
        </div>
        <FetchProjects theme={theme} showSearch={false} />
      </div>
    </div>
  );
};

export default SearchUser;
