// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { gethAuth } from "firebase/auth";
import { getAuth } from "firebase/auth";
import "firebase/firestore";
import { Firestore, getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

// const firebaseConfig = {
//   apiKey: "AIzaSyDmGYw_l1LFeeu5PFBiulTvMZSbSxglRh4",
//   authDomain: "pap-earth.firebaseapp.com",
//   projectId: "pap-earth",
//   storageBucket: "pap-earth.appspot.com",
//   messagingSenderId: "128152885971",
//   appId: "1:128152885971:web:fe5bdc49ed8c7891691226",
//   measurementId: "G-H8B8KWC69J",
// };

const firebaseConfig = {
  apiKey: "AIzaSyC3zd3Oel8vN_Le_Ppo2wj0RjWfiF3thjo",
  authDomain: "people-and-planet.firebaseapp.com",
  projectId: "people-and-planet",
  storageBucket: "people-and-planet.appspot.com",
  messagingSenderId: "364326050202",
  appId: "1:364326050202:web:c4844120c5249df11a4223",
  measurementId: "G-NYL0XTKCH2"
};

const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);

export default app;
