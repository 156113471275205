import React from "react";
import { downloadExcelData } from "../../globals/exportCSV";
import { useSelector } from "react-redux";

const FilterComp = ({ image, label }) => {
  const projects = useSelector((state) => state.project.data);
  return (
    <div
      className="cursor-pointer border border-[#DCE4E8] flex justify-between gap-[16px] rounded-[8px] py-[12px] px-[18px] w-full "
      onClick={() => {
        if (label === "export") {
          downloadExcelData(projects);
        }
      }}
    >
      <div>
        <img src={image} alt=""></img>
      </div>
      <p className="text-base font-semibold">{label}</p>
    </div>
  );
};

export default FilterComp;
