import React from "react";
import leaf from "../../assests/webapp/leaf.svg";

const LeafHeader = ({ label, size, NoCenter }) => {
  return (
    <div
      className={
        NoCenter ? " w-fit relative" : " relative w-fit mx-auto md:mx-0"
      }
    >
      <div className="absolute w-[11px] md:w-[30px] top-[0px] md:top-[-10px] left-[10%]">
        <img className="w-full" src={leaf} alt=""></img>
      </div>
      {size ? (
        <p
          className={`text-[28px] md:text-[${size}px] font-semibold leading-[47px] md:leading-[57px]`}
        >
          {label}
        </p>
      ) : (
        <p className=" text-[28px] md:text-[42px] font-semibold leading-[47px] md:leading-[57px]">
          {label}
        </p>
      )}
    </div>
  );
};

export default LeafHeader;
