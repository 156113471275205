import React from "react";

const WebsiteButton = ({ label, onClick, theme }) => {
  return (
    <div
      className={
        theme
          ? "bg-[white] rounded-[8px] py-[15px] flex items-center justify-center w-full cursor text-[#0B7026]"
          : "bg-[#0B7026] rounded-[8px] py-[15px] flex items-center justify-center w-full cursor text-[#ffffff]"
      }
      onClick={onClick}
    >
      <p className="text-[14px] md:text-[16px] font-semibold">{label}</p>
    </div>
  );
};

export default WebsiteButton;
