import React from "react";

const NavSvg = ({ theme }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="13"
        fill="none"
      >
        <path
          stroke={theme ? "#1A1C1E" : "#fff"}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="1.5"
          d="m4.455 10.46 3.26-3.26a.993.993 0 0 0 0-1.4l-3.26-3.26"
        />
      </svg>
    </div>
  );
};

export default NavSvg;
