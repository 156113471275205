import React, { useRef, useState } from "react";
import ReactCountryFlagsSelect from "react-country-flags-select";
import arrow from "../../assests/webapp/arrow-down.svg";
import ClickOustideTwoRefs from "../../globals/clickOustideTwoRefs";

const CountryComp = ({
  label,
  required,
  image,
  value,
  setValue,
  list,
  country,
  selected,
  setSelected,
  error,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  const [select, setSelect] = useState(selected ? selected : "");
  const ref = useRef();
  const ref1 = useRef();

  ClickOustideTwoRefs(ref, ref1, setOpen);

  console.log("error", error);

  return (
    <div className="relative ">
      <label className="flex gap-[2px] text-[#ACB5BB] font-medium text-[12px] block mb-[3px]">
        <p>{label}</p>
        {required && <p className="text-[#D80027]">*</p>}
      </label>{" "}
      {error && (
        <div className="absolute top-[-30px] right-0 my-3 py-[6px] w-fit px-[12px] rounded-[4px] bg-[#FFEFEF] text-[#FF0319]">
          <p className="text-xs">missing field</p>
        </div>
      )}
      {country ? (
        <ReactCountryFlagsSelect
          selected={selected}
          onSelect={setSelected}
          disabled={disabled}
        />
      ) : (
        // <div></div>
        <div
          ref={ref1}
          className={
            disabled
              ? "cursor-pointer bg-[#f0f0f0] px-[24px] py-[18px] border border-[#DCE4E8] rounded-[8px]"
              : "cursor-pointer px-[24px] py-[18px] border border-[#DCE4E8] rounded-[8px]"
          }
        >
          {/* show */}
          <div
            onClick={() => {
              if (disabled) {
              } else {
                open ? setOpen(false) : setOpen(true);
              }
            }}
            className="flex gap-[10px] justify-between items-centerborder border-[red]"
          >
            {/* left */}
            {select ? (
              <div className="flex items-center gap-[10px]">
                {/* left left */}
                <div className="w-[18px] h-[18px]">
                  <img
                    src={select.image}
                    alt=""
                    className="w-full h-full"
                  ></img>
                </div>
                {/* left right */}
                <div>
                  <p className="font-medium text-[12px]">{select.name}</p>
                </div>
              </div>
            ) : value ? (
              <p className="text-sm"> {value}</p>
            ) : (
              <p className="text-sm">select</p>
            )}

            {/* right */}
            <div
              className={
                open ? " rotate-180 cursor-pointer" : " cursor-pointer"
              }
              onClick={() => {}}
            >
              <img src={arrow} alt=""></img>
            </div>
          </div>

          {/* hidden */}
          <div
            ref={ref}
            className={
              open
                ? " block shadow-box-sm rounded-[8px]   w-full absolute z-20 top-[84px] left-0  bg-white"
                : "hidden "
            }
          >
            <div className="bg-white rounded-[8px] max-h-[300px]  overflow-auto   cursor-pointer">
              {list?.map((data, index) => (
                <div
                  key={index}
                  onClick={() => {
                    console.log("data", data);
                    setSelect(data);
                    setValue(data);
                    setOpen(false);
                  }}
                  className="  px-[24px] py-[18px] curor-pointer select-comp  "
                >
                  <div className="flex items-center gap-[10px]">
                    {/* left left */}
                    <div className="w-[18px] h-[18px]">
                      <img
                        src={data.image}
                        alt=""
                        className="w-full h-full"
                      ></img>
                    </div>
                    {/* left right */}
                    <div>
                      <p className="font-medium text-[12px]">{data.name}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CountryComp;
