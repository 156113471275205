import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  increment,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";

const updateUser = async (currentUser) => {
  if (currentUser == null) {
    return false;
  }

  const docRef = doc(db, "users", currentUser.uid);
  try {
    await updateDoc(docRef, {
      total_coins: increment(1),
      search_count: increment(1),
    });
    return true;
  } catch (error) {
    // Handle the error or show an error message to the user
    console.log("Error fetching document:", error);
    return false;
  }
};

export default updateUser;
