import React from "react";
import WebsiteButton from "./WebsiteButton";

import ProjectComp from "./ProjectComp";
import LeafHeader from "./LeafHeader";
import ProjectMainContainer from "./ProjectMainContainer";
import { db } from "../../firebase/firebaseConfig";
import {
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useState } from "react";
import { useEffect } from "react";
import FetchProjects from "./FetchProjects";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EverySearchYou = ({ theme }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };
  const handleClick = () => {
    navigate("/all-projects");
  };

  return (
    <div className="mt-[150px] text-[white] mb-[150px]">
      {/* main */}
      <div className="max-w-[1200px] mx-[24px]  xl:mx-auto  relative">
        {/* head */}

        {/* image */}

        <div
          className={
            theme
              ? "text-[#1A1C1E] text-center max-w-[811px] mx-auto"
              : "text-center max-w-[811px] mx-auto"
          }
        >
          <LeafHeader label={t("home.everySearchYou.header")} />
        </div>

        {/* main */}
        <FetchProjects theme={theme} showSearch={false} />

        {/* button */}
        <div
          onClick={() => {
            handleClick();
            moveToTop();
          }}
          className="w-full sm:w-[288px] mt-[100px] mx-auto cursor-pointer "
        >
          <WebsiteButton label={t("home.everySearchYou.button")} />
        </div>
      </div>
    </div>
  );
};

export default EverySearchYou;
