import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moveToTop from "../../globals/moveToTop";

const AppFooter = () => {
  const { t } = useTranslation();
  return (
    <div className="text-[#888888] pb-[52px] text-sm font-normal mt-[100px]">
      {/* margin max width */}
      <div className=" max-w-[1200px] mx-[24px]  xl:mx-auto ">
        {/* flex */}
        <div className=" flex flex-col gap-y-[20px] gap-x-[50px] sm:flex-row justify-between sm:items-center mx-auto w-fit sm:w-full">
          {/* left */}
          <div>
            <p>{t("footer.copyRight")}</p>
          </div>

          {/* right */}
          {/* TODO : make these links */}
          <div className="flex gap-[64px] items-center">
            <Link
              to={"../privacy-policy"}
              onClick={() => {
                moveToTop();
              }}
              className=""
            >
              {t("footer.navigation.privacyPolicy")}
            </Link>
            <Link
              to={"../terms-of-service"}
              onClick={() => {
                moveToTop();
              }}
              className=""
            >
              {t("footer.navigation.termsOfService")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
