import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const DetailsMapNavComp = ({
  label,
  color,
  setDetailsNav,
  isActive,
  type,
  details,
  detailsNav,
  SearchResult,
  detailNav,
  setMaps,
  maps,
  query
}) => {
  return (
    <div className="relative">
      <div
        onClick={() => {
          // setMaps(true);
          window.location.href = `https://www.google.com/maps/search/${query.trim().replace(/ /g, "+")}`;
        }}
        className={
          isActive
            ? color === "green"
              ? " text-base font-normal text-[#0B7026] relative z-20  border-b-2 border-[#0B7026] py-[15px] px-[24px] w-fit"
              : "text-base font-normal text-white relative z-20  border-b-2 border-[#0B7026] py-[15px] px-[24px] w-fit"
            : SearchResult === true
            ? "cursor-pointer text-base font-normal text-[#A2A6AA] relative z-20  py-[15px] px-[24px] w-fit"
            : "cursor-pointer text-base font-normal text-[#A2A6AA] relative z-20  border-b border-[#A2A6AA] py-[15px] px-[24px] w-fit"
        }
      >
        <p className="">{label}</p>
      </div>
      {/* maps section */}
      {maps && (
        <div className=" left-[10px] rounded-[8px] p-[12px] bg-[#2a2c2e] z-30 shadow-md absolute">
          <div className="px-[6px] ">
            {/* image */}
            <div>
              <img src="" alt=""></img>
            </div>
            {/* words */}
            <p>Bing</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailsMapNavComp;
