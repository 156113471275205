import { createSlice } from "@reduxjs/toolkit";
import { useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

import { auth } from "../firebase/firebaseConfig";

const data = null;
// useEffect(() => {
//   const unsuscribe = onAuthStateChanged(auth, (currentUser) => {
//     data = currentUser;
//   });
//   return () => {
//     unsuscribe();
//   };
// }, []);
export const UserAuthSlice = createSlice({
  // name
  name: "userAuth",

  // Initial State
  initialState: {
    data,
  },

  // reducers
  reducers: {
    setCurrentUser: (state, action) => {
      state.data = action.payload;
    },

    userLogin: (email, password, action) => {
      return createUserWithEmailAndPassword(auth, email, password);
    },
    userSignup: (email, password, action) => {
      return signInWithEmailAndPassword(auth, email, password);
    },
  },
});

export const { userSignup, userLogin, setCurrentUser } = UserAuthSlice.actions;
export default UserAuthSlice.reducer;
