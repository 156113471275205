import React, { useEffect } from "react";

// import React from "react";

const ClickOutsideRef = (ref, setOpen) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
        console.log("click outside");
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  // return (
  //   <div>clickOutsideRef</div>
  // )
};

export default ClickOutsideRef;
