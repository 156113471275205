import React from "react";

const DetailsNavComp = ({
  label,
  color,
  setDetailsNav,
  isActive,
  type,
  details,
  SearchResult,
}) => {
  return (
    <div
      onClick={() => {
        setDetailsNav(label);
      }}
      className={
        isActive
          ? color === "green"
            ? " text-base font-normal text-[#0B7026] relative z-20  border-b-2 border-[#0B7026] py-[15px] px-[24px] w-fit"
            : "text-base font-normal text-white relative z-20  border-b-2 border-[#0B7026] py-[15px] px-[24px] w-fit"
          : SearchResult === true
          ? "cursor-pointer text-base font-normal text-[#A2A6AA] relative z-20  py-[15px] px-[24px] w-fit"
          : "cursor-pointer text-base font-normal text-[#A2A6AA] relative z-20  border-b border-[#A2A6AA] py-[15px] px-[24px] w-fit"
      }
    >
      <p className="">{label}</p>
    </div>
  );
};

export default DetailsNavComp;
