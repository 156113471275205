import React, { useEffect, useState } from "react";

// assests
import coins from "../../assests/website/header-coins.svg";
import calendar from "../../assests/webapp/calendar.svg";
import navArrow from "../../assests/webapp/nav-arrow.svg";
import CoinComp from "../website/CoinComp";
import { DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
// import { fetchUser } from "../../redux/UserSlice";
import NavSvg from "./NavSvg";
import { useTranslation } from "react-i18next";

const AppHeadingNav = ({ left, right, date, arrow, theme }) => {
  // fetching all slices
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = useSelector((state) => state.user.data);
  const loading = useSelector((state) => state.user.loading);
  console.log("user", user);

  useEffect(() => {
    console.log("setting user");
  }, [user]);

  const { RangePicker } = DatePicker;

  return (
    <div
      className={
        theme
          ? " mt-[32px] flex flex-col gap-[24px] md:flex md:flex-row md:items-center justify-between text-[#1A1C1E] mb-[45px]"
          : " mt-[32px] flex flex-col gap-[24px] md:flex md:flex-row md:items-center justify-between text-[white] mb-[45px]"
      }
    >
      {/* left */}
      <div className="  flex flex-col gap-[24px] md:flex md:flex-row  md:gap-[52px]">
        {/* left left */}
        <div>
          {/* up */}
          <p className="font-semibold text-[24px] leading-[36px]">
            {t("appHeader.welcome")}, {loading ? "..." : user?.first_name}
          </p>

          {/* down */}
          <div className="flex gap-[2px] mt-[12px] items-center">
            <p className="font-medium text-sm text-[#A2A6AA]">{left}</p>

            {/* image */}
            <div className={arrow ? "hidden" : ""}>
              <NavSvg theme={theme} />
            </div>

            <p className="font-medium text-sm ">{right}</p>
          </div>
        </div>

        {/* left right */}
        <div className="min-w-[90px] w-[92px]">
          {/* <CoinComp image={coins} word={""} /> */}
        </div>
      </div>

      {/* right */}
      {/* <div className={date ? "" : "hidden"}>
        

        <div className="dark-date-picker w-full sm:w-[80%] md:w-full  bg-[#2a2c2e] rounded-[8px] flex items-center gap-[8px] py-[12px] px-[18px]">
      
          <div className="">
            <img src={calendar} alt=""></img>
          </div>
          <RangePicker placeholder={["Start Date", "End Date"]} />
        </div>
      </div> */}
    </div>
  );
};

export default AppHeadingNav;
