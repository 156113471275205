import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import HomePage from "../../pages/website/HomePage";
import svgs1 from "../../assests/website/home-back-svg.svg";

import HowPapWorksHome from "./HowPapWorksHome";
import WeInvestInAllGeo from "./WeInvestInAllGeo";
// import PapRewards from "./PapRewards";
import JoinOurGreen from "./JoinOurGreen";
import EverySearchYou from "./EverySearchYou";
import Home from "../../pages/website/Home";
import AllProjects from "./AllProjects";
import SearchUser from "./SearchUser";
import ProjectDetails from "./ProjectDetails";
import PrivacyPolicy from "../../pages/website/PrivacyPolicy";
// import PapRewards from "../../pages/webapp/PapRewards";

import Settings from "../../pages/website/Settings";
import Search from "../../pages/website/Search";
import SearchResult from "../../pages/website/SearchResult";
import HowPapWorks from "../../pages/website/HowPapWorks";
import DeleteData from "../../pages/website/DeleteData";
import {
  Route,
  Routes,
  useParams,
  useSearchParams,
  redirect,
  Navigate,
} from "react-router-dom";
import PapRewards from "./PapRewards";
import WebsitePapRewards from "../../pages/website/WebsitePapRewards";
import { useSelector } from "react-redux";
import TermsOfService from "../../pages/website/TermsOfService";
import ProtectedRoute from "../../firebase/ProtectedRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HomeVideoModal from "./HomeVideoModal";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "../../translations/en/websiteTranslation.json";
import translationFR from "../../translations/fr/websiteTranslation.json";
import translationGE from "../../translations/ge/websiteTranslation.json";
import translationCH from "../../translations/ch/websiteTranslation.json";
import ProtectedWebRoute from "../../firebase/ProtectedWebRoute";
import LogoutWhenTabIsClosed from "../../globals/LogoutWhenTabIsClosed";
import { auth } from "../../firebase/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  ge: {
    translation: translationGE,
  },
  ch: {
    translation: translationCH,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

const MainContainer = ({ theme }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const storedUser = JSON.parse(localStorage.getItem("currentUser"));
  let user_type = localStorage.getItem("user_type");

  const lang = localStorage.getItem("language");
  // console.log("lang", lang);
  const color = getComputedStyle(document.documentElement).getPropertyValue(
    "--theme"
  );

  function setColor(newColor) {
    document.documentElement.style.setProperty("--theme", newColor);
  }
  useEffect(() => {
    if (theme) {
      setColor("white");
    } else {
      setColor("#1a1c1e");
    }
  }, [theme]);
  // console.log(color);

  // const handleLanguageChange = (lang) => {
  //   i18n.changeLanguage(lang);
  // };

  // useEffect(() => {
  //   handleLanguageChange(lang);
  // }, [lang]);

  // // log out when user closes tab
  // LogoutWhenTabIsClosed();

  const remember = JSON.parse(localStorage.getItem("remember"));

  return (
    <div
      className={
        theme
          ? isPlaying
            ? "bg-white fixed w-full"
            : "relative bg-white"
          : isPlaying
          ? "bg-[#141414] fixed w-full"
          : "relative bg-[#141414]"
      }
    >
      {/* header */}
      {(params["*"] !== "search" || searchParams.get("q") !== null) && (
        <Header theme={theme} />
        // <WebHeader />
      )}
      {/* routes */}
      <div className="pt-[183px] lg:pt-[105px]">
        <Routes>
          <Route
            path="/"
            element={<Home theme={theme} setIsPlaying={setIsPlaying} />}
          />

          <Route path="/all-projects" element={<AllProjects theme={theme} />} />

          <Route
            path="/project-details/:id"
            element={<ProjectDetails theme={theme} />}
          />

          <Route path="/search" element={<SearchResult theme={theme} />} />

          <Route
            path="/how-pap-works"
            element={<HowPapWorks theme={theme} />}
          />

          <Route path="/settings" element={<Settings theme={theme} />} />

          <Route
            path="/pap-rewards"
            element={<WebsitePapRewards theme={theme} />}
          />

          <Route
            path="/privacy-policy"
            element={<PrivacyPolicy theme={theme} />}
          />

          <Route
            path="/terms-of-service"
            element={<TermsOfService theme={theme} />}
          />

          <Route path="/delete-data" element={<DeleteData theme={theme} />} />

          {/* <Route path="/search-result" element={<SearchResult />} /> */}

          {/* protected route */}

          {/* this protected route check if stored user is a search innovator be it shoes otherwise redirect to home */}

          <Route
            path="/profile"
            element={
              <ProtectedWebRoute>
                <SearchUser theme={theme} />
              </ProtectedWebRoute>
            }
          />
        </Routes>
        {/* <Home /> */}
        {/* <AllProjects /> */}
        {/* <SearchUser /> */}
        {/* <ProjectDetails /> */}
        {/* <PrivacyPolicy /> */}
        {/* <PapRewards /> */}
        {/* <Settings /> */}
        {/* <Search /> */}
        {/* <SearchResult /> */}
        {/* <HowPapWorks /> */}
      </div>

      {/* {isPlaying && <HomeVideoModal setIsPlaying={setIsPlaying} />} */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      {/* footer */}
      {(params["*"] !== "search" || searchParams.get("q") !== null) && (
        <Footer theme={theme} />
      )}
    </div>
  );
};

export default MainContainer;
