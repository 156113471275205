import React, { useState, useEffect } from "react";
import moveToTop from "../../globals/moveToTop";

const MobileTablePagination = ({
  setStartIndex,
  setEndIndex,
  dataSource,
  startIndex,
  endIndex,
}) => {
  const [page, setPage] = useState(1);
  const [pageEnd, setPageEnd] = useState();

  useEffect(() => {
    setPageEnd(Math.ceil(dataSource?.length / 10));
  }, [dataSource]);
  // console.log("pagend", pageEnd);
  // console.log("data", dataSource?.length / 10);
  // console.log("dataSource", dataSource);
  // console.log("start", startIndex);
  // console.log("endIndex", endIndex);
  // console.log("page", page);

  return (
    <div className="mt-[50px]  flex justify-between items-center">
      {/* left arrow */}
      <div
        className="cursor-pointer"
        onClick={() => {
          if (startIndex > 0) {
            console.log("minusing 10");
            setStartIndex(startIndex - 10);
            setEndIndex(endIndex - 10);
            setPage(page - 1);
            moveToTop();
          }
        }}
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.5">
            <rect
              width="39.4436"
              height="39.4436"
              rx="6.18723"
              fill="#B1C6BA"
            />
            <path
              d="M30.569 18.3524V21.0916H14.1341L21.6668 28.6242L19.722 30.569L8.875 19.722L19.722 8.875L21.6668 10.8198L14.1341 18.3524H30.569Z"
              fill="#23242A"
            />
          </g>
        </svg>
      </div>

      {/* middle */}
      <div>
        <p>
          {page}/{dataSource?.length < 10 ? 1 : pageEnd}
        </p>
      </div>

      {/* right arrow */}
      <div
        className="rotate-180 cursor-pointer"
        onClick={() => {
          if (endIndex < dataSource?.length) {
            console.log("Adding plus 10");
            setStartIndex(startIndex + 10);
            setEndIndex(endIndex + 10);
            setPage(page + 1);
            moveToTop();
          }
        }}
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.5">
            <rect
              width="39.4436"
              height="39.4436"
              rx="6.18723"
              fill="#B1C6BA"
            />
            <path
              d="M30.569 18.3524V21.0916H14.1341L21.6668 28.6242L19.722 30.569L8.875 19.722L19.722 8.875L21.6668 10.8198L14.1341 18.3524H30.569Z"
              fill="#23242A"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default MobileTablePagination;
