import React, { useEffect, useState } from "react";

// assests
import tree from "../../assests/webapp/treeSearch.svg";
import docs from "../../assests/webapp/doc.svg";
import target from "../../assests/webapp/target.svg";
import coins from "../../assests/website/header-coins.svg";
import grey from "../../assests/webapp/project-image.svg";

// components
import DashboardBoxComp from "../../components/webapp/DashboardBoxComp";
import OngoingProjectsDash from "../../components/webapp/OngoingProjectsDash";
import BigWhiteContainer from "../../components/webapp/BigWhiteContainer";
import ReceintNotComp from "../../components/webapp/ReceintNotComp";
import AppHeadingNav from "../../components/webapp/AppHeadingNav";
import ProjectNotification from "../../components/webapp/ProjectNotification";
import { useDispatch, useSelector } from "react-redux";
import { auth, db } from "../../firebase/firebaseConfig";
import {
  collection,
  getDocs,
  getDoc,
  orderBy,
  query,
  where,
  doc,
} from "firebase/firestore";
import { addProject } from "../../redux/ProjectSlice";
import moment from "moment";
import { fetchNotification } from "../../redux/NotificationSlice";
import { onAuthStateChanged, updateEmail } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Dashboard = ({ theme }) => {
  const [totalCoins, setTotalCoins] = useState();
  const [totalValProjects, setTotalValProjects] = useState();
  const [notification, setNotification] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.user.loading);
  const user = useSelector((state) => state.user.data);
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const { t } = useTranslation();
  let user_type = localStorage.getItem("user_type");

  console.log("user", user_type);

  // console.log("loading", loading);
  console.log("user", currentUser);

  // const user = useSelector((state) => state.user.data);

  // project states
  const projects = useSelector((state) => state.project.data);

  // fetching the projects from the database
  const fetchPost = async () => {
    const querySnapshot = await getDocs(
      query(
        collection(db, "projects"),
        where("user_id", "==", currentUser.uid),
        orderBy("timestamp", "desc")
      )
    );

    const newData = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    dispatch(addProject(newData));
    // console.log("new-project data", newData);
  };

  // fetching notification
  const fetchNotification = async () => {
    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, "notifications"),
          where("user_id", "==", currentUser.uid)
          // orderBy("timestamp", "desc")
        )
      );
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      // return
      // console.log("notification-newdata", newData);

      setNotification(newData);
    } catch (error) {
      console.log("Error fetching document:", error);
    }
  };

  useEffect(() => {
    fetchPost();
    fetchNotification();
  }, []);

  // useEffect(() => {
  //   dispatch(fetchNotification());
  // }, [dispatch]);

  // function to calculate validated projects
  const calculateValProjects = () => {
    if (projects) {
      var count = 0;
      for (let i = 0; i < projects.length; i++) {
        if (projects[i].project_status === "approved") {
          count = count + 1;
        }
      }
      return count;
    }
  };

  // function to calculate coins collected for each of the projects
  const calculateTotalCoins = () => {
    if (projects) {
      var total = 0;
      for (let i = 0; i < projects.length; i++) {
        total = total + projects[i].coins_collected;
      }

      return total;
    }
  };

  useEffect(() => {
    setTotalCoins(calculateTotalCoins);
    setTotalValProjects(calculateValProjects);
  }, [projects]);

  // useEffect(() => {
  //   console.log("setting user");
  // }, [user]);

  // onauthchange signout
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // User is authenticated
      } else {
        // User is not authenticated, navigate to login or another route
        // navigate("/login"); //TODO: Redirect to login page or any other route?
        navigate("/login");
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [onAuthStateChanged]);

  return (
    <div className=" ">
      {/* Navigation area */}
      <AppHeadingNav
        left={t("appHeader.dashboard")}
        right={t("appHeader.overview")}
        date={true}
        theme={theme}
      />
      {/* box area */}
      <div className=" mb-[18px]  overflow-x-auto xl:overflow-hidden">
        <div className="grid grid-cols-3 gap-[16px] min-w-[1200px] items-center ">
          {/* box comp */}
          {/* <DashboardBoxComp
            image={tree}
            header={"Number of Searches"}
            number={"22"}
            bg={"dash-bg-one"}
          /> */}

          {/* box comp */}
          <DashboardBoxComp
            image={docs}
            header={t("dashboard.noOfProject")}
            // data
            number={projects?.length}
            bg={"dash-bg-two"}
          />

          {/* box comp */}
          <DashboardBoxComp
            image={target}
            header={t("dashboard.validatedProjects")}
            // data
            number={totalValProjects}
            bg={"dash-bg-three"}
          />

          {/* box comp */}
          <DashboardBoxComp
            image={coins}
            header={t("dashboard.totalProjectCoins")}
            // data
            number={totalCoins}
            bg={"dash-bg-four"}
          />
        </div>
      </div>

      <div className="drop-shadow-md grid grid-cols-1 lg:grid-cols-2 gap-[16px]  ">
        {/* big box container */}
        {/* content area */}
        <BigWhiteContainer
          header={t("dashboard.ongoingProjects")}
          link={"../projects/my-projects"}
          theme={theme}
        >
          {projects?.length === 0 ? (
            <div className="text-sm text-[#ACB5BB]">
              {t("dashboard.noProjectsAvailable")}
            </div>
          ) : (
            projects?.slice(0, 4).map((data, index) => {
              return (
                <OngoingProjectsDash
                  key={index}
                  theme={theme}
                  image={grey}
                  id={data.id}
                  status={data.project_status}
                  name={data.project_name}
                  date={moment(data.created_at).format("DD-MM-YYYY")}
                  goal={data.project_goal}
                  percentage={`${data.coins_collected}(${
                    (data.coins_collected / data.project_goal) * 100
                  }%)`}
                />
              );
            })
          )}
        </BigWhiteContainer>

        {/* right */}
        <BigWhiteContainer
          header={t("dashboard.recentNotification")}
          link={"../notification"}
          theme={theme}
        >
          {notification?.length === 0 ? (
            <div className="text-sm text-[#ACB5BB]">
              {t("dashboard.noNotification")}
            </div>
          ) : (
            notification?.map((data, index) => {
              if (data.notification_type === "coins") {
                return (
                  <ReceintNotComp
                    header={data.notification_title}
                    notification={data.notification_subject}
                    time={"2m"}
                    theme={theme}
                  />
                );
              } else {
                return (
                  <ProjectNotification
                    image={grey}
                    name={data.notification_title}
                    message={data.notification_subject}
                    time={"30h"}
                    theme={theme}
                  />
                );
              }
            })
          )}
          {/* comp */}

          {/* comp */}
        </BigWhiteContainer>
      </div>
    </div>
  );
};

export default Dashboard;
