import React, { useState } from "react";
import cancelBlack from "../../assests/website/cancel.svg";
import cancelWhite from "../../assests/website/cancel-black.svg";
import WebNavComp from "../website/WebNavComp";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/firebaseConfig";
import WebNavCompChildren from "./WebNavCompChildren";
import LogOutModal from "./LogOutModal";
import { useTranslation } from "react-i18next";

const SwitchNavCompMobile = ({ setOpenHam, theme }) => {
  const navigate = useNavigate("");
  const [open, setOpen] = useState("");
  const { t } = useTranslation();
  return (
    <div className="">
      {/* cancel button */}
      <div className=" flex justify-end mb-[45px]">
        <div
          onClick={() => {
            setOpenHam(false);
          }}
          className="cursor-pointer"
        >
          {theme ? (
            <img src={cancelWhite} alt=""></img>
          ) : (
            <img src={cancelBlack} alt=""></img>
          )}
        </div>
      </div>

      {/* main */}
      <div className="">
        {/* flex */}
        <div className=" flex flex-col">
          {/* comp */}
          <WebNavComp
            label={t("appHeader.dashboard")}
            link={"../dashboard/"}
            close={setOpenHam}
            theme={theme}
          />
          <WebNavCompChildren
            label={t("appHeader.project")}
            close={setOpenHam}
            theme={theme}
            chilDren={[
              {
                name: t("appHeader.table"),
                link: "../dashboard/projects/my-projects",
              },
              {
                name: t("appHeader.create"),
                link: "../dashboard/projects/create-project",
              },
            ]}
          />
          <WebNavCompChildren
            label={t("appHeader.settings")}
            close={setOpenHam}
            theme={theme}
            chilDren={[
              {
                name: t("appHeader.personalInfo"),
                link: "../dashboard/settings/personal-info",
              },
              {
                name: t("appHeader.security"),
                link: "../dashboard/settings/security",
              },
            ]}
          />

          <div className="flex flex-col gap-[15px] mt-[34px]">
            <div
              onClick={() => {
                setOpen(true);
              }}
              className="cursor-pointer border border-[red] rounded-[8px] py-[12px] flex items-center justify-center w-full"
            >
              <p className="text-[14px] font-semibold leading-[20px]">
                {t("logout.logout")}
              </p>
            </div>

            <LogOutModal open={open} setOpen={setOpen} theme={theme} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwitchNavCompMobile;
