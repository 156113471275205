import React from "react";
import WebsiteButton from "./WebsiteButton";
import rewards from "../../assests/webapp/reward.png";
import curveDown from "../../assests/website/home-curve.svg";
import curveWhite from "../../assests/website/green-bottom-curve.svg";
import reward1 from "../../assests/website/svg-up.svg";
import reward2 from "../../assests/website/rewards-svg1.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PapRewards = ({ theme }) => {
  const { t } = useTranslation();
  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };
  const navigate = useNavigate("");
  return (
    <div className=" relative text-[white]  my-[150px] ">
      {/* absolute svg */}
      {/* <div className="absolute z-10 right-0">
        <img src={reward1} alt=""></img>
      </div> */}

      {/* absolute svg */}
      {/* <div className="absolute z-10 right-0 bottom-[0px]">
        <img src={reward2} alt=""></img>
      </div> */}

      {/* curve top */}
      <div className=" rotate-180 w-full  relative z-10">
        {/* absolute image */}
        <div className="absolute hidden md:block bottom-[6%] left-0 w-[83%]  rotate-180 z-10 ">
          <img className="w-full" src={reward1} alt=""></img>
        </div>
        {theme ? (
          <img className="w-full" src={curveWhite} alt=""></img>
        ) : (
          <img className="w-full" src={curveDown} alt=""></img>
        )}
      </div>
      <div
        className={theme ? "bg-[#0B7026] relative" : "bg-[#1A1C1E]   relative"}
      >
        {" "}
        {/* absolute image
        <div className="absolute top-0 right-0  z-10">
          <img src={reward1} alt=""></img>
        </div> */}
        {/* main */}
        <div className="py-[30px] lg:py-0  max-w-[1200px] mx-[24px]  xl:mx-auto  relative z-0">
          {/* flex */}
          <div className="grid grid-cols-1 lg:grid-cols-2 items-center relative">
            {/* left */}
            <div className="">
              <p className="text-[28px] md:text-[60px] leading-[45px] md:leading-[76px] text-center lg:text-start font-extrabold">
                {t("home.papRewards.header")}
              </p>
              <p className="max-w-[511px] text-center mx-auto lg:mx-0 lg:text-start text-sm leading-[28px] md:text-lg font-normal mb-[24px]">
                {t("home.papRewards.words")}
              </p>
              {/* button */}
              <div
                onClick={() => {
                  navigate("/pap-rewards");
                  moveToTop();
                }}
                className="w-full sm:w-[310px] mx-auto lg:mx-0 cursor-pointer"
              >
                <WebsiteButton
                  label={t("home.papRewards.button")}
                  theme={theme}
                />
              </div>
            </div>

            {/* right */}
            <div className=" mt-[50px] lg:mt-0 mx-auto lg:mx-0 flex justify-end">
              <div className=" w-fit">
                <img src={rewards} alt=""></img>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* curve bottom */}
      <div className="relative w-full">
        {/* absolute image */}
        <div className="hidden md:block absolute top-[-97%] right-0 w-[8%]  z-10">
          <img className="w-full" src={reward2} alt=""></img>
        </div>{" "}
        {theme ? (
          <img className="w-full" src={curveWhite} alt=""></img>
        ) : (
          <img className="w-full" src={curveDown} alt=""></img>
        )}
      </div>
    </div>
  );
};

export default PapRewards;
