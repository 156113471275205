import React, { useEffect, useState } from "react";
import PreferenceNav from "./PreferenceNav";

// assests
import america from "../../assests/webapp/america.svg";
import SearchRegionComp from "./SearchRegionComp";
import ButtonToggle from "./ButtonToggle";
import NewTabArea from "./NewTabArea";
import FormButtonTwo from "./FormButtonTwo";
import FormButtonComp from "./FormButtonComp";

const Preferences = ({ setNavlink }) => {
  useEffect(() => {
    setNavlink("Preferences");
  }, []);
  return (
    <div className="">
      {/* heading  */}
      <div className=" flex items-center justify-between mb-[35px]">
        {/* left */}
        <div>
          <p className="text-[24px] leading-[27px] font-medium">Theme</p>
        </div>

        {/* right */}
        <div className=" flex gap-[24px]">
          <PreferenceNav label={"Default"} active={true} />
          <PreferenceNav label={"Light"} active={false} />
          <PreferenceNav label={"Dark"} active={false} />
        </div>
      </div>

      {/* line area */}
      <div className="border border-[#EDF1F3]"></div>

      {/* search region area */}
      <div className=" w-full md:w-[85%] flex flex-col gap-[24px] my-[32px]">
        {/* comp1 */}
        <SearchRegionComp
          header={"Search Region"}
          image={america}
          view={true}
        />
        <SearchRegionComp header={"Website Language"} />
        <SearchRegionComp header={"Safe Search"} />
      </div>

      {/* line area */}
      <div className="border border-[#EDF1F3]"></div>

      {/* new tab area */}
      <div className=" my-[32px] w-full md:w-[85%]  flex flex-col gap-[24px]">
        {/* comp1 */}
        <NewTabArea header={"New tab"} />
        {/* comp1 */}
        <NewTabArea header={"Auto suggestions"} />
        {/* comp1 */}
        <NewTabArea header={"Personalised search results"} />
        {/* comp1 */}
        <NewTabArea header={"Notifications"} />
      </div>

      {/* line area */}
      <div className="border border-[#EDF1F3]"></div>

      {/* allow cookies */}
      <div className=" my-[32px] w-full md:w-[85%]  flex flex-col gap-[24px]">
        {/* comp1 */}
        <NewTabArea header={"Allow all cookies"} />
        {/* comp1 */}
        <NewTabArea header={"Block third-party cookies"} />
        {/* comp1 */}
        <NewTabArea header={"Block all cookies"} />
      </div>

      {/* submit button area */}
      <div className=" grid grid-cols-2 sm:grid-cols-4 gap-[24px]  w-full md:w-[85%]  mt-[16px]">
        {/* cancel */}
        <FormButtonTwo label={"Cancel"} start={true} />
        {/* yes */}
        <FormButtonComp label={"Yes"} start={true} />
      </div>
    </div>
  );
};

export default Preferences;
