import React, { useEffect, useState, useRef } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  increment,
  updateDoc,
} from "firebase/firestore";
import { signInWithCustomToken } from "firebase/auth";
import { db, auth } from "../../firebase/firebaseConfig";
import { toast } from "react-toastify";

// assests

import coins from "../../assests/website/header-coins.svg";
import american_flag from "../../assests/website/america.svg";
import french_flag from "../../assests/website/flag-of-france-rounded-corners.png";
import german_flag from "../../assests/website/Flag_of_Germany.svg.png";
import chinese_flag from "../../assests/website/Flag_of_the_People's_Republic_of_China.svg.png";

// components
import CoinComp from "./CoinComp";
import LogoSmall from "./LogoSmall";
import SearchComponent from "../webapp/SearchComponent";
import WebsiteButton from "./WebsiteButton";
import WebsiteButtonTwo from "./WebsiteButtonTwo";
import WebNavComp from "./WebNavComp";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SwitchNavComp from "./SwitchNavComp";
import SignAsComp from "./SignAsComp";
import { onSnapshot } from "firebase/firestore";
import SearchButton from "./SearchButton";
import HamburgerMenu from "./HamburgerMenu";
import { useTranslation } from "react-i18next";

// globals
import updateTotalSearchCount from "../../globals/updateTotalSearchCount";
import updateTotalCoinsForGuestUser from "../../globals/updateTotalCoinsForGuestUser";
import updateUser from "../../globals/updateUser";
import AddToChromeBtn from "./AddToChromeBtn";
import ClickOutsideRef from "../../globals/clickOutsideRef";

const Header = ({ theme }) => {
  // useStates
  const [openHam, setOpenHam] = useState(false);
  const [sign, setSign] = useState("");
  const [changeView, setChangeView] = useState(false);
  const navigate = useNavigate("");
  const [navbar, setNavbar] = useState(false);
  const [user, setUser] = useState();
  const [value, setValue] = useState("");
  // const [theme, setTheme] = useState(false);
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const getLanguage = JSON.parse(localStorage.getItem("language"));

  const ref = useRef();
  ClickOutsideRef(ref, setOpenHam);
  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };

  const { t } = useTranslation();
  // console.log("params", params["*"]);
  // console.log("params2", searchParams.get("q"));

  const getUrlParameter = (sParam) => {
    const sPageURL = window.location.search.substring(1); //get the url parameter
    const sURLVariables = sPageURL.split("&");
    var sParameterName, i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=");

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined
          ? null
          : decodeURIComponent(sParameterName[1]);
      }
    }

    return null;
  };

  // fetch user from firebase
  const fetchUser = async () => {
    if (currentUser) {
      const docRef = doc(db, "users", currentUser.uid);
      try {
        // const querySnapshot = getDoc(docRef);
        onSnapshot(docRef, (snapshot) => {
          setUser(snapshot.data());
          // console.log(user);
        });
        // Handle the data or dispatch an action here
        // console.log("snapshot userslice", querySnapshot.data());
      } catch (error) {
        // Handle the error or show an error message to the user
        // console.log("Error fetching document:", error);
      }
    }
  };

  // useEffect for fetching user
  useEffect(() => {
    checkIfUserIsFromMobileApp();
    fetchUser();
    // setSearchParams("");
  }, []);

  useEffect(() => {}, [user]);

  const changeHeaderNav = () => {
    // console.log(window.scrollY);
    if (window.scrollY >= 25) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const countryFlags = {
    en: american_flag,
    fr: french_flag,
    ch: chinese_flag,
    ge: german_flag,
  };

  window.addEventListener("scroll", changeHeaderNav);

  const gotoSearch = async () => {
    if (value === "" || value === null) {
      return;
    }
    localStorage.setItem("global_search", value);
    updateTotalCoinsForGuestUser(currentUser);
    await updateUser(currentUser);
    await updateTotalSearchCount();

    window.location.href = `/search?q=${value}`;
  };

  const checkIfUserIsFromMobileApp = async () => {
    if (currentUser) {
      return;
    }

    const uid = getUrlParameter("uid");
    const token = getUrlParameter("token");
    const systemOS = getUrlParameter("os");

    if (systemOS !== null) {
      localStorage.setItem("os", systemOS.toLowerCase());
    }

    if (uid === null || token === null) {
      return;
    }

    const URL = `https://us-west1-people-and-planet.cloudfunctions.net/getuserbytoken?uid=${uid}&token=${token}`;

    const resp = await fetch(URL);

    const data = await resp.json();

    if (data["error"]) {
      toast.error(data["message"]);
      return;
    }

    // signInWithCustomToken(auth)

    const user = data["user"];
    const credentials = data["credentials"];

    localStorage.setItem(
      "currentUser",
      JSON.stringify({
        user_type: user["user_type"],
        ...credentials,
      })
    );

    window.location.reload();
  };

  return (
    <div
      className={
        navbar
          ? theme
            ? " py-6 bg-[white]  fixed w-full z-30 drop-shadow-md "
            : " py-6  dark-theme fixed w-full z-30 drop-shadow-md"
          : theme
          ? "py-6 bg-[white] fixed w-full z-30"
          : "py-6 dark-theme fixed w-full z-30"
      }
    >
      <div className=" max-w-[1200px] mx-[24px] xl:mx-auto">
        <div className=" ">
          {/* flex */}
          <div className="flex items-center justify-between gap-[30px]">
            {/* logo and search */}
            <div className=" flex items-center gap-[32px] xl:gap-[60px] lg:w-full ">
              {/* logo */}
              <div className="cursor-pointer  hidden lg:block">
                <LogoSmall />
              </div>

              {/* mobile  ******************/}
              {/* coins */}
              <div
                className="block lg:hidden cursor-pointer"
                onClick={() => {
                  navigate("/pap-rewards");
                  moveToTop();
                }}
              >
                <CoinComp
                  image={coins}
                  word={
                    user
                      ? user?.total_coins
                      : localStorage.getItem("total_coins") === null ||
                        localStorage.getItem("total_coins") === "null"
                      ? 0
                      : localStorage.getItem("total_coins")
                  }
                  theme={theme}
                />
              </div>

              {/* search bar and button for larger screens */}

              <div className="hidden lg:flex gap-[30px]   w-full ">
                <div className=" flex gap-[7px] items-center w-full ">
                  <div className="w-full max-w-[531px]">
                    <SearchComponent
                      setValue={setValue}
                      value={value}
                      bg={"gray"}
                      theme={theme}
                      onSubmit={() => {
                        // navigate("/search");
                        gotoSearch();
                      }}
                      placeholder={t("home.header.search.placeholder")}
                    />
                  </div>

                  <SearchButton
                    value={value}
                    theme={theme}
                    onSubmit={() => {
                      // console.log("Entering header large");
                      gotoSearch();
                    }}
                  />
                </div>

                {searchParams.get("q") !== null && (
                  <div className="shrink-0">
                    <AddToChromeBtn />
                  </div>
                )}
              </div>
            </div>

            {/* mobile ************/}
            {/* logo */}
            <div
              onClick={() => {
                navigate("/");
              }}
              className="cursor-pointer  block lg:hidden"
            >
              <LogoSmall />
            </div>

            {/* coins/flag and ham*/}
            <div className="flex items-center gap-[30px] xl:gap-[60px]">
              {/* coins and flag */}
              <div className=" flex items-center gap-[30px] xl:gap-[60px]">
                {/* coins */}
                <div
                  className="hidden lg:block cursor-pointer"
                  onClick={() => {
                    navigate("/pap-rewards");
                    moveToTop();
                  }}
                >
                  <CoinComp
                    image={coins}
                    word={
                      user
                        ? user?.total_coins
                        : localStorage.getItem("total_coins") === null ||
                          localStorage.getItem("total_coins") === "null"
                        ? 0
                        : localStorage.getItem("total_coins")
                    }
                    theme={theme}
                  />
                </div>

                <div
                  className="hidden lg:block cursor-pointer"
                  onClick={() => {
                    navigate("/settings");
                    moveToTop();
                  }}
                >
                  <CoinComp
                    image={
                      getLanguage !== null
                        ? countryFlags[getLanguage.value] ?? american_flag
                        : american_flag
                    }
                    word={
                      getLanguage !== null
                        ? getLanguage?.value?.toUpperCase()
                        : "EN"
                    }
                    theme={theme}
                  />
                </div>
              </div>

              {/* harmburger menu */}
              <div>
                {/* shown */}
                <HamburgerMenu theme={theme} setOpenHam={setOpenHam} />
                {/* hidden */}
                <div
                  ref={ref}
                  className={
                    openHam
                      ? theme
                        ? "bg-[white] border border-[#DCE4E8] rounded-bl-[24px] max-w-[420px] w-[100%] fixed top-[25px] right-0 z-50 px-[16px] py-[33px]"
                        : " bg-[#2A2C2E] rounded-bl-[24px] max-w-[420px] w-[100%] fixed top-[25px] right-0 z-50 px-[16px] py-[33px]"
                      : "hidden"
                  }
                >
                  {changeView ? (
                    <SignAsComp
                      sign={sign}
                      user={""}
                      setOpenHam={setOpenHam}
                      setChangeView={setChangeView}
                      theme={theme}
                    />
                  ) : (
                    <SwitchNavComp
                      setOpenHam={setOpenHam}
                      setSign={setSign}
                      setChangeView={setChangeView}
                      theme={theme}
                    />
                  )}
                  {/* switch nav component */}

                  {/* signin as _ */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" mt-[30px] flex justify-center items-center lg:hidden">
          {/* search bar and button for mobile */}

          <div className="flex lg:hidden items-center  md:w-auto w-full ">
            {/* search */}
            <div className=" w-full md:w-[531px]">
              <SearchComponent
                value={searchParams.get("q")}
                setValue={setValue}
                bg={"gray"}
                theme={theme}
                phone={true}
                onSubmit={() => {
                  gotoSearch();
                }}
                placeholder={t("home.header.search.placeholder")}
              />
            </div>

            {/* button */}
            <SearchButton
              value={value}
              theme={theme}
              phone={true}
              onSubmit={() => {
                // console.log("Entering header small");
                gotoSearch();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
