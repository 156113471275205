import React from "react";

import noResult from "../../assests/website/no-result.svg";

import LeafHeader from "./LeafHeader";
import WebsiteButton from "./WebsiteButton";

const NoResults = ({ theme, error }) => {
  const onClick = () => {
    window.location.reload();
  };
  return (
    <div className=" mt-[100px]">
      {/* comp */}
      <div className="grid grid-cols-1 md:grid-cols-2 items-center">
        {/* left */}
        <div className="row-start-2 md:row-start-auto">
          <img className="w-full md:w-[80%] " src={noResult} alt=""></img>
        </div>

        {/* right */}
        <div className="  row-start-1 md:row-start-auto">
          {/* leaf comp */}
          <div className={theme ? "text-[#1A1C1E]" : ""}>
            <LeafHeader label={"Page Not Found"} />
          </div>

          <p
            className={
              theme
                ? "text-[#a2a6aa] text-center md:text-left text-[18px] font-normal leading-[28px] mt-[12px] mb-[30px]"
                : "text-[18px] text-center md:text-left font-normal leading-[28px] mt-[12px] mb-[30px]"
            }
          >
            {error.message}
          </p>

          {/* button */}
          <div className="w-[207px] mx-auto md:mx-0">
            <WebsiteButton label={"Try again"} onClick={onClick} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoResults;
