import React from "react";
import arrow from "../../assests/website/back_nav.svg";

const CircleArrow = () => {
  return (
    <div className="rounded-full bg-[#1A1C1E] border border-[white] flex items-center justify-center w-[40px] h-[40px]">
      <img src={arrow} alt=""></img>
    </div>
  );
};

export default CircleArrow;
