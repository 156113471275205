import React, { useEffect } from "react";

// components
import AntTableComp from "./AntTableComp";
import SearchComponent from "./SearchComponent";
import SearchRegionComp from "./SearchRegionComp";
import FilterComp from "./FilterComp";
import { Navigate, Route, Routes } from "react-router-dom";
import ProjectAntTableComp from "./ProjectAntTableComp";
import ProjectDetails from "./ProjectDetails";
import ProjectCreate from "./ProjectCreate";
import { useTranslation } from "react-i18next";

const ProjectAntTable = ({ setNavlink }) => {
  const { t } = useTranslation();
  useEffect(() => {
    setNavlink(t("appHeader.myProjects"));
  }, []);
  return (
    <div>
      <Routes>
        <Route index element={<Navigate to="table" replace />} />
        <Route path="table" element={<ProjectAntTableComp />} />
        <Route path="details/:id" element={<ProjectDetails />} />
        <Route
          path="edit/:id"
          element={<ProjectCreate setNavlink={setNavlink} />}
        />
      </Routes>
    </div>
  );
};

export default ProjectAntTable;
