import { combineReducers } from "redux";
// import usersReducer from './usersReducer'
// import postsReducer from './postsReducer'
import ProjectSlice from "./ProjectSlice";
import UserAuthSlice from "./UserAuthSlice";
import UserSlice from "./UserSlice";
import NotificationSlice from "./NotificationSlice";
import { TypeUserSlice } from "./TypeUserSlice";
import ThemeSlice from "./ThemeSlice";
import languageSlice from "./LanguageSlice";

const rootReducer = combineReducers({
  project: ProjectSlice,
  userAuth: UserAuthSlice,
  user: UserSlice,
  notification: NotificationSlice,
  userType: TypeUserSlice,
  theme: ThemeSlice,
  language: languageSlice,

  // users: usersReducer,
  // posts: postsReducer,
});

export default rootReducer;
