import React, { useEffect } from "react";
import chrome from "../../assests/website/chrome.svg";
import yandex from "../../assests/website/yandex.svg";
import chromium from "../../assests/website/chromium.svg";
import opera from "../../assests/website/opera.svg";
import safari from "../../assests/website/safari.svg";
import firefox from "../../assests/website/firefox.svg";
import IE from "../../assests/website/internet-explorer.svg";
import edge from "../../assests/website/edge.svg";
import {
  isIE,
  isChrome,
  isChromium,
  isFirefox,
  isEdge,
  isSafari,
  isOpera,
  isYandex,
} from "react-device-detect";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import translationEN from "../../translations/en/home/heroSection.json";
// import translationFR from "../../translations/fr/home/heroSection.json";
import { useSelector } from "react-redux";
// const resources = {
//   en: {
//     translation: translationEN,
//   },
//   fr: {
//     translation: translationFR,
//   },
// };

// i18n.use(initReactI18next).init({
//   resources,
//   lng: "en",
//   fallbackLng: "en",
//   interpolation: {
//     escapeValue: false,
//   },
// });

const AddToChromeBtn = ({ theme }) => {
  const lang = useSelector((state) => state.language.langState);
  const { t } = useTranslation();
  // const handleLanguageChange = (lang) => {
  //   i18n.changeLanguage(lang);
  // };

  // useEffect(() => {
  //   handleLanguageChange(lang);
  // }, []);

  return (
    <div
      className="bg-[#0B7026]  cursor-pointer py-[11px] px-[10px] max-w-[357px] hidden  rounded-[8px]  lg:flex items-center gap-[8px] justify-center"
      onClick={() => {
        if (isChrome) {
          window.location.href =
            "https://chrome.google.com/webstore/detail/pap-earth/ninlhgpimpglegioafgikkfefhbcdghk";
          return;
        } else if (isSafari) {
          window.location.href =
            "https://apps.apple.com/ng/app/pap-extension/id1666022406?mt=12";
          return;
        }
      }}
    >
      {isChrome ? (
        <>
          <div className="h-[24px] w-[24px] min-w-[24px] ">
            <img src={chrome} className="w-full h-full object-fit" alt=""></img>
          </div>
          <p className={"text-[white] text-[18px] font-semibold"}>
            {t("home.heroSection.button")} Chrome
          </p>
        </>
      ) : isIE ? (
        <>
          <div className="h-[24px] w-[24px] min-w-[24px]">
            <img src={IE} className="w-full h-full object-fit" alt=""></img>
          </div>
          <p className={"text-[white] text-[18px] font-semibold"}>
            {t("home.heroSection.button")} Internet Explorer
          </p>
        </>
      ) : isSafari ? (
        <>
          <div className="h-[24px] w-[24px] min-w-[24px]">
            <img src={safari} className="w-full h-full object-fit" alt=""></img>
          </div>
          <p className={"text-[white] text-[18px] font-semibold"}>
            {t("home.heroSection.button")} Safari
          </p>
        </>
      ) : isYandex ? (
        <>
          <div className="h-[24px] w-[24px] min-w-[24px]">
            <img src={yandex} className="w-full h-full object-fit" alt=""></img>
          </div>
          <p className={"text-[white] text-[18px] font-semibold"}>
            {t("home.heroSection.button")} Yandex
          </p>
        </>
      ) : isFirefox ? (
        <>
          <div className="h-[24px] w-[24px] min-w-[24px]">
            <img
              src={firefox}
              className="w-full h-full object-fit"
              alt=""
            ></img>
          </div>
          <p className={"text-[white] text-[18px] font-semibold"}>
            {t("home.heroSection.button")} Firefox
          </p>
        </>
      ) : isEdge ? (
        <>
          <div className="h-[24px] w-[24px] min-w-[24px]">
            <img src={edge} className="w-full h-full object-fit" alt=""></img>
          </div>
          <p className={"text-[white] text-[18px] font-semibold"}>
            {t("home.heroSection.button")} Edge
          </p>
        </>
      ) : isOpera ? (
        <>
          <div className="h-[24px] w-[24px] min-w-[24px]">
            <img src={opera} className="w-full h-full object-fit" alt=""></img>
          </div>
          <p className={"text-[white] text-[18px] font-semibold"}>
            {t("home.heroSection.button")} Opera
          </p>
        </>
      ) : isChromium ? (
        <>
          <div className="h-[24px] w-[24px] min-w-[24px]">
            <img
              src={chromium}
              className="w-full h-full object-fit"
              alt=""
            ></img>
          </div>
          <p className={"text-[white] text-[18px] font-semibold"}>
            {t("home.heroSection.button")} Browser
          </p>
        </>
      ) : (
        <>
          <p className={"text-[white] text-[18px] font-semibold"}>
            {t("home.heroSection.button")} Browser
          </p>
        </>
      )}
    </div>
  );
};

export default AddToChromeBtn;
