import React from "react";

const DashboardBoxComp = ({ image, header, number, bg }) => {
  return (
    <div
      className={` cursor-pointer p-[28px]  text-[white] rounded-[8px] ${bg}`}
    >
      {/* flex */}
      <div className="flex  gap-[7px]  mb-[19px] items-center">
        <div className="">
          <img src={image} alt=""></img>
        </div>
        <p className="font-semibold text-lg md:text-[20px] md:leading-[30px]">
          {header}
        </p>
      </div>
      {/* number */}
      <p className="font-bold text-[28px] md:text-[42px] leading-[47px] md:leading-[64px]">
        {number}
      </p>
    </div>
  );
};

export default DashboardBoxComp;
