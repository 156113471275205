import React from "react";
import ProjectComp from "./ProjectComp";
import ProjectMainContainer from "./ProjectMainContainer";
import FetchProjects from "./FetchProjects";
import { useTranslation } from "react-i18next";

const AllProjects = ({ theme }) => {
  const { t } = useTranslation();
  return (
    <div className={theme ? "text-[#1A1C1E]" : "text-white "}>
      <div className=" max-w-[1200px] mx-[24px]  xl:mx-auto  relative mt-[80px] md:mt-[100px] mb-[150px]">
        {/* header */}
        <div className="mx-auto max-w-[922px]">
          <p className="text-[28px] md:text-[60px] font-extrabold leading-[47px] md:leading-[59px] mb-[11px] md:mb-[8px] text-center ">
            {t("allProjects.header")}
          </p>
          {/* <p className="text-sm md:text-[18px] font-normal leading-[28px] md:leading-[33px] text-center">
            Browse through the list of ongoing projects created by other users.
            You can support or be part of any of these projects
          </p> */}
        </div>

        {/* main */}
        <FetchProjects theme={theme} all={true} showSearch={true} />
      </div>
    </div>
  );
};

export default AllProjects;
