import React, { useEffect, useRef, useState } from "react";
import click from "../../assests/website/click-big.png";
import caro from "../../assests/website/hpw-carousel.png";
import WebsiteButton from "./WebsiteButton";

import { useNavigate } from "react-router-dom";
import LeafHeader from "./LeafHeader";
import CarouselHome from "./CarouselHome";
import CarouselHomeLeft from "./CarouselHomeLeft";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { useSelector } from "react-redux";

const HowPapWorksHome = ({ theme }) => {
  const [steps, setSteps] = useState(1);
  const [rec, setRec] = useState("");

  const { t } = useTranslation();

  // Start the timer when the component is mounted
  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update the count state every second
      if (steps >= 3) {
        setSteps((prevStep) => prevStep - 2);
      } else {
        setSteps((prevStep) => prevStep + 1);
      }
    }, 5000);

    // Clean up the interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };
  }, [steps]); // Empty dependency array ensures the effect runs only once, like componentDidMount

  // this is the new way of getting each section to scroll
  // get the boundingclient rec and when it scrolls to the top of this section its fixed
  // const ref = useRef(null);

  // const printRec = () => {
  //   if (ref.current) {
  //     setRec(ref.current.getBoundingClientRect());
  //     // const{top,x, y,width}=rect
  //     window.addEventListener("scroll", (event) => {
  //       console.log("Element position:", rec?.left, rec?.top, rec?.x, rec?.y);
  //     });
  //   }
  // };

  // useEffect(() => {
  //   printRec();
  // }, [ref]);

  // when the scroll reaches a setting point it changes carousel
  // after all the carousel is shown it makes it unfixed

  const navigate = useNavigate("");
  return (
    <div
      className={
        theme
          ? "text-[#1a1c1e]  mt-[150px] mb-[150px] sm:mb-[200px]"
          : "text-[white]  mt-[150px] mb-[150px] sm:mb-[200px] "
      }
    >
      {/* header */}
      <div className="mb-[50px]">
        <div className="w-fit mx-auto lg:mx-0">
          <LeafHeader label={t("home.howPapWorksHome.header")} size={60} />
        </div>

        <p className="text-base md:text-[18px] text-center lg:text-start leading-[28px] font-normal mt-[8px]">
          {t("home.howPapWorksHome.words")}
        </p>
      </div>

      {/* grid */}
      <div className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-y-[62px]">
        {/* left */}
        {steps === 1 && (
          <CarouselHomeLeft
            number={t("home.howPapWorksHome.carousel.stepOne.number")}
            header={t("home.howPapWorksHome.carousel.stepOne.header")}
            step={t("home.howPapWorksHome.carousel.stepOne.words")}
          />
        )}
        {steps === 2 && (
          <CarouselHomeLeft
            number={`${t("home.howPapWorksHome.carousel.stepTwo.number")}`}
            header={`${t("home.howPapWorksHome.carousel.stepTwo.header")}`}
            step={`${t("home.howPapWorksHome.carousel.stepTwo.words")}`}
          />
        )}
        {steps === 3 && (
          <CarouselHomeLeft
            number={`${t("home.howPapWorksHome.carousel.stepThird.number")}`}
            header={`${t("home.howPapWorksHome.carousel.stepThird.header")}`}
            step={`${t("home.howPapWorksHome.carousel.stepThird.words")}`}
          />
        )}
        {/* right */}
        <div className=" flex  justify-end ">
          <CarouselHome theme={theme} steps={steps} setSteps={setSteps} />
        </div>
      </div>
    </div>
  );
};

export default HowPapWorksHome;
