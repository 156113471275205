import React from "react";

import facebook from "../../assests/website/facebook.svg";
import twitter from "../../assests/website/twitter.svg";
import instagram from "../../assests/website/instagram.svg";
import linkedIn from "../../assests/website/linkedIn.svg";
import { Link } from "react-router-dom";

const Socials = () => {
  return (
    <div className=" flex items-center gap-[32px]">
      {/* facebook */}
      <Link
        to={"https://facebook.com/weareforpap"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={facebook} alt=""></img>
      </Link>
      {/* twitter */}
      <Link
        to={"https://twitter.com/weareforpap"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={twitter} alt=""></img>
      </Link>
      {/* instagram */}
      <Link
        to={"https://www.instagram.com/weareforpap/"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={instagram} alt=""></img>
      </Link>
      {/* linkedIn */}
      <Link
        to={"https://www.linkedin.com/company/weareforpap/"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={linkedIn} alt=""></img>
      </Link>
    </div>
  );
};

export default Socials;
