import { createSlice } from "@reduxjs/toolkit";

const data = [];

export const TypeUserSlice = createSlice({
  // name
  name: "userType",
  initialState: {
    data,
  },

  // reducers
  reducers: {
    updateUserType: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { updateUserType } = TypeUserSlice.actions;
export default TypeUserSlice.reducer;
