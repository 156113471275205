import React, { useState } from "react";
import eye from "../../assests/webapp/eye.svg";
import edit from "../../assests/webapp/edit.svg";
import moment from "moment";
import { useNavigate } from "react-router";
import MobileTablePagination from "./MobileTablePagination";
import { addCommas } from "separate-comma-numbers";
import { useTranslation } from "react-i18next";

const TableMobile = ({ dataSource }) => {
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(10);
  const navigate = useNavigate();
  const { t } = useTranslation();
  // console.log("created at", dataSource[0]?.created_at);
  return (
    <div className=" flex flex-col gap-y-[10px] mt-[10px]  ">
      {dataSource?.slice(startIndex, endIndex).map((data, index) => (
        <div className="  bg-[white]  rounded-[16px] p-[24px]">
          {/* comp */}
          <div className="flex-col gap-y-[16px] ">
            <p className="text-base font-semibold text-center">
              {startIndex + (index + 1)}
            </p>
            {/* project name */}
            <div className="flex justify-between gap-[10px]">
              {/* header */}
              <p className="text-base font-semibold">
                {t("projectTable.projectName")}
              </p>
              {/* result */}
              <p className="font-normal text-sm text-[#ACB5BB]">
                {data.project_name}
              </p>
            </div>
            {/* project date */}
            <div className="flex justify-between gap-[10px]">
              {/* header */}
              <p className="text-base font-semibold">
                {t("projectTable.creationDate")}
              </p>
              {/* result */}
              <div className="flex gap-[10px]">
                <p className="">
                  {moment(data.created_at).format("DD-MM-YYYY")}
                </p>
                <p className="font-normal text-sm text-[#ACB5BB] float-right">
                  {moment(data.created_at).format("h:mm A")}
                </p>
              </div>
            </div>
            {/* coins collected */}
            <div className="flex justify-between gap-[10px]">
              {/* header */}
              <p className="text-base font-semibold">
                {t("projectTable.coinsCollected")}
              </p>
              {/* result */}
              <div className="flex gap-[10px]">
                <p className="font-normal text-sm">{`${data.coins_collected}(${
                  (data.coins_collected / data.project_goal) * 100
                }%)`}</p>
                <p className="font-normal text-sm text-[#ACB5BB] float-right">
                  {addCommas(data.project_goal)}
                </p>
              </div>
            </div>
            {/* project support */}
            <div className="flex justify-between gap-[10px]">
              {/* header */}
              <p className="text-base font-semibold">
                {t("projectTable.support")}
              </p>
              {/* result */}
              <p className="font-normal text-sm text-[#ACB5BB]">
                {data.total_support}
              </p>
            </div>
            {/* project status */}
            <div className="flex justify-between gap-[10px]">
              {/* header */}
              <p className="text-base font-semibold">
                {t("projectTable.status")}
              </p>
              {/* result */}
              <div
                className={
                  data.project_status === "approved"
                    ? "py-[6px] px-[12px] w-fit rounded-[4px] font-normal text-sm bg-[#ECF8F0] text-[#1C8C6E]"
                    : data.project_status === "pending"
                    ? "py-[6px] w-fit px-[12px] rounded-[4px] font-normal text-sm bg-[#FBF4E4] text-[#FBBC04]"
                    : "py-[6px] w-fit px-[12px] rounded-[4px] font-normal text-sm bg-[#FFEFEF] text-[#FF0319]"
                }
              >
                <p className="text-sm font-bold">{data.project_status}</p>
              </div>
            </div>
          </div>
          {/* line */}
          <div className="my-[20px] bg-[#EDF1F3] w-full h-[1px]"></div>
          {/* Actions */}
          <div className=" flex justify-between gap-[10px]">
            {/* hide */}
            <div
              className="cursor-pointer "
              onClick={() => {
                navigate(`../details/${data.id}`);
              }}
            >
              <img src={eye} alt=""></img>
            </div>
            {/* edit */}
            <div
              className="cursor-pointer "
              onClick={() => {
                navigate(`../edit/${data.id}`);
              }}
            >
              <img src={edit} alt=""></img>
            </div>
          </div>
        </div>
      ))}

      {/* mobile table pagination */}
      <MobileTablePagination
        setStartIndex={setStartIndex}
        setEndIndex={setEndIndex}
        dataSource={dataSource}
        startIndex={startIndex}
        endIndex={endIndex}
      />
    </div>
  );
};

export default TableMobile;
