import Excel from "exceljs";
import { saveAs } from "file-saver";
export const downloadExcelData = async (data) => {
  console.log("task", data);
  const workbook = new Excel.Workbook();
  const workSheetName = "Worksheet-1";
  const workBookName = "PAP Projects";
  try {
    const columns = [];
    for (var v in data[0]) {
      if (
        `${v}` !== "id" ||
        `${v}` !== "timestamp" ||
        `${v}` !== "created_at"
      ) {
        columns.push({
          header: v,
          key: v,
          alignment: { horizontal: "center" },
          width: v.length + 5,
        });
      }
    }
    console.log(columns);
    const fileName = workBookName;
    // creating one worksheet in workbook
    const worksheet = workbook.addWorksheet(workSheetName);
    // add worksheet columns
    // each columns contains header and its mapping key from data
    worksheet.columns = columns;
    // updated the font for first row.
    worksheet.getRow(1).font = { bold: true };
    // loop through data and add each one to worksheet
    data.forEach((singleData) => {
      delete singleData["id"];
      delete singleData["timestamp"];
      delete singleData["created_at"];
      worksheet.addRow(singleData);
    });
    // write the content using writeBuffer
    const buf = await workbook.xlsx.writeBuffer();
    // download the processed file
    saveAs(new Blob([buf]), `${fileName}.csv`);
  } catch (error) {
    console.error("<<<ERRROR>>>", error);
    console.error("Something Went Wrong", error.message);
  } finally {
    // removing worksheet's instance to create new one
    workbook.removeWorksheet(workSheetName);
  }
};
