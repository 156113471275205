import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

const NavComp = ({ label, link, theme }) => {
  // const [state, setState] = useState(active);

  return (
    <NavLink to={link}>
      {({ isActive }) => (
        <div
          className={
            isActive
              ? theme
                ? "py-[14px] px-[34px] rounded-[8px] app-header-active-2 cursor-pointer"
                : "py-[14px] px-[34px] rounded-[8px] app-header-active cursor-pointer"
              : theme
              ? " py-[14px] px-[34px] cursor-pointer text-[#1a1c1e]"
              : " py-[14px] px-[34px] cursor-pointer text-[#A2A6AA]"
          }
        >
          <p className="text-sm font-normal ">{label}</p>
        </div>
      )}
    </NavLink>
  );
};

export default NavComp;
