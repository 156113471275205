import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import moveToTop from "../../globals/moveToTop";

const WebNavComp = ({ label, link, close, theme }) => {
  const [active, setActive] = useState(true);
  return (
    <NavLink to={link}>
      {({ isActive }) => (
        <div
          onClick={() => {
            close(false);
            moveToTop();
          }}
          className={
            isActive
              ? theme
                ? "webnav-bg-white curor-pointer rounded-[8px] px-[16px] py-[12px] text-[#1A1C1E]"
                : "webnav-bg curor-pointer rounded-[8px] px-[16px] py-[12px] text-[white] "
              : theme
              ? "text-[#1A1C1E] px-[16px] cursor-pointer py-[12px] "
              : "px-[16px] cursor-pointer py-[12px] text-[#7C7C7C]"
          }
        >
          <p className="text-base font-medium">{label}</p>
        </div>
      )}
    </NavLink>
  );
};

export default WebNavComp;
