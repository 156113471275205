import React from "react";

// assests
import coins from "../../assests/webapp/coins-small.svg";
import { addCommas } from "separate-comma-numbers";
import { useTranslation } from "react-i18next";

// import

const GreenContainer = ({
  coinsCollected,
  redeem,
  projectGoal,
  value,
  setValue,
  fetchRewards,
}) => {
  const { t } = useTranslation();
  return (
    <div className=" grid grid-cols-1 sm:grid-cols-2 w-full p-[20px] rounded-[8px] bg-[#2CD85D] text-white mb-[60px] mx-auto  max-w-[700px]">
      {/* left */}
      <div className=" w-full border-b border-r-0 sm:border-r sm:border-b-0 border-[#1D9C41] pb-[20px] sm:pb-0 pr-0 sm:pr-[20px]">
        <div className=" w-fit mx-auto md:w-full md:mx-0  flex gap-[6px]">
          <div className="">
            <img src={coins} alt=""></img>
          </div>
          <p className="font-semibold text-center md:text-start text-base md:text-xl md:leading-[31px]">
            {t("earnRewards.greenContainer.coinsCollected")}
          </p>
        </div>
        <div className=" w-full  mt-[19px]">
          <p className="font-bold text-[28px] text-center md:text-start md:text-3xl leading-[42px] md:leading-[63px]">
            {addCommas(coinsCollected)}
          </p>
        </div>
      </div>
      {/* right */}
      {redeem && (
        <div className=" w-full pt-[20px] pl-0 sm:pt-0 sm:pl-[20px]">
          <p className="font-semibold text-center md:text-start text-base md:text-xl md:leading-[31px]">
            {t("earnRewards.greenContainer.coinsNo")}
          </p>
          <div className="flex flex-col sm:flex-row gap-[16px] sm:gap-[6px] mt-[30px]">
            {/* input */}
            <input
              onChange={(e) => {
                setValue(e.target.value);
              }}
              className="w-full min-w-[100px] text-base text-[#1a1c1e] md:text-lg rounded-[8px] py-[12px] px-[24px]"
            ></input>
            {/* button */}
            <div
              onClick={() => {
                fetchRewards(value);
              }}
              className="bg-[#0B7026] flex justify-center cursor-pointer py-[12px] px-[28px] rounded-[8px]"
            >
              <p className="font-bold text-base ">
                {" "}
                {t("earnRewards.greenContainer.button")}
              </p>
            </div>
          </div>
        </div>
      )}

      {projectGoal && (
        <div className=" flex-row pt-[20px] pl-0 sm:pt-0 sm:pl-[20px] justify-end">
          <div className=" w-fit mx-auto md:w-full md:mx-0  flex gap-[6px]">
            <div className="">
              <img src={coins} alt=""></img>
            </div>
            <p className="font-semibold text-center md:text-start text-base md:text-xl md:leading-[31px]">
              {t("earnRewards.greenContainer.projectGoal")}
            </p>
          </div>
          <div className=" w-full  mt-[19px]">
            <p className="font-bold text-[28px] text-center md:text-start md:text-3xl leading-[42px] md:leading-[63px]">
              {addCommas(projectGoal)}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default GreenContainer;
