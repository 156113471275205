import React, { useState } from "react";
import curve from "../../assests/website/home-curve.svg";
import circle from "../../assests/website/circle.svg";
import curvy1 from "../../assests/website/home-back-svg.svg";
import curvy2 from "../../assests/website/search-curve-svg.svg";
import leaf from "../../assests/webapp/leaf.svg";
import curveWhite from "../../assests/website/curve-bottom-white.svg";

import WebsiteButton from "../../components/website/WebsiteButton";
import JoinOurGreen from "../../components/website/JoinOurGreen";
import ProjectComp from "../../components/website/ProjectComp";
import SearchComponent from "../../components/webapp/SearchComponent";
import SearchCompBtn from "../../components/website/SearchCompBtn";
import LeafHeader from "../../components/website/LeafHeader";
// globals
import updateTotalSearchCount from "../../globals/updateTotalSearchCount";
import updateTotalCoinsForGuestUser from "../../globals/updateTotalCoinsForGuestUser";
import updateUser from "../../globals/updateUser";
import LogoBig from "../../components/website/LogoBig";
import HamburgerMenu from "../../components/website/HamburgerMenu";
import SignAsComp from "../../components/website/SignAsComp";
import SwitchNavComp from "../../components/website/SwitchNavComp";
import { useNavigate } from "react-router-dom";
import HandleLanguageChange from "../../globals/handleLanguageChange";
import { useTranslation } from "react-i18next";

const Search = ({ theme }) => {
  // useStates
  const [openHam, setOpenHam] = useState(false);
  const [sign, setSign] = useState("");
  const [changeView, setChangeView] = useState(false);
  const navigate = useNavigate("");
  const [navbar, setNavbar] = useState(false);
  const [user, setUser] = useState();

  const [value, setValue] = useState("");
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));

  const gotoSearch = async () => {
    if (value === "" || value === null) {
      return;
    }
    localStorage.setItem("global_search", value);
    updateTotalCoinsForGuestUser(currentUser);
    await updateUser(currentUser);
    await updateTotalSearchCount();
    window.location.href = `/search?q=${value}`;
  };
  const { t } = useTranslation();
  // handle language change
  HandleLanguageChange();

  return (
    <div className="mt-[-183px] lg:mt-[-105px] h-screen">
      <div
        className={
          theme
            ? " relative bg-[#F7F7F7]  text-[#1A1C1E] h-[80%]"
            : " relative bg-[#1a1c1e] text-white     h-[80%] "
        }
      >
        {/* absolute */}
        <div className="hidden md:block absolute left-0 top-0 z-20">
          <img src={curvy2} alt=""></img>
        </div>

        {/* absolute */}
        <div className="hidden md:block absolute right-0 z-20 top-[50px]">
          <img src={curvy1} alt=""></img>
        </div>
        {/* section 1 */}
        <div className=" relative  max-w-[1200px] mx-[24px]  xl:mx-auto  flex justify-center items-center  h-full">
          {/* harmburger menu */}
          <div className="absolute top-[30px] right-0">
            {/* shown */}
            <HamburgerMenu theme={theme} setOpenHam={setOpenHam} />
            {/* hidden */}
            <div
              className={
                openHam
                  ? theme
                    ? "bg-[white] border border-[#DCE4E8] rounded-bl-[24px] max-w-[420px] w-[100%] fixed top-[25px] right-0 z-50 px-[16px] py-[33px]"
                    : " bg-[#2A2C2E] rounded-bl-[24px] max-w-[420px] w-[100%] fixed top-[25px] right-0 z-50 px-[16px] py-[33px]"
                  : "hidden"
              }
            >
              {changeView ? (
                <SignAsComp
                  sign={sign}
                  user={""}
                  setOpenHam={setOpenHam}
                  setChangeView={setChangeView}
                  theme={theme}
                />
              ) : (
                <SwitchNavComp
                  setOpenHam={setOpenHam}
                  setSign={setSign}
                  setChangeView={setChangeView}
                  theme={theme}
                />
              )}
              {/* switch nav component */}

              {/* signin as _ */}
            </div>
          </div>

          {/* search bar */}
          <div className="max-w-[1200px]  relative  w-full ">
            <div className="max-w-[40px] md:max-w-[80px] mx-auto mb-[50px] cursor-pointer">
              <LogoBig />
            </div>
            <div className="max-w-[843px] mx-auto w-full">
              <SearchCompBtn
                placeholder={t("home.header.search.placeholder")}
                onSubmit={gotoSearch}
                setValue={setValue}
                value={value}
                theme={theme}
              />
            </div>
          </div>
        </div>
      </div>
      {/* under curve */}
      <div className="mb-[150px] w-full relative ">
        {theme ? (
          <img className="w-full" src={curveWhite} alt=""></img>
        ) : (
          <img className="w-full" src={curve} alt=""></img>
        )}
        {/* absolute */}
        <div className="hidden md:block absolute right-[150px] bottom-[30px] 2xl:bottom-[50px] w-[103px]">
          <img className="w-full" src={circle} alt=""></img>
        </div>
      </div>
    </div>
  );
};

export default Search;
