import React, { useState } from "react";
import info from "../../assests/webapp/info-circle.svg";

const TextAreaComp = ({
  label,
  required,
  value,
  setValue,
  min,
  max,
  inf,
  error,
}) => {
  const [total, setTotal] = useState(0);
  const [hidden, setHidden] = useState(false);
  const [maxWords, setMaxWords] = useState();
  const [disable, setDisabled] = useState(false);
  // console.log("maxWords", maxWords);
  console.log("value", value);

  const handleInputChange = (event) => {
    const newInputValue = event.target.value.trim();
    const words = newInputValue.split(/\s+/);

    if (words.slice(0, max).length >= max) {
      setValue(words.slice(0, max).join(" "));

      event.preventDefault();
      event.stopPropagation();
    } else {
      setValue(newInputValue);
    }
  };

  const handlePaste = (event) => {
    console.log("handling paste inside function");
    const pastedText = event.clipboardData.getData("text");
    const pastedWords = pastedText.split(/\s+/);
    const currentWords = value.split(/\s+/);
    var totalWords = 0;
    if (value.length > 0) {
      totalWords = currentWords.length + pastedWords.length + value.length;
    } else {
      totalWords = currentWords.length + pastedWords.length;
    }

    if (totalWords > max) {
      event.preventDefault();
      event.stopPropagation();

      const remainingSpace = max - currentWords.length;
      const newText = pastedWords.slice(0, remainingSpace).join(" ");
      console.log("nextText length", newText.length);
      var subtraction = totalWords - 100;
      setTotal(totalWords - subtraction);

      // Update the input field with the new text
      // e.g., inputElement.value = newText;
      setValue(value + newText);
      // Make sure to update your input element accordingly
    }

    console.log("total-words", totalWords);
  };

  return (
    <div className="relative">
      <label className="flex gap-[2px] text-[#ACB5BB] font-medium text-[12px] block mb-[3px]">
        <p>{label}</p>
        {required && <p className="text-[#D80027]">*</p>}
        {error && (
          <div className="absolute top-[-30px] right-0 my-3 py-[6px] w-fit px-[12px] rounded-[4px] bg-[#FFEFEF] text-[#FF0319]">
            <p className="text-xs">missing field</p>
          </div>
        )}
        {inf && (
          <div
            onMouseLeave={() => {
              setHidden(false);
            }}
            className="cursor-pointer relative"
          >
            <div
              className=""
              onMouseOver={() => {
                setHidden(true);
              }}
            >
              <img src={info} alt=""></img>
            </div>

            {/* hidden */}
            <div
              className={
                hidden
                  ? "block absolute p-[8px] rounded-[8px] border border-[#ACB5BB] w-[300px] bg-[white] "
                  : "hidden"
              }
            >
              <p className="text-[black]">
                Explain your projects capacity to remove 1 tonne of C02 per
                annum
              </p>
            </div>
          </div>
        )}
      </label>
      <textarea
        value={value}
        onPaste={(e) => {
          console.log("handling paste");
          handlePaste(e);
        }}
        onChange={(e) => {
          handleInputChange(e);
          const words = e.target.value.split(/\s+/);
          const breakage = words?.slice(0, max);
          // Split by whitespace
          console.log("target.value", e.target.value.length);
          console.log("words", words.length);
          console.log("break", breakage.length);
          setTotal(breakage.length);
          setValue(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Backspace" || e.key === "Delete") {
            // Allow backspace and delete even if max limit is reached
            return;
          }
          if (value.split(/\s+/).length >= max) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        className="px-[24px] py-[18px] w-full border border-[#DCE4E8] text-[14px] rounded-[8px]"
      ></textarea>
      {max && (
        <div className="">
          <p className="text-[#ACB5BB] font-medium text-[12px]  text-right">
            {total}/100
          </p>
        </div>
      )}

      {min && (
        <div className="">
          <p className="text-[#ACB5BB] font-medium text-[12px]  text-right">
            maximum of 100 words ({total})
          </p>
        </div>
      )}
    </div>
  );
};

export default TextAreaComp;
