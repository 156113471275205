import React, { useState } from "react";
// assets

import curve from "../../assests/webapp/header-curve.svg";

// components
import LogoSmall from "../website/LogoSmall";
import NavComp from "./NavComp";
import SearchComponent from "./SearchComponent";
import CoinComp from "../website/CoinComp";
import BellNotification from "./BellNotification";
import { useSelector } from "react-redux";
import LogOutComp from "./LogOutComp";
import SwitchNavComp from "../website/SwitchNavComp";
import HamburgerMenu from "../website/HamburgerMenu";
import SwitchNavCompMobile from "./SwitchNavCompMobile";
import HandleLanguageChange from "../../globals/handleLanguageChange";
import { useTranslation } from "react-i18next";

// styles
// import "../../App.css";

const AppHeader = ({ theme }) => {
  const [navbar, setNavbar] = useState(false);
  const [active, setActive] = useState("dashboard");
  const [openHam, setOpenHam] = useState(false);
  const user = useSelector((state) => state.userAuth.data);
  const { t } = useTranslation();
  HandleLanguageChange();

  const changeHeaderNav = () => {
    // console.log(window.scrollY);
    if (window.scrollY >= 25) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeHeaderNav);

  return (
    <div
      className={
        theme
          ? " h-[338px] rounded-b-[48px] text-[#1a1c1e] bg-[#F4F4F7] relative"
          : " h-[338px] rounded-b-[48px] bg-[#1A1C1E] text-[white] relative"
      }
    >
      {/* curve */}
      <div className=" absolute right-0 ">
        <img src={curve} alt=""></img>
      </div>
      <div
        className={
          navbar
            ? theme
              ? " fixed w-full z-30 bg-[#F4F4F7] drop-shadow-md"
              : "fixed w-full z-30 bg-[#1a1c1e]"
            : " fixed w-full z-30"
        }
      >
        {/* margin maxwidth */}
        <div className=" max-w-[1200px] mx-[24px] xl:mx-auto py-[12px]  md:py-[21px]">
          {/* up */}
          <div className="hidden md:flex justify-between items-center">
            {/* left */}
            <div className=" flex gap-[64px] items-center">
              {/* left left (logo) */}
              <div>
                <LogoSmall />
              </div>
              {/* left right (navigation) */}
              <div className=" flex gap-[24px] items-center">
                {/* nav comp */}
                <NavComp
                  label={t("appHeader.dashboard")}
                  active={true}
                  link={"../dashboard/"}
                  theme={theme}
                />
                {/* nav comp */}
                <NavComp
                  label={t("appHeader.project")}
                  active={false}
                  link={"../dashboard/projects"}
                  theme={theme}
                />
                {/* nav comp */}
                {/* <NavComp
                  label={"Pap Rewards"}
                  active={false}
                  link={"dashboard/pap-rewards"}
                /> */}
                {/* nav comp */}
                <NavComp
                  label={t("appHeader.settings")}
                  active={false}
                  link={"../dashboard/settings"}
                  theme={theme}
                />
              </div>
            </div>
            {/* right */}
            <div className=" flex gap-[14px] items-center">
              {/* search */}
              {/* <div className="w-[343px]">
                <SearchComponent placeholder={"Search anything here"} />
              </div> */}
              <div className="flex gap-[14px] items-center ">
                {/* bell */}
                <BellNotification theme={theme} />
                {/* log out */}
                <LogOutComp theme={theme} />
              </div>
            </div>
          </div>

          {/* mobile responsiveness */}
          <div className="flex md:hidden justify-between items-center">
            {/* notification */}
            {/* <BellNotification theme={theme} /> */}

            {/* logo */}

            <div>
              <LogoSmall />
            </div>

            {/* hamburger menu */}
            <div>
              {/* shown */}
              <HamburgerMenu
                theme={theme}
                setOpenHam={setOpenHam}
                dash={true}
              />
              {/* hidden */}
              <div
                className={
                  openHam
                    ? theme
                      ? "bg-[white] border border-[#DCE4E8] rounded-bl-[24px] max-w-[420px] w-[100%] fixed top-[25px] right-0 z-50 px-[16px] py-[33px]"
                      : " bg-[#2A2C2E] rounded-bl-[24px] max-w-[420px] w-[100%] fixed top-[25px] right-0 z-50 px-[16px] py-[33px]"
                    : "hidden"
                }
              >
                <SwitchNavCompMobile setOpenHam={setOpenHam} theme={theme} />
              </div>
            </div>
          </div>

          {/* down */}
        </div>
      </div>
    </div>
  );
};

export default AppHeader;
