import React from "react";

// assests
import success from "../../assests/website/success.svg";

// components
import LoginComp from "./LoginComp";
import FormButtonComp from "./FormButtonComp";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../firebase/firebaseConfig";

import { onAuthStateChanged, updateEmail } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { useDispatch } from "react-redux";
// import { fetchUser } from "../../redux/UserSlice";

const CreateAccSuccessComp = ({ words, button, theme }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = () => {
    // Listen for changes in the user's authentication state
    // const unsubscribe = onAuthStateChanged(auth, (user) => {
    //   if (user) {
    //     // User is signed in
    //     console.log("app-user", user);
    //     // setUser(user);
    //     const docRef = doc(db, "users", user.id);
    //     updateDoc(docRef, {
    //       email_verified: true,
    //     });
    //   }
    // });

    navigate("/login");
  };
  return (
    <LoginComp theme={theme}>
      {/* success image */}
      <div className=" w-fit mx-auto">
        <img src={success} alt=""></img>
      </div>

      {/* words */}
      <div className=" mt-[16px] mb-[48px]">
        <p className="text-[32px] leading-[48px] font-bold text-center mb-[4px]">
          Success!
        </p>
        <p className="font-normal text-[#ACB5BB] text-center">{words}</p>
      </div>

      {/* button */}
      {button === "no" ? (
        <></>
      ) : (
        <FormButtonComp label={"Proceed to login"} onSubmit={handleSubmit} />
      )}
    </LoginComp>
  );
};

export default CreateAccSuccessComp;
