import React from "react";
import { isAndroid, isMacOs, isIOS } from "react-device-detect";
import { Link, useNavigate } from "react-router-dom";

const AddToPlayStore = () => {
  var userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
  // console.log("userAgent", userAgent);

  const getUrlParameter = (sParam) => {
    const sPageURL = window.location.search.substring(1); //get the url parameter
    const sURLVariables = sPageURL.split("&");
    var sParameterName, i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=");

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined
          ? null
          : decodeURIComponent(sParameterName[1]);
      }
    }

    return null;
  };

  const osType = () => {
    const systemOs = localStorage.getItem("os");
    if(systemOs !== null) {
      return systemOs.toLowerCase(); // android & ios
    }

    const getSystemOS = getUrlParameter("os");

    if(getSystemOS !== null) {
      localStorage.setItem("os", getSystemOS.toLowerCase());
      return getSystemOS.toLowerCase();
    }

    if(/android/i.test(userAgent)) {
      return "android";
    }

    if(/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "ios";
    }

    return "generic";
  }

  return (
    <Link
      to={"https://papearth.page.link/TG78"}
      className="lg:hidden bg-[#0B7026] cursor-pointer mb-[40px] py-[10px] max-w-[357px]   rounded-[8px]  flex items-center gap-[8px] justify-center mx-auto lg:mx-0"
    >
      {(osType() === "android") ? (
        <p className="text-[white]">Add to Android</p>
      ) : (osType() === "ios") ? (
        <p className="text-[white]">Add to iOS</p>
      ) : (
        <p className="text-[white]">Available on Android & iOS</p>
      )}
    </Link>
  );

  // return (
  //   <Link
  //     to={
  //       /android/i.test(userAgent)
  //         ? "https://play.google.com/store/apps/details?id=com.pap.earth"
  //         : /iPad|iPhone|iPod/.test(userAgent) &&
  //           !window.MSStream &&
  //           "https://apps.apple.com/ng/app/pap-earth/id1666003378"
  //     }
  //     className="lg:hidden bg-[#0B7026] cursor-pointer mb-[40px] py-[10px] max-w-[357px]   rounded-[8px]  flex items-center gap-[8px] justify-center mx-auto lg:mx-0"
  //   >
  //     {/android/i.test(userAgent) ? (
  //       <p className="text-[white]">Add to Android</p>
  //     ) : /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream ? (
  //       <p className="text-[white]">Add to iOS</p>
  //     ) : (
  //       <p className="text-[white]">Available on Android & iOS</p>
  //     )}
  //   </Link>
  // );
};

export default AddToPlayStore;
