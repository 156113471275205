import { createSlice } from "@reduxjs/toolkit";

export const languageSlice = createSlice({
  // name
  name: "language",

  // Initial State
  initialState: {
    langState: "en",
  },

  // reducers
  reducers: {
    updateLanguage: (state, action) => {
      state.langState = action.payload;
    },
  },
});

export const { updateLanguage } = languageSlice.actions;
export default languageSlice.reducer;
