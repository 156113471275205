import React, { useEffect } from "react";
// assests
import reward from "../../assests/webapp/reward.png";
import leaf from "../../assests/webapp/leaf.svg";
import bolt from "../../assests/webapp/bolt.svg";
import uber from "../../assests/webapp/uberEats.svg";
import coins from "../../assests/webapp/coins-small.svg";
import curvy1 from "../../assests/website/home-back-svg.svg";
import curveDown from "../../assests/website/home-curve.svg";
import curveWhite from "../../assests/website/curve-bottom-white.svg";
import loader from "../../assests/webapp/loader-svg.gif";
import RewardLinkComp from "../../components/webapp/RewardLinkComp";
import LeafHeader from "../../components/website/LeafHeader";
import Tags from "../../components/website/Tags";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
  Timestamp,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { useState } from "react";
import GreenContainer from "../../components/website/GreenContainer";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import HandleLanguageChange from "../../globals/handleLanguageChange";

const WebsitePapRewards = ({ theme }) => {
  const [tag, setTag] = useState("All");
  const [tags, setTags] = useState([]);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [fetchedRewards, setFetchedRewards] = useState(false);
  const { t } = useTranslation();

  // current user
  const [user, setUser] = useState();
  const [rewards, setRewards] = useState([]);
  const [filteredRewards, setFilteredRewards] = useState([]);
  const dispatch = useDispatch();
  // const user = useSelector((state) => state.user);
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const lang = JSON.parse(localStorage.getItem("language"));
  // const projects = useSelector((state) => state.project.data);

  const fetchUser = async () => {
    // console.log("enter fetchuser");
    if (currentUser) {
      const docRef = doc(db, "users", currentUser.uid);
      try {
        onSnapshot(docRef, (snapshot) => {
          const d = snapshot.data();
          setUser(snapshot.data());
          // console.log(user);
        });
      } catch (error) {
        // Handle the error or show an error message to the user
        console.log("Error fetching document:", error);
      }
    }
  };

  const fetchRewards = async (amount) => {
    setLoading(true);
    try {
      const colRef =
        value === ""
          ? query(
              collection(db, "rewards"),
              // where("redeem_value", "<=", amount === undefined ? Number(value): amount),
              // where("expires_at", ">=", Timestamp.fromDate(new Date()))
              orderBy("redeem_value", "desc")
            )
          : query(
              collection(db, "rewards"),
              where(
                "redeem_value",
                "<=",
                amount === undefined ? Number(value) : amount
              )
              // where("expires_at", ">=", Timestamp.fromDate(new Date()))
              // orderBy("timestamp", "desc")
            );
      const querySnapshot = await getDocs(colRef);
      // console.log(querySnapshot.docs);
      const t = [];
      const rwds = [];

      querySnapshot.forEach((doc) => {
        // push category in a temp array
        t.push(doc.data().category);
        // push data in a temp array
        rwds.push(doc.data());
        // console.log("t", t);
      });
      // create a set for the categories
      const s = new Set(t);
      setTags([...s]);
      setRewards(rwds);
      setLoading(false);
      setFetchedRewards(true);
    } catch (e) {
      // console.log("error", e);
      setLoading(false);
    }
  };

  // console.log("rewards", rewards);

  useEffect(() => {
    fetchUser();
  }, []);

  // useEffect to show rewards that are less than or equals to value
  useEffect(() => {
    console.log("check if it renders at first");

    const h = rewards.filter((val) => {
      const timestamp = Timestamp.fromMillis(val.expires_at["seconds"] * 1000);
      const now = Timestamp.fromDate(new Date());
      return now.toMillis() <= timestamp.toMillis();
    });

    setFilteredRewards(h);
    setTag("All");
  }, [rewards]);

  useEffect(() => {
    if (tag === "All") {
      setFilteredRewards(rewards);
      return;
    }
    const f = rewards.filter((val) => {
      return val.category === tag;
    });
    setFilteredRewards(f);
  }, [tag]);

  useEffect(() => {
    if ((user && !fetchedRewards) || (!user && !fetchedRewards)) {
      fetchRewards(); //user?.total_coins
    }
    // fetchRewards();
  }, [user]); //

  // handle language change
  HandleLanguageChange();

  // console.log("tags", tags);
  console.log("rewards", rewards);
  console.log("value", value);
  console.log("filtered Rewards", filteredRewards);

  return (
    <div
      className={
        theme
          ? "relative text-[#1a1c1e] relative"
          : "relative text-white relative"
      }
    >
      {/* absolute */}
      <div className="absolute hidden sm:block right-0 z-20 top-[-50px]">
        <img src={curvy1} alt=""></img>
      </div>
      {/* div */}
      <div
        className={
          theme
            ? "bg-[#F7F7F7] absolute top-0 w-full py-[300px] lg:py-[270px]"
            : " absolute top-0 w-full bg-[#1a1c1e] py-[300px] lg:py-[270px]"
        }
      ></div>
      {/* curve bottom */}
      <div className="w-full absolute  top-[600px] lg:top-[540px] mb-[150px]">
        {theme ? (
          <img className="w-full" src={curveWhite} alt=""></img>
        ) : (
          <img className="w-full" src={curveDown} alt=""></img>
        )}
      </div>

      <div className=" max-w-[1200px] mx-[24px]  xl:mx-auto  relative pt-[100px] mb-[140px]">
        {/* main */}
        <div className="grid grid-cols-1 lg:grid-cols-2  rounded-[16px]">
          {/* left */}
          <div className=" row-start-2  lg:row-start-1">
            {/* header */}
            <p className="text-[28px] text-center lg:text-start md:text-[60px] leading-[47px] md:leading-[74px] font-semibold mb-[20px]">
              {t("earnRewards.header")}
            </p>
            {/* paragraph1 */}
            <p className="text-sm md:text-lg leading-[28px] font-normal mb-[16px] text-center lg:text-start">
              {t("earnRewards.wordsOne")}
            </p>
            {/* paragraph 2 */}
            <p className="text-sm md:text-lg leading-[28px] font-normal mb-[33px] text-center lg:text-start">
              {t("earnRewards.wordsTwo")}
            </p>

            {/* green container */}
            <GreenContainer
              coinsCollected={user?.total_coins ?? 0}
              redeem={true}
              setValue={setValue}
              fetchRewards={fetchRewards}
              // projectGoal={"3,000,000"}
            />
            <div>
              {/* header */}
              <div className="w-fit border-b border-[#A2A6AA] pb-[5px] mb-[25px] ">
                <LeafHeader
                  label={t("earnRewards.excitingDiscounts")}
                  pos={true}
                />
              </div>
              {/* tags */}
              <div className="overflow-x-auto relative mb-[50px] ">
                <div className=" flex items-center gap-[16px]   min-w-[546px]">
                  <Tags label={"All"} active={tag === "All"} setTag={setTag} />
                  {tags?.map((data) =>
                    tag == data ? (
                      <Tags label={data} active={true} setTag={setTag} />
                    ) : (
                      <Tags label={data} active={false} setTag={setTag} />
                    )
                  )}
                </div>
              </div>
            </div>
            {/* exciting discounts */}
            {loading ? (
              <div className=" h-[300px] w-full flex justify-center items-center ">
                <div className=" w-[40px] ">
                  <img className="w-full" src={loader} alt=""></img>
                </div>
              </div>
            ) : rewards.length !== 0 && filteredRewards.length !== 0 ? (
              <div className="">
                {/* reward links */}
                <div className="flex flex-col gap-[32px]">
                  {filteredRewards.length != 0 ? (
                    filteredRewards?.map((data) => {
                      return (
                        <RewardLinkComp
                          id={data.id}
                          image={data.company_logo}
                          name={data.company_name}
                          theme={theme}
                          subHeader={data.subtitle}
                          description={
                            lang?.value === "en"
                              ? data.description_translated.en
                              : lang?.value === "fr"
                              ? data.description_translated.fr
                              : lang?.value === "ge"
                              ? data.description_translated.de
                              : lang?.value === "ch"
                              ? data.description_translated.zh
                              : data.description_translated.en
                          }
                          link={data.redeem_code}
                          coins={data.redeem_value}
                          subscribers_list={data.subscribers}
                          user={currentUser?.uid}
                          user_total_coins={user?.total_coins ?? 0}
                          user_data={user}
                        />
                      );
                    })
                  ) : (
                    <div className="h-[300px]">
                      <p>{t("earnRewards.noRewards")}</p>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="h-[300px]">
                <p>{t("earnRewards.inputRewards")}</p>
              </div>
            )}
          </div>
          {/* right */}
          <div className=" row-start-1 mb-[30px]">
            <div className=" w-fit mx-auto">
              <img src={reward} alt="" className=""></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsitePapRewards;
