import React from "react";
import DatePicker from "react-date-picker";
import cal from "../../assests/webapp/calendar-black.svg";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import moment from "moment";

// registerLocale("es", es);

const ComponentDate = ({ label, required, value, setValue, disabled }) => {
  return (
    <div className="">
      <label className="flex gap-[2px] text-[#ACB5BB] font-medium text-[12px] block mb-[3px]">
        <p>{label}</p>
        {required && <p className="text-[#D80027]">*</p>}
      </label>

      <div
        className={
          disabled
            ? "bg-[#f0f0f0] relative px-[24px] py-[18px] flex justify-between items-center w-full border border-[#DCE4E8] text-[14px] rounded-[8px]"
            : "relative px-[24px] py-[18px] flex justify-between items-center w-full border border-[#DCE4E8] text-[14px] rounded-[8px]"
        }
      >
        {/* date picker */}

        <DatePicker selected={value} onChange={setValue} disabled={disabled} />
        {value && (
          <p
            className={
              disabled
                ? "bg-[#f0f0f0] absolute top-[18px] left-[24px]  font-medium text-[14px]"
                : "bg-[white]  absolute top-[18px] left-[24px]  font-medium text-[14px]"
            }
          >
            {" "}
            {moment(value).format("DD-MM-YYYY")}
          </p>
        )}
        {/* image */}
        <div className="">
          <img src={cal} alt=""></img>
        </div>
      </div>
    </div>
  );
};

export default ComponentDate;
