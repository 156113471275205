import React, { useEffect, useState } from "react";
// assests
import google from "../../assests/website/google-icon.svg";
// components
import LoginComp from "./LoginComp";
import InputComp from "./InputComp";
import PasswordInputComp from "./PasswordInputComp";
import SignInWithComp from "./SignInWithComp";
import FormButtonComp from "./FormButtonComp";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser, userSignup } from "../../redux/UserAuthSlice";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendEmailVerification,
  signOut,
} from "firebase/auth";
import { auth, db } from "../../firebase/firebaseConfig";
import {
  collection,
  addDoc,
  setDoc,
  doc,
  FieldValue,
  serverTimestamp,
} from "firebase/firestore";
import { useTranslation } from "react-i18next";
import HandleLanguageChange from "../../globals/handleLanguageChange";
const CreateAccComp = ({ setSuccess, theme }) => {
  // dates
  const date = new Date();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  HandleLanguageChange();
  // const user = useSelector((state) => state.userAuth.data);
  console.log("current user", auth.currentUser);

  console.log("user_type", localStorage.getItem("user_type"));

  // states
  const [loading, setLoading] = useState(false);
  // form value
  const [error, setError] = useState();
  const [inputError, setInputError] = useState();
  const [passMismatch, setPassMissmatch] = useState();
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [email, setEmail] = useState("");
  // what is a better way to store password?
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");

  // to check if pass1 and two are equal
  useEffect(() => {
    if (password !== password1) {
      setError("password Password mismatch");
      setPassMissmatch(true);
    } else {
      setError();
      setPassMissmatch(false);
    }
  }, [password1]);

  const handleSubmit = async () => {
    setLoading(true);
    let user_type = localStorage.getItem("user_type");

    if (
      first.trim() === "" ||
      last.trim() === "" ||
      email.trim() === "" ||
      passMismatch
    ) {
      setInputError(" missing fields or password mismatch");
      window.scrollTo({ top: 0, behavior: "smooth" });
      setLoading(false);
    } else {
      try {
        await createUserWithEmailAndPassword(
          auth,
          email.toLowerCase(),
          password1
        )
          .then(async (userCredential) => {
            // Save the user's authentication state in local storage
            // localStorage.setItem(
            //   "currentUser",
            //   JSON.stringify(userCredential.user)
            // );

            // console.log(userCredential.user);

            // send verification email

            await sendEmailVerification(userCredential.user);

            //  function to add user
            const docRef = doc(db, "users", userCredential.user.uid);
            setDoc(docRef, {
              id: userCredential.user.uid,
              user_name: `${last} ${first}`,
              first_name: first,
              last_name: last,
              email: email.toLowerCase(),
              email_verified: false,
              user_type:
                user_type == null || user_type == "null"
                  ? "search_user"
                  : user_type,
              user_role_type: "owner",
              msgId: [],
              device_fingerprint: "",
              devices: [],
              role: "",
              position: "User",
              new_user: true,
              image: "",
              last_logged_in_date: "N/A",
              search_count: 0,
              total_coins: 0,
              country: "",
              city: "",
              province: "",
              d_o_b: null,
              created_date: date.toString(),
              modified_date: date.toString(),
              blocked: false,
              logout: false,
              timestamp: serverTimestamp(),
            })
              .then(() => {
                signOut(auth);
              })
              .catch((err) => {
                const user = auth.currentUser;
                user
                  .delete()
                  .then(() => {
                    console.log("user-delete-on-sign-in-success");
                    // User deleted.
                  })
                  .catch((err) => {
                    console.log("delete-user-error", err);
                    // An error occurred
                    // ...
                  });
              });
            setSuccess(true);
          })
          .catch((err) => {
            setError(err.message);
            console.log(err);
          });

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log("signup error", err);
        setError(err.message);
      }
    }
  };

  console.log("email", email);
  // console.log(user);
  console.log("password", password);
  return (
    <LoginComp theme={theme} header={t("createAcc.header")}>
      <div className="flex flex-col gap-[17px] ">
        <InputComp
          label={t("createAcc.firstname")}
          setValue={setFirst}
          required={true}
        />
        <InputComp
          label={t("createAcc.lastname")}
          setValue={setLast}
          required={true}
        />
        <InputComp
          label={t("createAcc.emailAddress")}
          setValue={setEmail}
          required={true}
        />
        <PasswordInputComp
          label={t("createAcc.password")}
          setValue={setPassword}
        />
        <PasswordInputComp
          label={t("createAcc.confirm")}
          setValue={setPassword1}
        />
        <FormButtonComp
          label={t("createAcc.button")}
          onSubmit={handleSubmit}
          loading={loading}
        />
        {/*sign in with google  */}
        {/* <SignInWithComp image={google} words={"Sign up with google"} /> */}
      </div>

      {error && (
        <div className="bg-[#ffb4b4] mt-[10px] py-[10px]">
          {" "}
          <p className="text-center">{error.substring(9)}</p>
        </div>
      )}
      {inputError && (
        <div className="bg-[#ffb4b4] mt-[10px] py-[10px]">
          {" "}
          <p className="text-center">{inputError}</p>
        </div>
      )}

      <div className=" mt-[32px]">
        <p className="text-center text-base font-semibold">
          {t("createAcc.alreadyHaveAnAccount")}?{"   "}
          <Link to={"../login"}>
            {" "}
            <i className="text-[#58BA59] not-italic cursor-pointer ml-1">
              {" "}
              {t("createAcc.login")}
            </i>
          </Link>
        </p>
      </div>
    </LoginComp>
  );
};

export default CreateAccComp;
