const updateTotalCoinsForGuestUser = (currentUser) => {
  if (currentUser !== null) {
    return false;
  }
  let currentCoins = localStorage.getItem("total_coins");
  if (currentCoins === null || currentCoins === "null") {
    currentCoins = "0";
  }
  localStorage.setItem("total_coins", `${Number(currentCoins) + 1}`);
  return true;
};

export default updateTotalCoinsForGuestUser;
