import React from "react";

import play from "../../assests/website/search-play.svg";
import { Link } from "react-router-dom";
import getMainDomain from "../../globals/getMainDomain";

const SearchVideoComp = ({ theme, image, item }) => {
  console.log("news", item);
  return (
    <Link to={item.link} className=" flex flex-col sm:flex-row gap-[20px]  ">
      {/* row 1 */}
      <div className="relative cursor-pointer w-fit">
        {/* image */}
        {item.pagemap.cse_image && (
          <div className="w-[195px] h-[110px]">
            <img
              className=" rounded-[8px] w-full h-full object-cover"
              src={item.pagemap.cse_image[0].src}
              alt=""
            ></img>
          </div>
        )}
        {/* play video and time */}
        <div className="absolute bottom-[10px] right-[10px] flex items-center gap-[5px] bg-[black] rounded-[8px] px-[5px] py-[2px]">
          {/* play video */}
          <div className="w-[12px] h-[12px]">
            <img className="w-full " src={play} alt=""></img>
          </div>
          {/* time */}
          <div className=" text-xs">{/* <p>12:30</p> */}</div>
        </div>
      </div>
      {/* row2 */}
      <div className=" w-full ">
        <div className="cursor-pointer w-full">
          <p className="text-xs w-full">{getMainDomain(item.link)}</p>
          <p
            className={
              theme
                ? "text-[#1a0dab] text-[20px] leading-[24px] font-medium mb-[5px] search-v-comp"
                : "text-[20px] leading-[24px] font-medium text-[#8ab4f8] mb-[5px] search-v-comp"
            }
          >
            <a href={item.link}>{item.title}</a>
          </p>
          {/* <p className="text-xl text-[#0055CA]  hover:underline">{item.title}</p> */}
        </div>
        <p className="hidden sm:block text-[14px] leading-[22px] font-normal">
          {item.snippet.substring(0, 70)}...
        </p>
        <p className="block sm:hidden text-[14px] leading-[22px] font-normal">
          {item.snippet.substring(0, 50)}...
        </p>
        {/* <p className="text-sm">{item.snippet}</p> */}
      </div>
    </Link>
  );
};

export default SearchVideoComp;
