import React, { useEffect } from "react";

// assests
import curvy1 from "../../assests/website/home-back-svg.svg";
import curvy2 from "../../assests/website/search-curve-svg.svg";

// imports
import america from "../../assests/webapp/america.svg";
import NewTabArea from "../../components/webapp/NewTabArea";
import FormButtonTwo from "../../components/webapp/FormButtonTwo";
import FormButtonComp from "../../components/webapp/FormButtonComp";
import SearchRegionComp from "../../components/webapp/SearchRegionComp";
import PreferenceNav from "../../components/webapp/PreferenceNav";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateLanguage } from "../../redux/LanguageSlice";
import { updateTheme } from "../../redux/ThemeSlice";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import moveToTop from "../../globals/moveToTop";
import HandleLanguageChange from "../../globals/handleLanguageChange";
import FormButtonTwoWeb from "../../components/website/FormButtonTwoWeb";

const Settings = ({ theme }) => {
  const { t } = useTranslation();
  // handle language change
  HandleLanguageChange();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = JSON.parse(localStorage.getItem("language"));
  const [language, setLanguage] = useState(lang ? lang : "");

  // const modeTheme = localStorage.getItem("mode");
  // const themeTypeLocal = localStorage.getItem("themeType");
  const [mode, setMode] = useState(localStorage.getItem("mode"));
  const [themeType, setThemeType] = useState(localStorage.getItem("themeType"));

  useEffect(() => {
    themeType === "light"
      ? setMode(t("settings.theme.light"))
      : themeType === "dark"
      ? setMode(t("settings.theme.dark"))
      : themeType === "default"
      ? setMode(t("settings.theme.default"))
      : setMode(t("settings.theme.default"));
  }, []);

  useEffect(() => {}, [mode]);

  console.log("mode", mode);
  console.log("themetype", themeType);
  // console.log("theme-local", themeTypeLocal);
  const [searchRegion, setSearchRegion] = useState("");
  const [safeSearch, setSafeSearch] = useState("");
  const [newTab, setNewTab] = useState(false);
  const [autoSuggestion, setAutoSuggestion] = useState(false);
  const [personalisedSearchResults, setPersonalisedSearchResults] =
    useState(false);
  const [notifications, setNotifications] = useState(false);
  const [allowCookies, setAllowCookies] = useState(false);
  const [blockThirdParty, setBlockThirdParty] = useState(false);
  const [blockAllCookies, setBlockAllCookies] = useState(false);
  const [change, setChange] = useState(false);

  useEffect(() => {
    setNewTab(localStorage.getItem("new_tab") === "true" ? true : false);
    setPersonalisedSearchResults(
      localStorage.getItem("personalised_search") === "true" ? true : false
    );
    setNotifications(
      localStorage.getItem("notifications") === "true" ? true : false
    );
    setAllowCookies(
      localStorage.getItem("allow_cookies") === "true" ? true : false
    );
    setBlockThirdParty(
      localStorage.getItem("block_third") === "true" ? true : false
    );
    setBlockAllCookies(
      localStorage.getItem("block_all_cookies") === "true" ? true : false
    );
  }, []);

  const handleSubmit = () => {
    console.log("change", change);
    // set theme
    localStorage.setItem(
      "theme",
      mode === t("settings.theme.light") ? true : false
    );
    localStorage.setItem("mode", mode);
    // set theme type
    localStorage.setItem("themeType", themeType);
    // set language
    localStorage.setItem("language", JSON.stringify(language));
    change && localStorage.setItem("languageChange", true);
    // set region
    localStorage.setItem("region", searchRegion);
    // set newTab
    localStorage.setItem("new_tab", newTab);
    // set autoSuggestion
    localStorage.setItem("auto_suggestion", autoSuggestion);
    // set personalised search
    localStorage.setItem("personalised_search", personalisedSearchResults);
    // set notification
    localStorage.setItem("notifications", notifications);
    // set allowCokkies
    localStorage.setItem("allow_cookies", allowCookies);
    // set block third party
    localStorage.setItem("block_third", blockThirdParty);
    // set block all cookies
    localStorage.setItem("block_all_cookies", blockAllCookies);

    // alert("submited");

    navigate("../");
    window.location.reload();
    toast.success("Settings saved successfully.");
    // moveToTop();
  };

  // change languageChange as soon as lang changes

  // console.log("lang", lang);
  // console.log("language", language);

  return (
    <div
      className={
        theme
          ? "border border-[transparent] relative mb-[150px] text-[#1A1C1E]"
          : "border border-[transparent] relative text-[white] mb-[150px]"
      }
    >
      {/* absolute */}
      <div className="absolute hidden sm:block left-0 top-[500px] z-20">
        <img src={curvy2} alt=""></img>
      </div>

      {/* absolute */}
      <div className="absolute hidden sm:block right-0 z-20 top-[-50px]">
        <img src={curvy1} alt=""></img>
      </div>
      <div className=" max-w-[1200px] mx-[24px]  xl:mx-auto  relative">
        <p className="mt-[80px] md:mt-[100px] mb-[42px] text-[28px] md:text-[52px] font-bold leading-[24px] md:leading-[67px]">
          {t("settings.header")}
        </p>
        {/* heading  */}
        <div className=" max-w-[800px] flex flex-col md:flex-row md:items-center justify-between mb-[35px] gap-y-[2px]">
          {/* left */}
          <div>
            <p className=" text-[16px] md:text-[24px] leading-[27px] font-medium">
              {t("settings.theme.header")}
            </p>
          </div>

          {/* right */}
          <div className="overflow-x-auto">
            <div className=" flex justify-between gap-[24px]">
              {mode === t("settings.theme.default") ? (
                <PreferenceNav
                  label={t("settings.theme.default")}
                  active={true}
                  setMode={setMode}
                />
              ) : (
                <PreferenceNav
                  label={t("settings.theme.default")}
                  setMode={setMode}
                  setThemeType={setThemeType}
                />
              )}

              {mode === t("settings.theme.light") ? (
                <PreferenceNav
                  label={t("settings.theme.light")}
                  active={true}
                  setMode={setMode}
                />
              ) : (
                <PreferenceNav
                  label={t("settings.theme.light")}
                  active={false}
                  setMode={setMode}
                  setThemeType={setThemeType}
                />
              )}

              {mode === t("settings.theme.dark") ? (
                <PreferenceNav
                  label={t("settings.theme.dark")}
                  active={true}
                  setMode={setMode}
                />
              ) : (
                <PreferenceNav
                  label={t("settings.theme.dark")}
                  active={false}
                  setMode={setMode}
                  setThemeType={setThemeType}
                />
              )}
            </div>
          </div>
        </div>

        {/* line area */}
        <div
          className={
            theme ? "border-b border-[#E1E1E1]" : "border-b border-[#383838]"
          }
        ></div>

        {/* search region area */}
        <div className=" max-w-[800px] flex flex-col gap-[24px] my-[32px]">
          {/* comp1 */}
          <SearchRegionComp
            header={t("settings.searchRegion.header")}
            image={america}
            view={true}
            label={"United States (English)"}
            theme={theme}
            setValue={setSearchRegion}
            list={[
              {
                image: america,
                name: "United States (English)",
              },
            ]}
          />
          <SearchRegionComp
            header={t("settings.websiteLang.header")}
            label={
              language.value === "en"
                ? t("settings.websiteLang.options.english")
                : language.value === "fr"
                ? t("settings.websiteLang.options.french")
                : language.value === "ge"
                ? t("settings.websiteLang.options.german")
                : language.value === "ch"
                ? t("settings.websiteLang.options.chinese")
                : t("settings.websiteLang.options.english")
            }
            theme={theme}
            setValue={setLanguage}
            setChange={setChange}
            list={[
              {
                name: t("settings.websiteLang.options.english"),
                value: "en",
              },
              {
                name: t("settings.websiteLang.options.french"),
                value: "fr",
              },
              {
                name: t("settings.websiteLang.options.german"),
                value: "ge",
              },
              {
                name: t("settings.websiteLang.options.chinese"),
                value: "ch",
              },
            ]}
          />
          <SearchRegionComp
            header={t("settings.safeSearch.header")}
            label={"Strict"}
            theme={theme}
            setValue={setSafeSearch}
          />
        </div>

        {/* line area */}
        <div
          className={
            theme ? "border-b border-[#E1E1E1]" : "border-b border-[#383838]"
          }
        ></div>

        {/* new tab area */}
        <div className=" max-w-[800px]  my-[32px]   flex flex-col gap-[24px]">
          {/* comp1 */}
          <NewTabArea
            header={t("settings.newTab")}
            value={newTab}
            setValue={setNewTab}
          />
          {/* comp1 */}
          {/* <NewTabArea
            header={t("settings.autoSuggestion")}
            value={autoSuggestion}
            setValue={setAutoSuggestion}
          /> */}
          {/* comp1 */}
          <NewTabArea
            header={t("settings.personalised")}
            value={personalisedSearchResults}
            setValue={setPersonalisedSearchResults}
          />
          {/* comp1 */}
          <NewTabArea
            header={t("settings.notifications")}
            value={notifications}
            setValue={setNotifications}
          />
        </div>

        {/* line area */}
        <div
          className={
            theme ? "border-b border-[#E1E1E1]" : "border-b border-[#383838]"
          }
        ></div>

        {/* allow cookies */}
        <div className=" max-w-[800px] my-[32px]   flex flex-col gap-[24px]">
          {/* comp1 */}
          <NewTabArea
            header={t("settings.allowCookies")}
            value={allowCookies}
            setValue={setAllowCookies}
          />
          {/* comp1 */}
          <NewTabArea
            header={t("settings.blockThird")}
            value={blockThirdParty}
            setValue={setBlockThirdParty}
          />
          {/* comp1 */}
          <NewTabArea
            header={t("settings.blockAll")}
            value={blockAllCookies}
            setValue={setBlockAllCookies}
          />
        </div>

        {/* submit button area */}
        <div className=" grid grid-cols-1 sm:grid-cols-2 sm:grid-cols-4 gap-[24px] max-w-[800px] mt-[50px]  md:mt-[16px]">
          {/* cancel */}
          <FormButtonTwoWeb
            label={t("settings.cancelBtn")}
            start={true}
            onSubmit={handleSubmit}
            theme={theme}
          />
          {/* yes */}
          <FormButtonComp
            label={t("settings.saveBtn")}
            start={true}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default Settings;
