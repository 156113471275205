import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  setDoc,
  increment,
  query,
  updateDoc,
  where,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { deviceDetect } from "react-device-detect";
import curvy1 from "../../assests/website/home-back-svg.svg";
import curvy2 from "../../assests/website/search-curve-svg.svg";
import { timezones } from "../../globals/timezones";

import DetailsNavComp from "../../components/webapp/DetailsNavComp";
import AllResults from "../../components/website/AllResults";
import LeafHeader from "../../components/website/LeafHeader";
import WebsiteButton from "../../components/website/WebsiteButton";
import NoResults from "../../components/website/NoResults";
import AllImages from "../../components/website/AllImages";
import AllVideos from "../../components/website/AllVideos";
import AllNews from "../../components/website/AllNews";
import DetailsMapNavComp from "../../components/website/DetailsMapNavComp";
import { useTranslation } from "react-i18next";
import loader from "../../assests/webapp/loader-svg.gif";
import { useParams, useSearchParams } from "react-router-dom";
import Search from "./Search";
import HandleLanguageChange from "../../globals/handleLanguageChange";
import { geminiAiSearch } from "../../globals/geminiAiSearch";

const SearchResult = ({ theme }) => {
  const [detailsNav, setDetailsNav] = useState("All");
  const [maps, setMaps] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [nextPageIndex, setNextPageIndex] = useState(1);
  const [previousPageIndex, setPreviousPageIndex] = useState(1);
  const [nextSearchPage, setNextSearchPage] = useState(1);
  const [searchInformation, setSearchInformation] = useState();
  const [searchItems, setSearchItems] = useState([]);
  const [searchImageItems, setSearchImageItems] = useState([]);
  const [searchNewsItems, setSearchNewsItems] = useState([]);
  const [searchVideosItems, setSearchVideosItems] = useState([]);
  const [geminiAIResponse, setGeminiAIResponse] = useState();
  const [adsItems, setAdsItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  // console.log("params", searchParams.get("q"));
  // console.log("params2", params);
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  let globalSearch = localStorage.getItem("global_search");

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };

  const getUrlParameter = (sParam) => {
    const sPageURL = window.location.search.substring(1); //get the url parameter

    const sURLVariables = sPageURL?.split("&");
    var sParameterName, i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=");

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined
          ? null
          : decodeURIComponent(sParameterName[1]);
      }
    }
    // console.log("pageUrl", sPageURL);
    // console.log("search value", decodeURIComponent(sParameterName[1]));
  };

  const searchCustomGoogle = async (start, type, extra) => {
    setLoading(true);
    try {
      const searchQuery = getUrlParameter("q");
      if (searchQuery === null || searchQuery === undefined) {
        return;
      } //old key = AIzaSyC3zd3Oel8vN_Le_Ppo2wj0RjWfiF3thjo and old cx = 529c3c5d3a6a24514

      let locale = "en";

      const getLang = localStorage.getItem("language");
      if (getLang) {
        const gl = JSON.parse(getLang);
        locale = gl.value.toLowerCase();
      }

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      let URL = `https://customsearch.googleapis.com/customsearch/v1?cx=06cafd64864bf4234&hl=${locale}&q=${encodeURIComponent(
        searchQuery
      )}&key=AIzaSyC3zd3Oel8vN_Le_Ppo2wj0RjWfiF3thjo`;

      if (timezone !== "" || timezone) {
        const _country = timezones[timezone].c[0];
        URL += `&gl=${_country?.toLowerCase()}`;
      }

      if (start !== undefined) {
        URL += `&start=${start}`;
      }

      if (type !== "all") {
        URL += `&searchType=${type}`;
      }

      if (extra !== undefined) {
        URL += `&orTerms=${extra}`;
      }

      const resp = await fetch(URL);

      const data = await resp.json();

      if (data.queries["previousPage"] !== undefined) {
        const prevP = data?.queries.previousPage[0];
        setPreviousPageIndex(prevP.startIndex);
      }

      if (data.queries.nextPage !== undefined) {
        const nextP = data?.queries.nextPage[0];
        setNextPageIndex(nextP.startIndex);
      }

      if (extra !== undefined && extra === "news") {
        setSearchNewsItems(data?.items);
      }

      if (extra !== undefined && extra === "youtube") {
        const d = data?.items;
        const videos = d?.filter((val) => {
          return val.displayLink.includes("youtube");
        });
        setSearchVideosItems(videos);
      }

      if (type === "all" && extra === undefined) {
        setSearchInformation(data?.searchInformation);
        setSearchItems(data?.items);
      }

      if (type === "image") {
        setSearchImageItems(data?.items);
      }

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (e) {
      setError(e);
      console.log(e.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    // setLoading(true);
    fetchSearchAds();
    searchCustomGoogle(1, "all");
    searchCustomGoogle(1, "image");
    searchCustomGoogle(1, "all", "news");
    searchCustomGoogle(1, "all", "youtube");

    async function fetchData() {
      // You can await here
      setGeminiAIResponse(await geminiAiSearch(getUrlParameter("q")));
      // ...
    }
    fetchData();
    // console.log("..fetching");

    // setLoading(false);
  }, []);

  useEffect(() => {
    if (!loading) {
      if (
        searchItems &&
        searchImageItems &&
        searchNewsItems &&
        searchVideosItems
      ) {
        storeSearchHistory();
      }
    }
  }, [loading]);

  const storeSearchHistory = async () => {
    const searchQuery = getUrlParameter("q");
    if (searchQuery === null || searchQuery === undefined) {
      return;
    }
    const today = new Date();
    const colRef = collection(db, "search-result-data");
    const id = doc(colRef).id;
    const docRef = doc(db, "search-result-data", id);
    await setDoc(docRef, {
      id: id,
      device: deviceDetect(),
      query: searchQuery.replace(/\+/g, " ").toLowerCase(),
      uid:
        currentUser !== null
          ? currentUser.uid
          : getUrlParameter("uid") === null ||
            getUrlParameter("uid") === undefined
          ? ""
          : getUrlParameter("uid"),
      day: today.getUTCDate(),
      month: today.getUTCMonth() + 1,
      year: today.getUTCFullYear(),
      hour: today.getUTCHours(),
      min: today.getUTCMinutes(),
      time: today.getTime(),
      timestamp: serverTimestamp(),
      result_all: searchItems,
      result_images: searchImageItems,
      result_news: searchNewsItems,
      result_videos: searchVideosItems,
    });
  };

  const fetchSearchAds = async () => {
    try {
      const searchQuery = getUrlParameter("q");
      if (searchQuery === null || searchQuery === undefined) {
        return;
      }

      // let URL = http://127.0.0.1:5001/people-and-planet/us-west1/fetchgoogleads `https://cse.google.com/cse_v2/ads?adsafe=high&pcsa=false&cx=06cafd64864bf4234&fexp=20606%2C17301293%2C17301318%2C17301319%2C17301365%2C17301374%2C17301375%2C17301266&client=hosted-page-client&q=${encodeURIComponent(searchQuery)}&r=m&sct=ID%3Df74612650abd6d7f%3AT%3D1693639480%3ART%3D1693639480%3AS%3DALNI_MbxKgbGvUu2JiK8aNC7ZO8R4YmzzA&sc_status=6&hl=en&type=0&oe=UTF-8&ie=UTF-8&format=p4&ad=p4&nocache=1221693844110999&num=0&output=uds_ads_only&source=gcsc&v=3&bsl=10&pac=0&u_his=2&u_tz=60&dt=${new Date(1693846373590).getTime()}&u_w=1440&u_h=900&biw=1440&bih=150&psw=1424&psh=130&frm=0&cl=560704565&uio=-&jsid=csa&nfp=1&jsv=560704565&rurl=${encodeURI(window.location.href)}&referer=${encodeURI("https://pap.earch")}&adbw=master-1%3A1396`

      let URL = `https://us-west1-people-and-planet.cloudfunctions.net/fetchgoogleads?q=${searchQuery}`;
      // let URL = `http://127.0.0.1:5001/people-and-planet/us-west1/fetchgoogleads?q=${searchQuery}`;

      const resp = await fetch(URL); // {headers: {"Accept": "*/*", "Access-Control-Allow-Origin": "http://localhost:3000"}, method: "GET"}

      if (resp?.status !== 200) {
        return;
      }

      const html = await resp?.text();

      var doc = new DOMParser().parseFromString(html, "text/html");

      var ads = doc.querySelectorAll(".clicktrackedAd_js");

      const mAds = [];

      ads?.forEach((ad) => {
        let title = "",
          desc = "",
          visualLink = "",
          link = "";
        const styleable_title = ad.getElementsByClassName("styleable-title");
        const styleable_visurl = ad.getElementsByClassName("styleable-visurl");
        const styleable_description = ad.getElementsByClassName(
          "styleable-description"
        );

        if (styleable_title.item(0) !== null) {
          const anchor = styleable_title.item(0);
          link = anchor.getAttribute("href");
          title = anchor.textContent;
        }

        if (styleable_visurl.item(0) !== null) {
          const anchor2 = styleable_visurl.item(0);
          visualLink = anchor2.textContent;
        }

        if (styleable_description.item(0) !== null) {
          const anchor3 = styleable_description.item(0);
          desc = anchor3.textContent;
        }

        if (title !== "" && link !== "") {
          mAds.push({
            title: title,
            link: link,
            snippet: desc,
            displayUrl: visualLink,
          });
        }
      });

      setAdsItems(mAds);
    } catch (e) {
      // console.log("");
    }
  };

  useEffect(() => {
    if (adsItems.length === 0) {
      return;
    }
    const existingScript = document.getElementById("pap-result");
    const papChecker = document.getElementById("pap-checker2");

    const script = document.createElement("script");

    script.type = "text/javascript";
    script.src = "https://cse.google.com/adsense/search/async-ads.js?pac=0";
    script.nonce = "rPC1uKSWe8U-kRBLByxeCA";

    if (!papChecker) {
      existingScript?.appendChild(script);

      const div = document.createElement("div");
      div.id = "pap-checker2";
      existingScript?.appendChild(div);
    }
    return () => {
      // clean up the script when the component in unmounted
      existingScript?.removeChild(script);
    };
  }, [adsItems]);

  // console.log("add-items", adsItems);

  // useEffect(() => {
  //   const existingScript = document.getElementById("pap-result");
  //   const papChecker = document.getElementById("pap-checker");

  //   const script = document.createElement("script");

  //   script.async = true;
  //   script.src = "https://cse.google.com/cse.js?cx=529c3c5d3a6a24514";

  //   if (!papChecker) {
  //     existingScript.appendChild(script);
  //     // const divSearch = document.createElement("div");
  //     // divSearch.classList = "gcse-searchresults-only border border-[transparent] relative";
  //     // existingScript.appendChild(divSearch);

  //     const div = document.createElement("div");
  //     div.id = "pap-checker";
  //     existingScript.appendChild(div);
  //   }
  //   return () => {
  //     // clean up the script when the component in unmounted
  //     existingScript.removeChild(script);
  //   };
  // }, []);

  // handle language change
  HandleLanguageChange();
  // console.log("loading", loading);
  // console.log("searchitems", searchItems);

  return (
    <div className="mt-[-2px] text-[white] ">
      {/* header */}
      {searchParams.get("q") !== null ? (
        <div className="">
          {/* nav */}
          <div
            className={
              theme
                ? "border-b border-[#E1E1E1] overflow-x-auto "
                : "border-b border-[#383838] overflow-x-auto"
            }
          >
            <div className="">
              {/* header nav */}
              <div className=" flex max-w-[1200px] mx-[24px] gap-[24px]  xl:mx-auto  relative">
                {detailsNav === "All" ? (
                  <DetailsNavComp
                    label={t("search.all")}
                    setMaps={setMaps}
                    maps={maps}
                    setDetailsNav={setDetailsNav}
                    detailsNav={detailsNav}
                    isActive={true}
                    color={"green"}
                    SearchResult={false}
                  />
                ) : (
                  <DetailsNavComp
                    label={t("search.all")}
                    setMaps={setMaps}
                    maps={maps}
                    setDetailsNav={setDetailsNav}
                    detailsNav={detailsNav}
                    SearchResult={true}
                  />
                )}
                {detailsNav === "Images" ? (
                  <DetailsNavComp
                    setMaps={setMaps}
                    maps={maps}
                    label={t("search.images")}
                    setDetailsNav={setDetailsNav}
                    isActive={true}
                    detailsNav={detailsNav}
                    color={"green"}
                    SearchResult={true}
                  />
                ) : (
                  <DetailsNavComp
                    label={t("search.images")}
                    setMaps={setMaps}
                    maps={maps}
                    setDetailsNav={setDetailsNav}
                    detailsNav={detailsNav}
                    SearchResult={true}
                  />
                )}
                {detailsNav === "Videos" ? (
                  <DetailsNavComp
                    setMaps={setMaps}
                    maps={maps}
                    label={t("search.videos")}
                    setDetailsNav={setDetailsNav}
                    detailsNav={detailsNav}
                    isActive={true}
                    color={"green"}
                    SearchResult={false}
                  />
                ) : (
                  <DetailsNavComp
                    setMaps={setMaps}
                    maps={maps}
                    label={t("search.videos")}
                    setDetailsNav={setDetailsNav}
                    detailsNav={detailsNav}
                    SearchResult={true}
                  />
                )}
                {/* {detailsNav === "Shopping" ? (
              <DetailsNavComp
                setMaps={setMaps}
                maps={maps}
                label={t('search.shopping')}
                setDetailsNav={setDetailsNav}
                detailsNav={detailsNav}
                isActive={true}
                color={"green"}
                SearchResult={false}
              />
            ) : (
              <DetailsNavComp
                setMaps={setMaps}
                maps={maps}
                label={t('search.shopping')}
                setDetailsNav={setDetailsNav}
                detailsNav={detailsNav}
                SearchResult={true}
              />
            )} */}
                {detailsNav === "News" ? (
                  <DetailsNavComp
                    setMaps={setMaps}
                    maps={maps}
                    label={t("search.news")}
                    setDetailsNav={setDetailsNav}
                    detailsNav={detailsNav}
                    isActive={true}
                    color={"green"}
                    SearchResult={false}
                  />
                ) : (
                  <DetailsNavComp
                    setMaps={setMaps}
                    maps={maps}
                    label={t("search.news")}
                    setDetailsNav={setDetailsNav}
                    detailsNav={detailsNav}
                    SearchResult={true}
                  />
                )}
                {/* {detailsNav === "Books" ? (
              <DetailsNavComp
                setMaps={setMaps}
                maps={maps}
                label={t('search.books')}
                setDetailsNav={setDetailsNav}
                detailsNav={detailsNav}
                isActive={true}
                color={"green"}
                SearchResult={false}
              />
            ) : (
              <DetailsNavComp
                setMaps={setMaps}
                maps={maps}
                label={t('search.books')}
                setDetailsNav={setDetailsNav}
                detailsNav={detailsNav}
                SearchResult={true}
              />
            )} */}
                {detailsNav === "Maps" ? (
                  <DetailsMapNavComp
                    setMaps={setMaps}
                    maps={maps}
                    label={t("search.maps")}
                    // setDetailsNav={setDetailsNav}
                    detailsNav={detailsNav}
                    isActive={true}
                    color={"green"}
                    SearchResult={false}
                    query={getUrlParameter("q")}
                  />
                ) : (
                  <DetailsMapNavComp
                    setMaps={setMaps}
                    maps={maps}
                    label={t("search.maps")}
                    detailsNav={detailsNav}
                    // setDetailsNav={setDetailsNav}
                    SearchResult={true}
                    query={getUrlParameter("q")}
                  />
                )}
                {/* {detailsNav === "More" ? (
              <DetailsNavComp
                label={t('search.more')}
                setDetailsNav={setDetailsNav}
                isActive={true}
                color={"green"}
                SearchResult={false}
              />
            ) : (
              <DetailsNavComp
                label={t('search.more')}
                setDetailsNav={setDetailsNav}
                SearchResult={true}
              />
            )} */}
              </div>
            </div>
          </div>

          <div className=" relative">
            {/* absolute */}
            <div className="absolute hidden sm:block left-0 top-0 z-20">
              {/* <img src={curvy2} alt=""></img> */}
            </div>

            {/* absolute */}
            <div className="absolute hidden sm:block right-0 z-20 top-[50px]">
              <img src={curvy1} alt=""></img>
            </div>
            {/* main */}
            {loading ? (
              <div className=" h-[300px] w-full flex justify-center items-center ">
                <div className=" w-[40px] ">
                  <img className="w-full" src={loader} alt=""></img>
                </div>
              </div>
            ) : (
              <div className="max-w-[1200px] mx-[24px]  xl:mx-auto  relative mt-[30px]">
                <div>
                  {searchInformation && (
                    <p className="text-[14px] font-normal leading-[44px] text-[#70757A]">
                      About {searchInformation?.formattedTotalResults} results (
                      {searchInformation?.formattedSearchTime} seconds){" "}
                    </p>
                  )}
                </div>

                {detailsNav == "All" && searchItems?.length > 0 ? (
                  <AllResults
                    ads={adsItems}
                    theme={theme}
                    items={searchItems}
                    images={searchImageItems}
                    currentIndex={nextSearchPage}
                    geminiAIResponse={geminiAIResponse}
                    nextSearch={(next) => {
                      // console.log(nextPageIndex)
                      moveToTop();
                      let startIndex = 1;
                      if (next === 1) {
                        startIndex = 1;
                      }
                      if (next === 2) {
                        startIndex = 11;
                      }
                      if (next === 3) {
                        startIndex = 21;
                      }
                      if (next === 4) {
                        startIndex = 31;
                      } else {
                        startIndex = 41;
                      }
                      setNextSearchPage(next);
                      searchCustomGoogle(startIndex, "all");
                      searchCustomGoogle(startIndex, "image");
                      searchCustomGoogle(startIndex, "all", "news");
                      searchCustomGoogle(startIndex, "all", "youtube");
                    }}
                    changeTab={(tabName) => {
                      setDetailsNav(tabName);
                      moveToTop();
                    }}
                  />
                ) : detailsNav == "Images" && searchImageItems?.length > 0 ? (
                  <AllImages theme={theme} images={searchImageItems} />
                ) : detailsNav == "Videos" && searchVideosItems?.length > 0 ? (
                  <AllVideos theme={theme} items={searchVideosItems} />
                ) : detailsNav == "News" && searchNewsItems?.length > 0 ? (
                  <AllNews theme={theme} items={searchNewsItems} />
                ) : (
                  // <div className=" h-[300px] w-full flex justify-center items-center ">
                  //   <div className=" w-[40px] ">
                  //     <img className="w-full" src={loader} alt=""></img>
                  //   </div>
                  // </div>
                  // ) : (
                  <NoResults theme={theme} error={error} />
                )}

                {/* no result  */}
                {/* <NoResults /> */}
                <div id="pap-result"></div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <Search theme={theme} />
        </div>
      )}
    </div>
  ); // 1 , 1
}; // 1 - 1, 2 - 11, 3 - 21, 4 - 31, 5 - 41

export default SearchResult;
