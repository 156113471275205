import React, { useEffect, useRef, useState } from "react";
import ResultComp from "./ResultComp";
import AdsResultComp from "./AdsResultComp";
import image from "../../assests/website/cleanenergy/1.jpg";
import WebsiteButton from "./WebsiteButton";
import WebsiteButtonTwo from "./WebsiteButtonTwo";
import play from "../../assests/website/search-play.svg";
import searchWhite from "../../assests/website/header-search-black.svg";
import CircleArrow from "./CircleArrow";
import SearchNavigation from "./SearchNavigation";
import AllResultImageComp from "./AllResultImageComp";
import { TypeAnimation } from "react-type-animation";
import Typewriter from "./TypeWriter";

const AllResults = ({
  ads,
  theme,
  items,
  images,
  nextSearch,
  currentIndex,
  changeTab,
  geminiAIResponse,
}) => {
  const ref = useRef();
  const [refineAIResponse, setRefinedAIResponse] = useState();

  // console.log("geminiAI", geminiAIResponse);
  // const [left, setLeft]= useState(0)
  // get bounding client of ref

  var rect = ref?.current?.getBoundingClientRect();
  // console.log("rect", rect);
  var left = 0;
  // handle move right onclick
  const handleScroll = (direction) => {
    // console.log("left", left);
    if (direction) {
      // handle move right
      if (left < 600) {
        ref?.current?.scrollTo({
          left: (left += 200),
          behavior: "smooth",
        });
      }
    } else {
      // handle move left onclick
      if (left > 0) {
        ref?.current?.scrollTo({
          left: (left -= 200),
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    const news = geminiAIResponse; //?.substring(7);
    // const newss = news?.slice(0, -3);
    setRefinedAIResponse(news);
    // console.log("...running");
  }, [geminiAIResponse]);

  useEffect(() => {}, []);

  // console.log("refined", refineAIResponse);

  return (
    <div
      className={
        theme
          ? "text-[#1A1C1E] grid grid-cols-12"
          : " text-[white] grid grid-cols-12"
      }
    >
      {/* column 1 */}
      <div className="flex flex-col gap-[30px] col-span-12 md:col-span-9 lg:col-span-7">
        {/* section 1 */}
        <div className="">
          <div className="">
            {/* get result time */}

            {/* result comp */}
            <div className="flex flex-col gap-[30px]">
              {/* <div id="papadsblock">
                <iframe src="https://cse.google.com/cse_v2/ads?adsafe=high&pcsa=false&cx=529c3c5d3a6a24514&fexp=20606%2C17301293%2C17301318%2C17301319%2C17301365%2C17301374%2C17301375%2C17301266&client=hosted-page-client&q=watches%20in%20lekki&r=m&sct=ID%3Df74612650abd6d7f%3AT%3D1693639480%3ART%3D1693639480%3AS%3DALNI_MbxKgbGvUu2JiK8aNC7ZO8R4YmzzA&sc_status=6&hl=en&type=0&oe=UTF-8&ie=UTF-8&format=p4&ad=p4&nocache=1221693844110999&num=0&output=uds_ads_only&source=gcsc&v=3&bsl=10&pac=0&u_his=2&u_tz=60&dt=1693844111001&u_w=1440&u_h=900&biw=1440&bih=150&psw=1424&psh=130&frm=0&cl=560704565&uio=-&jsid=csa&nfp=1&jsv=560704565&rurl=https%3A%2F%2Fcse.google.com%2Fcse%3Fcx%3D529c3c5d3a6a24514%23gsc.tab%3D0%26gsc.q%3Dwatches%2520in%2520lekki%26gsc.sort%3D&referer=https%3A%2F%2Fprogrammablesearchengine.google.com%2F&adbw=master-1%3A1396"></iframe>
              </div> */}
              <div id="adBlock">
                {ads &&
                  ads.map((item, index) => (
                    <AdsResultComp result={item} theme={theme} />
                  ))}
              </div>
              {items.slice(0, 2).map((item, index) => (
                <ResultComp theme={theme} result={item} />
              ))}
            </div>
          </div>
        </div>

        {/* section 2 (images)*/}
        {images.length > 0 && (
          <div className=" mt-[12px] mb-[40px]">
            <div className="relative border border-[grey] p-[24px] rounded-[8px]">
              {/* see all images button */}
              <div
                className="absolute bottom-[-30px] w-[180px] left-[50%] ml-[-90px] cursor-pointer"
                onClick={() => {
                  changeTab("Images");
                }}
              >
                <WebsiteButton label={"See all Images"} />
              </div>
              <p>Images</p>
              <div className=" relative mt-[15px] mb-[24px]">
                {/* arrows */}
                {/* left */}
                <div
                  onClick={() => {
                    handleScroll(false);
                  }}
                  className="cursor-pointer left-[-20px] z-20  top-[50%] mt-[-20px] absolute "
                >
                  <CircleArrow />
                </div>

                {/* right */}
                <div
                  onClick={() => {
                    handleScroll(true);
                  }}
                  className="cursor-pointer rotate-180 right-[-20px] z-20  top-[50%] mt-[-20px] flex items-center justify-center absolute w-[40px] h-[40px] bg-[grey] rounded-full "
                >
                  <CircleArrow />
                </div>
                <div
                  ref={ref}
                  className=" relative search-scroll overflow-x-auto"
                >
                  {/* grid images */}
                  <div className=" flex gap-[10px] min-w-[1000px] ">
                    {/* image 1 */}
                    {images.slice(0, 5).map((image, index) => (
                      <AllResultImageComp image={image} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* section 3(result section) */}
        <div className="flex flex-col gap-[30px]">
          {items.slice(2).map((item, index) => (
            <ResultComp theme={theme} result={item} />
          ))}
        </div>

        {/* section 4(videos) */}
        {/* <div className="mt-[12px] mb-[40px]">
          <div className="relative border border-[grey] p-[24px] rounded-[8px]">
            
            <div className="absolute bottom-[-30px] w-[180px] left-[50%] ml-[-90px]">
              <WebsiteButton label={"See all Videos"} />
            </div>
            <p>Videos</p>
            <div className="relative mt-[15px] mb-[20px]">
             
              <div className="cursor-pointer left-[-20px] z-20  top-[50%] mt-[-20px] absolute ">
                <CircleArrow />
              </div>

              
              <div className="cursor-pointer rotate-180 right-[-20px] z-20  top-[50%] mt-[-20px] flex items-center justify-center absolute w-[40px] h-[40px] bg-[grey] rounded-full ">
                <CircleArrow />
              </div>
              <div className="relative search-scroll overflow-x-auto">
                
                <div className="flex gap-[10px] min-w-[1068px]">
                  
                  <div className="cursor-pointer object-scale">
                    <div className="relative w-[263px] h-[132px] object-div overflow-auto rounded-t-[8px]">
                      
                      <div className="cursor-pointer z-20 top-[50%] w-[30px] left-[50%] mt-[-15px] ml-[-15px] absolute ">
                        <img className="w-full " src={play} alt=""></img>
                      </div>

                      
                      <div className="absolute  z-20 p-[5px] bottom-[10px] right-[10px]  bg-[black]">
                        <p className="text-xs">5:33</p>
                      </div>
                      <img
                        className="scale-img w-full h-full object-cover rounded-t-[8px]"
                        src={image}
                        alt=""
                      ></img>
                    </div>
                    
                    <div className="border border-[grey] border-t-0 rounded-b-[8px] p-[12px] shadow-sm">
                      <p className="text-sm">name - location</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* section5(more results) */}
        <div className="flex flex-col gap-[30px]">
          {/* <ResultComp theme={theme} />
          <ResultComp theme={theme} />
          <ResultComp theme={theme} />
          <ResultComp theme={theme} /> */}
        </div>

        {/* section 6 (related search)*/}
        {/* <div className="">
          <p>Related Search</p>
          <div className="grid grid-cols-2 gap-x-[24px] gap-y-[10px] mt-[24px]">
            
            <div className="cursor-pointer flex items-center gap-[10px] py-[8px] px-[12px] rounded-[100px] border border-[grey]">
              
              <div>
                <img src={searchWhite} alt=""></img>
              </div>
              <p>Love is not relatable</p>
            </div>

           
            <div className="cursor-pointer flex items-center gap-[10px] py-[8px] px-[12px] rounded-[100px] border border-[grey]">
              
              <div>
                <img src={searchWhite} alt=""></img>
              </div>
              <p>Love is not relatable</p>
            </div>

            
            <div className="cursor-pointer flex items-center gap-[10px] py-[8px] px-[12px] rounded-[100px] border border-[grey]">
              
              <div>
                <img src={searchWhite} alt=""></img>
              </div>
              <p>Love is not relatable</p>
            </div>

            
            <div className="cursor-pointer flex items-center gap-[10px] py-[8px] px-[12px] rounded-[100px] border border-[grey]">
             
              <div>
                <img src={searchWhite} alt=""></img>
              </div>
              <p>Love is not relatable</p>
            </div>
          </div>
        </div> */}

        {/* section 7(navigation) */}
        <SearchNavigation
          nextSearch={nextSearch}
          currentIndex={currentIndex}
          theme={theme}
        />
      </div>

      {/* column 2 */}
      <div className="row-start-1  col-span-12 md:col-span-9 lg:ml-[50px] lg:row-start-auto lg:col-span-5 mb-[50px]">
        {/* gemini AI response */}
        <div
          className={
            theme
              ? "border border-[red]  __gemini "
              : "__gemini__white    h-[300px] overflow-y-auto"
          }
        >
          <div className="">
            {refineAIResponse ? (
              <>
                <Typewriter text={refineAIResponse} delay={5} />
              </>
            ) : (
              <p className="__blinker"></p>
            )}
          </div>
        </div>
        {/* images area */}
        <div className="border border-[transparent] rounded-[8px] w-full lg:w-[300px]">
          {
            <>
              <div id="adsense"></div>
              <ins
                className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-format="fluid"
                data-ad-layout-key="+1w+rx+1+2-3"
                data-ad-client="ca-pub-2378864044628387"
                data-ad-slot="7947843677"
              ></ins>
            </>
          }
          {/* <ins
            class="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-3778422781150734"
            data-ad-slot="3299208144"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins> */}
          {/* <amp-ad width="100vw" height="320"
            type="adsense"
            data-ad-client="ca-pub-2378864044628387"
            data-ad-slot="7903160349"
            data-auto-format="rspv"
            data-full-width="">
            <div overflow=""></div>
          </amp-ad> */}
        </div>
      </div>
    </div>
  );
};

export default AllResults;
