import React from "react";

const FormButtonTwo = ({ label, start, onSubmit, theme }) => {
  return (
    <div
      className={
        start
          ? "col-start-0 sm:col-start-3 border border-[#ACB5BB] py-[16px] px-[24px] rounded-[8px] w-full cursor-pointer"
          : " border border-[#ACB5BB] py-[16px] px-[24px] rounded-[8px] w-full cursor-pointer"
      }
      onClick={() => {
        onSubmit();
      }}
    >
      <p className={" text-center font-semibold  text-[#1a1c1e]"}>{label}</p>
    </div>
  );
};

export default FormButtonTwo;
