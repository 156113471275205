import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const ProjectsNavComp = ({ label, color, link, theme }) => {
  // const [state, setState] = useState(active);
  return (
    <NavLink to={link}>
      {({ isActive }) => (
        <div
          className={
            isActive
              ? color === "green"
                ? "text-base font-normal text-[#0B7026] relative z-50  border-b-2 border-[#0B7026] py-[15px] px-[24px] w-fit"
                : "text-base font-normal text-white relative z-50  border-b-2 border-[#0B7026] py-[15px] px-[24px] w-fit"
              : "cursor-pointer text-base font-normal text-[#A2A6AA] relative z-50  border-b border-[#A2A6AA] py-[15px] px-[24px] w-fit"
          }
        >
          <p className="">{label}</p>
        </div>
      )}
    </NavLink>
  );
};

export default ProjectsNavComp;
