import React, { useEffect, useRef, useState } from "react";
// assests
import inputSearch from "../../assests/website/input-search.svg";
import inputSearchOne from "../../assests/website/header-search-white.svg";
import inputSearchTwo from "../../assests/webapp/white-search.svg";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
import { useMountEffect } from "primereact/hooks";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";

const SearchComponent = ({
  placeholder,
  bg,

  setValue,
  value,
  onSubmit,
  theme,
  phone,
}) => {
  const inputRef = useRef(null);
  const op = useRef(null);
  const [searchValue, SetSearchValue] = useState("");

  const msgs = useRef(null);

  useMountEffect(() => {
    // msgs.current.show(
    //     { sticky: true, severity: 'info', summary: 'Info', detail: 'Message Content', closable: true }
    // );
  });

  const getUrlParameter = (sParam) => {
    const sPageURL = window.location.search.substring(1); //get the url parameter
    const sURLVariables = sPageURL.split("&");
    var sParameterName, i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=");

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined
          ? null
          : decodeURIComponent(sParameterName[1]);
      }
    }
  };

  useEffect(() => {
    if (window.location.search.length > 0) {
      const s = getUrlParameter("q");

      if (s) {
        SetSearchValue(s);
      } else {
        SetSearchValue("");
      }
    }

    // Focus the input element when the component mounts
    if (inputRef) {
      inputRef.current.focus();
    }

    // Add event listener to window click
    // const handleClickOutside = (event) => {
    //   if (inputRef.current && !inputRef.current.contains(event.target)) {
    //     // Click occurred outside the input element
    //     if (inputRef) {
    //       inputRef.current.focus();
    //       console.log("click outside");
    //     }
    //   }
    // };

    // window.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      // window.removeEventListener("click", handleClickOutside);
    };
  }, [window.location]);

  return (
    <>
      <div
        className={
          bg === "white"
            ? " bg-[white] border border-[#DCE4E8]  rounded-[8px] py-[12px] px-[18px] flex gap-[16px] w-full"
            : theme
            ? phone
              ? " bg-[#EAEAEA]  rounded-l-[8px] py-[12px] px-[18px] flex gap-[16px] w-full"
              : " bg-[#EAEAEA]  rounded-[8px] py-[12px] px-[18px] flex gap-[16px] w-full"
            : phone
            ? "bg-[#EAEAEA] dark-theme-h-input rounded-l-[8px] py-[12px] px-[18px] flex gap-[16px] w-full"
            : "bg-[#EAEAEA] dark-theme-h-input rounded-[8px] py-[12px] px-[18px] flex gap-[16px] w-full"
        }
      >
        <div className="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
          >
            <path
              stroke={
                bg === "white" ? "#1A1C1E" : theme ? "#1A1C1E" : "#ffffff"
              }
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
              d="M11.5 21a9.5 9.5 0 1 0 0-19 9.5 9.5 0 0 0 0 19ZM22 22l-2-2"
            />
          </svg>
          {/* <img
          src={
            bg === "white"
              ? inputSearchTwo
              : theme
              ? inputSearchOne
              : inputSearch
          }
          alt=""
        ></img> */}
        </div>
        <input
          id="input-search"
          // defaultValue={value}
          ref={inputRef}
          // value={searchValue}
          autofocus={true}
          onChange={(e) => {
            // op.current.toggle(e);
            // setTimeout(() => {
            //   msgs.current.show(
            //     { sticky: true, severity: 'info', summary: 'Info', detail: 'Message Content', closable: true }
            //   );
            // }, 200);
            // SetSearchValue(e.target.value);
            setValue(e.target.value);
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              // console.log("Enter key up");
              onSubmit();
            }
          }}
          type="text"
          autocomplete="on"
          className={
            bg === "white"
              ? "header-input-white"
              : theme
              ? "header-input "
              : "header-input dark-theme-h-input "
          }
          placeholder={placeholder}
        ></input>
      </div>
      <div>
        {/* <div>
            <Messages ref={msgs} />
        </div> */}
        {/* <Button type="button" icon="pi pi-image" label="Image" onClick={(e) => op.current.toggle(e)} /> */}
        {/* <OverlayPanel ref={op}>
          <Messages ref={msgs} />
        </OverlayPanel> */}
      </div>
    </>
  );
};

export default SearchComponent;
