import React from "react";

const WebsiteTextAreaComp = ({ label, theme, setValue }) => {
  return (
    <div className="w-full">
      <label className="mb-[6px] text-[17px] leading-[26px] font-semibold block">
        {label}
      </label>
      <textarea
        className={
          theme
            ? "w-full h-[300px] bg-[white] border border-[#D5DAE1] rounded-[8px] p-[16px] text-[#6C7278] text-[14px] leading-[19px] font-normal"
            : "w-full h-[300px] bg-[#303030] border border-[#575757] rounded-[8px] p-[16px] text-[#6C7278] text-[14px] leading-[19px] font-normal"
        }
        onChange={(e) => {
          setValue(e.target.value)
        }}
      ></textarea>
    </div>
  );
};

export default WebsiteTextAreaComp;
