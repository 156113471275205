import React, { useEffect, useState } from "react";
import arrow from "../../assests/webapp/arrow-settings.svg";
import arrowWhite from "../../assests/webapp/arrow-settings-white.svg";
import { NavLink } from "react-router-dom";

const SettingsSideComp = ({ imgOne, imgTwo, label, link }) => {
  return (
    <NavLink to={link}>
      {({ isActive }) => (
        <div
          className={
            isActive
              ? "flex items-center justify-between  p-[24px] rounded-[16px] bg-[#0B7026] text-white"
              : "flex items-center justify-between cursor-pointer  p-[24px] rounded-[16px]"
          }
        >
          {/* left */}
          <div className="flex gap-[16px] items-center">
            {/* left left */}
            <div
              className={
                isActive
                  ? "rounded-[100%] min-w-[40px] w-[40px] h-[40px] bg-[white] flex items-center justify-center"
                  : "rounded-[100%] min-w-[40px] w-[40px] h-[40px] bg-[#EDF1F3] flex items-center justify-center"
              }
            >
              <div className="">
                <img src={isActive ? imgTwo : imgOne} alt=""></img>
              </div>
            </div>

            {/* left right */}
            <div>
              <p className="text-lg font-medium">{label}</p>
            </div>
          </div>

          {/* right */}
          <div className="cursor-pointer">
            <img src={isActive ? arrowWhite : arrow} alt=""></img>
          </div>
        </div>
      )}
    </NavLink>
  );
};

export default SettingsSideComp;
