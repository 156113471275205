import React, { useState } from "react";
import cancelBlack from "../../assests/website/cancel.svg";
import cancelWhite from "../../assests/website/cancel-black.svg";
import backBlack from "../../assests/website/back_nav.svg";
import backWhite from "../../assests/website/back_nav_black.svg";
import WebNavComp from "./WebNavComp";
import UserClickComp from "./UserClickComp";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateUserType } from "../../redux/TypeUserSlice";
import { useTranslation } from "react-i18next";

const SignAsComp = ({ sign, setOpenHam, setChangeView, theme }) => {
  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  const [activeOne, setActiveOne] = useState(false);
  const [user, setUser] = useState("");

  // user type
  const userType = useSelector((state) => state?.userType?.data);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // console.log("user-type", userType);

  // put user in

  const handleClick = () => {
    setActive(true);
  };
  return (
    <div>
      {/* cancel button */}

      <div className=" flex items-center justify-between mb-[45px]">
        <div
          onClick={() => {
            setChangeView(false);
          }}
          className="cursor-pointer"
        >
          {theme ? (
            <img src={backWhite} alt=""></img>
          ) : (
            <img src={backBlack} alt=""></img>
          )}
        </div>
        <div
          onClick={() => {
            setOpenHam(false);
            setChangeView(false);
          }}
          className="cursor-pointer"
        >
          {theme ? (
            <img src={cancelWhite} alt=""></img>
          ) : (
            <img src={cancelBlack} alt=""></img>
          )}
        </div>
      </div>

      {/* header */}
      <p className="mx-[14px] text-[26px] font-medium leading-[37px] mb-[34px]">
        {sign} as
      </p>

      {/* group */}
      <div className="">
        <div
          onClick={() => {
            setActive(true);
            setActiveOne(false);
            setUser("Search User");

            localStorage.setItem("user_type", "search_user");
            if (sign === "Sign in") {
              navigate("/login");
            } else {
              navigate("/signup");
            }

            // dispatch(updateUserType("Search User"));
          }}
          className={
            active
              ? theme
                ? "webnav-bg-white curor-pointer rounded-[8px] px-[16px] py-[12px] text-[#1A1C1E]"
                : "border border-[blue] webnav-bg curor-pointer rounded-[8px] px-[16px] py-[12px] text-[white] "
              : theme
              ? "text-[#1A1C1E] px-[16px] cursor-pointer py-[12px] "
              : "px-[16px] cursor-pointer py-[12px] text-[#7C7C7C]"
          }
        >
          <p className="text-base font-medium">
            {t("home.header.navigation.searchUser")}
          </p>
        </div>
        {/* secon one */}
        <div
          onClick={() => {
            setActiveOne(true);
            setActive(false);
            setUser("Climate Innovator");
            localStorage.setItem("user_type", "climate_innovator");

            if (sign === "Sign in") {
              navigate("/login");
            } else {
              navigate("/signup");
            }
          }}
          className={
            activeOne
              ? theme
                ? "webnav-bg-white curor-pointer rounded-[8px] px-[16px] py-[12px] text-[#1A1C1E]"
                : "border border-[red] webnav-bg curor-pointer rounded-[8px] px-[16px] py-[12px] text-[white] "
              : theme
              ? "text-[#1A1C1E] px-[16px] cursor-pointer py-[12px] "
              : "px-[16px] cursor-pointer py-[12px] text-[#7C7C7C]"
          }
        >
          <p className="text-base font-medium">
            {t("home.header.navigation.climateInnovator")}
          </p>
        </div>
      </div>

      {/* button */}
      {/* <div className="mt-[34px]">
        <div
          onClick={() => {
           
          }}
          className="cursor-pointer bg-[#0B7026] rounded-[8px] py-[12px] flex items-center justify-center w-full"
        >
          <p className="text-[14px] font-semibold leading-[20px]">{sign}</p>
        </div>
      </div> */}
    </div>
  );
};

export default SignAsComp;
