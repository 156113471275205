import React from "react";
import hamburger from "../../assests/website/hamburger.svg";
import hamburgerBlack from "../../assests/website/hamburger-black.svg";

const HamburgerMenu = ({ theme, setOpenHam, dash }) => {
  return (
    <div
      onClick={() => {
        setOpenHam(true);
      }}
      className={
        theme
          ? dash
            ? "min-w-[48px] rounded-full bg-[white]  p-[10px] cursor-pointer"
            : "min-w-[48px] rounded-full bg-[#EAEAEA]  p-[10px] cursor-pointer"
          : "min-w-[48px] rounded-full  dark-theme-h-bg  p-[10px] cursor-pointer"
      }
    >
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          fill="none"
        >
          <path
            fill={theme ? "#1A1C1E" : "#ffffff"}
            fill-rule="evenodd"
            d="M2.334 6.278a1.611 1.611 0 0 1 1.611-1.611h19.333a1.61 1.61 0 1 1 0 3.222H3.945a1.611 1.611 0 0 1-1.611-1.61ZM2.334 14.334a1.611 1.611 0 0 1 1.611-1.611h19.333a1.611 1.611 0 1 1 0 3.222H3.945a1.611 1.611 0 0 1-1.611-1.611ZM2.334 22.39a1.611 1.611 0 0 1 1.611-1.612h9.667a1.612 1.612 0 0 1 0 3.223H3.945a1.611 1.611 0 0 1-1.611-1.612Z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>
  );
};

export default HamburgerMenu;
