import React, { useEffect, useRef, useState } from "react";
// assests
import arrow from "../../assests/webapp/arrow-down.svg";
import ClickOustideTwoRefs from "../../globals/clickOustideTwoRefs";

const CityComp = ({ label, required, list, setValue, value }) => {
  const [open, setOpen] = useState(false);
  const [select, setSelected] = useState("");
  const ref = useRef();
  const ref1 = useRef();

  ClickOustideTwoRefs(ref, ref1, setOpen);

  return (
    <div className=" relative ">
      <label className="flex gap-[2px] text-[#ACB5BB] font-medium text-[12px] block mb-[3px]">
        <p>{label}</p>
        {required && <p className="text-[#D80027]">*</p>}
      </label>{" "}
      <div
        ref={ref1}
        onClick={() => {
          {
            open ? setOpen(false) : setOpen(true);
          }
        }}
        className="cursor-pointer flex items-center justify-between px-[24px] py-[18px] w-full border border-[#DCE4E8] text-[14px] rounded-[8px]"
      >
        <p
          className={
            select
              ? "text-sm font-medium"
              : "text-sm font-medium text-[#ACB5BB]"
          }
        >
          {value ? value : select ? select : "select"}
        </p>
        <div
          className={open ? " rotate-180 cursor-pointer" : " cursor-pointer"}
          onClick={() => {}}
        >
          <img src={arrow} alt=""></img>
        </div>
      </div>
      {/* hidden options */}
      <div
        ref={ref}
        className={
          open
            ? "  block shadow-box-sm rounded-[8px] z-20   w-full absolute top-[84px]  bg-white"
            : "hidden "
        }
      >
        <div className="bg-white rounded-[8px] max-h-[255px]  overflow-auto   cursor-pointer">
          {list?.map((data, index) => (
            <div
              key={index}
              onClick={() => {
                setSelected(data);
                setValue(data);
                setOpen(false);
              }}
              className="  px-[24px] py-[18px] curor-pointer select-comp  "
            >
              <p className="text-sm  font-medium rounded-[8px]">{data}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CityComp;
