import { createSlice } from "@reduxjs/toolkit";

export const ThemeSlice = createSlice({
  // name
  name: "theme",

  // Initial State
  initialState: {
    themeState: false,
  },

  // reducers
  reducers: {
    updateTheme: (state, action) => {
      state.themeState = action.payload;
    },
  },
});

export const { updateTheme } = ThemeSlice.actions;
export default ThemeSlice.reducer;
