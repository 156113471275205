import React from "react";
import coins from "../../assests/website/header-coins.svg";
const ReceintNotComp = ({ header, notification, time, theme }) => {
  return (
    <div className=" flex flex-col sm:flex-row gap-y-[5px]  sm:items-center justify-between">
      {/* left */}
      <div className=" flex gap-[12px] items-center">
        {/* left left */}
        <div
          className={
            theme
              ? "bg-[white] flex items-center justify-center min-w-[52px] w-[52px] h-[52px] rounded-[100%]"
              : "bg-[#F4F4F7] flex items-center justify-center min-w-[52px] w-[52px] h-[52px] rounded-[100%]"
          }
        >
          <div className=" w-fit">
            <img src={coins} alt=""></img>
          </div>
        </div>
        {/* left right */}
        <div className=" flex flex-col gap-[6px]">
          <p className="text-base font-semibold">{header}</p>
          <p className="font-normal text-sm text-[#ACB5BB]">
            {notification.substring(0, 50)}....
          </p>
        </div>
      </div>
      {/* right */}
      <div className=" flex gap-[6px] items-center justify-between">
        <p className="text-sm ml-[16px] sm:ml-0 text-[#ACB5BB] font-medium">
          {time}
        </p>
        <div className="bg-[#58BA59] w-[8px] h-[8px] rounded-[100%]"></div>
      </div>
    </div>
  );
};

export default ReceintNotComp;
