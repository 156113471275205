import React, { useEffect, useState } from "react";
import PasswordInputComp from "./PasswordInputComp";
import FormButtonTwo from "./FormButtonTwo";
import FormButtonComp from "./FormButtonComp";
import { auth } from "../../firebase/firebaseConfig";
import { toast } from "react-toastify";
import {
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  getAuth,
} from "firebase/auth";
import { useTranslation } from "react-i18next";

const Security = ({ setNavlink }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    setNavlink(t("appHeader.security"));
  }, []);

  const changePassword = async () => {
    if (oldPassword === "" || newPassword === "" || repeatPassword === "") {
      return;
    }
    if (newPassword !== repeatPassword) {
      return;
    }

    try {
      const user = getAuth().currentUser;
      if (user === null) {
        return;
      }
      await reauthenticateWithCredential(
        user,
        EmailAuthProvider.credential(user.email, oldPassword)
      );
      await updatePassword(user, newPassword);
      toast.success("Password changed successfully");
    } catch (e) {
      toast.error("The action couldn't be completed. Please try again.");
    }
  };

  return (
    <div className=" flex flex-col gap-[32px]">
      {/* password comp */}
      <div className="flex flex-col gap-[32px]">
        <PasswordInputComp
          label={t("security.oldPass")}
          setValue={setOldPassword}
        />
        <PasswordInputComp
          label={t("security.newPass")}
          setValue={setNewPassword}
        />
        <PasswordInputComp
          label={t("security.repNewPass")}
          setValue={setRepeatPassword}
        />
      </div>
      {/* line area */}
      <div className="border border-[#EDF1F3]"></div>
      {/* button area */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-[24px]">
        {/* cancel */}
        {/* <FormButtonTwo label={"Cancel"} /> */}
        {/* yes */}
        <FormButtonComp
          label={t("security.changePass")}
          onSubmit={changePassword}
        />
      </div>
    </div>
  );
};

export default Security;
