import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  increment,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";

const updateTotalSearchCount = async () => {
  const docRef = doc(db, "stats", "search");
  await updateDoc(docRef, {
    total_search: increment(1),
    total_coins: increment(1),
  });
};

export default updateTotalSearchCount;
