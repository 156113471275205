import React, { useState } from "react";

const AllResultImageComp = ({ image }) => {
  // console.log("images ======", image.link);
  const [open, setOpen] = useState(false);
  // if(image.link) {
  //   return(<></>);
  // }
  return (
    <a href={image.image.contextLink}>
      <div
        className="object-scale cursor-pointer relative"
        onMouseLeave={() => {
          console.log("on mouse leave");
          setOpen(false);
        }}
      >
        <div className="object-div w-[178px] h-[185px] rounded-t-[8px] overflow-auto">
          <img
            className="scale-img w-full h-full object-cover rounded-t-[8px] relative"
            src={image.link ?? image.image.thumbnailLink}
            alt=""
          ></img>
        </div>
        {/* details */}
        <div
          className="border border-[grey] border-t-0 rounded-b-[8px] p-[12px] shadow-sm max-w-[178px] h-[65px]"
          onMouseOver={() => {
            console.log("on mouse over");
            setOpen(true);
          }}
        >
          <p className="text-sm">{image.title.substring(0, 34)}...</p>
        </div>
        {/* hover snippet */}

        <div
          className={
            open
              ? "block absolute z-20 left-[20px] bottom-[10px] px-[5px] py-[5px] border border-[grey] bg-[white] w-full"
              : "hidden"
          }
        >
          <p className="text-[10px]">{image.title}</p>
        </div>
      </div>
    </a>
  );
};

export default AllResultImageComp;
