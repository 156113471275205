import React, { useEffect, useRef, useState } from "react";

const LoginFlexLeftComp = ({ image, carousel }) => {
  const [steps, setSteps] = useState(1);
  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update the count state every second
      if (steps >= 3) {
        setSteps((prevStep) => prevStep - 2);
      } else {
        setSteps((prevStep) => prevStep + 1);
      }
    }, 10000);

    // Clean up the interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };
  }, [steps]);
  return (
    <div className=" md:basis-1/2 h-full">
      {carousel ? (
        steps === 1 ? (
          <img
            src={image.image1}
            alt=""
            className=" h-full w-full object-cover object-top"
          ></img>
        ) : steps === 2 ? (
          <img
            src={image.image2}
            alt=""
            className=" h-full w-full object-cover object-top"
          ></img>
        ) : steps === 3 ? (
          <img
            src={image.image3}
            alt=""
            className=" h-full w-full object-cover object-top"
          ></img>
        ) : (
          <></>
        )
      ) : (
        <img
          src={image}
          alt=""
          className="h-full w-full object-cover object-top"
        ></img>
      )}
    </div>
  );
};

export default LoginFlexLeftComp;
