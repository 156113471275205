import React, { useState } from "react";
import eyeSlash from "../../assests/webapp/eye-slash.svg";
import eyeOpen from "../../assests/webapp/eye-open.svg";

const PasswordInputComp = ({ label, setValue }) => {
  const [eye, setEye] = useState(false);
  const [type, setType] = useState("password");
  return (
    <div>
      <label className="text-[#ACB5BB] font-medium text-[12px] block mb-[3px]">
        {label}
      </label>
      <div className="flex relative items-center justify-between w-full  border border-[#DCE4E8] rounded-[8px]">
        <input
          type={type}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          autoComplete="off"
          className=" text-[14px]  rounded-[8px] w-full pl-[24px] pr-[60px] py-[18px] "
        ></input>
        {/* image */}
        {eye ? (
          <div
            className="cursor-pointer h-[24px] w-[24px] absolute top-[18px] right-[24px]"
            onClick={() => {
              setEye(false);
              setType("password");
            }}
          >
            <img
              src={eyeOpen}
              className="h-full w-full  object-cover"
              alt=""
            ></img>
          </div>
        ) : (
          <div
            className="cursor-pointer absolute top-[18px] right-[24px]"
            onClick={() => {
              setEye(true);
              setType("text");
            }}
          >
            <img src={eyeSlash} alt=""></img>
          </div>
        )}
      </div>
    </div>
  );
};

export default PasswordInputComp;
