import React from "react";
import searchBlack from "../../assests/website/header-search-white.svg";
import searchWhite from "../../assests/website/header-search-black.svg";

const SearchButton = ({ value, theme, phone, onSubmit }) => {
  return (
    <div
      onClick={() => {
        if (value === "" || value === null) {
          return;
        }
        onSubmit();
        // localStorage.setItem("global_search", value);
        // window.location.href = `/website/search?q=${value}`;
      }}
      className={
        phone
          ? "bg-[#0B7026] p-[13px] h-[48px] rounded-r-[8px] cursor-pointer"
          : " bg-[#0B7026] p-[13px] h-[48px] rounded-[8px] cursor-pointer"
      }
    >
      <div className="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          fill="none"
        >
          <path
            stroke={theme ? "#FFFFFF" : "#1A1C1E"}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2.5"
            d="m20 20-4.343-4.343m0 0A8 8 0 1 0 4.345 4.344a8 8 0 0 0 11.312 11.313Z"
          />
        </svg>
      </div>
    </div>
  );
};

export default SearchButton;
