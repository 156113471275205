import React, { useEffect, useState } from "react";
import copy from "../../assests/webapp/copy.svg";
import CoinComp from "../website/CoinComp";
import coin from "../../assests/website/header-coins.svg";
import WebsiteButton from "../website/WebsiteButton";
import loader from "../../assests/webapp/loader-svg.gif";
import { doc, updateDoc, arrayUnion, increment } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const RewardLinkComp = ({
  id,
  image,
  name,
  theme,
  subHeader,
  description,
  link,
  coins,
  subscribers_list,
  user,
  user_total_coins,
  user_data,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const redeemClicked = async (e) => {
    // console.log("Entering function");
    if (user === undefined) {
      toast.error("Please login to redeem rewards.");
      return;
    }
    if (Number(`${coins}`) > Number(`${user_total_coins}`)) {
      toast.error("You don't have enough coins to redeem.");
      return;
    }
    try {
      setLoading(true);
      // update rewards subscribers list
      const docRef = doc(db, "rewards", id);
      await updateDoc(docRef, {
        subscribers: arrayUnion(user),
        subscribers_data: arrayUnion({
          uid: user,
          name: `${user_data.first_name} ${user_data.last_name}`,
          email: user_data.email,
        }),
      });
      // update user coins
      const userDocRef = doc(db, "users", user);
      await updateDoc(userDocRef, {
        total_coins: increment(Number(`${coins}`) * -1),
      });
      subscribers_list.push(user);
      setLoading(false);
      toast.success("Reward redeemed successfully.");
    } catch (e) {
      toast.error(`An error occurred. Please try again. ${e}`);
      setLoading(false);
    }
  };

  useEffect(() => {
    // console.log("user_total_coins = ", user_total_coins);
  }, [loading]);

  return (
    <div className="">
      {/* up */}
      <div className="flex items-center justify-between mb-[8px]">
        {/* left */}
        <div className="flex gap-[8px] items-center">
          {/* left left */}
          <div className="flex justify-center items-center w-[40px] h-[40px] rounded-[100%] bg-[#F4F4F7]">
            <div>
              <img src={image} alt=""></img>
            </div>
          </div>

          {/* left right */}
          <p className="text-base md:text-[25px] leading-[23px] font-semibold">
            {name}
          </p>
        </div>
        {/* right */}
        <div>
          <p className="text-[#A2A6AA] text-base md:text-[24px] leading-[23px] font-semibold">
            {subHeader}
          </p>
        </div>
      </div>
      {/* middle */}
      <div className="mb-[12px]">
        <p className="font-normal leading-[24px] text-xs sm:text-sm">
          {description}
        </p>
        {/* redeem value */}
        <div className="w-[100px] mt-[10px] flex justify-between w-full">
          <CoinComp word={coins} image={coin} theme={theme} />
          {/* Redeem button */}
          {loading ? (
            <div className=" h-[40px] w-full flex justify-end items-center ">
              <div className=" w-[40px] ">
                <img className="w-full" src={loader} alt=""></img>
              </div>
            </div>
          ) : subscribers_list?.length === 0 ||
            !subscribers_list?.includes(user) ? (
            <div
              className="cursor-pointer bg-[#0B7026] px-[18px] py-[13px] rounded-[8px] flex justify-center items-center"
              onClick={redeemClicked}
            >
              <p className="text-[13px] sm:text-[15px] text-white font-semibold">
                {t("earnRewards.redeem")}
              </p>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      {/* down */}
      {subscribers_list?.length === 0 || !subscribers_list?.includes(user) ? (
        <div></div>
      ) : (
        <div className=" flex flex-col sm:flex-row gap-[15px]">
          {/* input */}
          <div
            className={
              theme
                ? "bg-[#F7F7F7] px-[18px] py-[13px] text-[#1A1C1E] rounded-[8px] flex justify-between w-full"
                : "px-[18px] py-[13px] bg-[#212324] text-[white] rounded-[8px] flex justify-between w-full"
            }
          >
            <p className="text-[13px] sm:text-[15px] leading-[23px] font-semibold underline">
              {link}
            </p>
            {/* copy area */}
            <div
              className="flex items-center gap-[5px] cursor-pointer"
              onClick={(e) => {
                navigator.clipboard.writeText(link);
                toast.success("Copied!");
              }}
            >
              <p className="text-[#A2A6AA] text-[15px] leading-[23px] font-semibold">
                {t("earnRewards.copy")}
              </p>
              {/* image */}
              <div className="min-w-[15px]">
                <img className="w-full" src={copy} alt=""></img>
              </div>
            </div>
          </div>
          {/* Redeem button */}
          {/* <div className="cursor-pointer bg-[#0B7026] px-[18px] py-[13px] rounded-[8px] flex justify-center items-center">
          <p className="text-[13px] sm:text-[15px] text-white font-semibold">
            Redeem
          </p>
        </div> */}
        </div>
      )}
    </div>
  );
};

export default RewardLinkComp;
