import React from "react";
// assests
import grey from "../../assests/webapp/project-image.svg";
// components
import OngoingProjectsDash from "./OngoingProjectsDash";
import ReceintNotComp from "./ReceintNotComp";
import ProjectNotification from "./ProjectNotification";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HiddenNotification = ({ setOpen, reff, data }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div
      ref={reff}
      className=" bg-[white] absolute top-[80px] left-[3vw] md:left-auto right-auto md:right-0 z-50 w-[93vw] md:w-[428px] shadow-box p-[24px] flex flex-col gap-[24px] rounded-[16px]"
    >
      {/* header */}
      <p className="text-2xl font-semibold text-[#1a1c1e]">
        {t("notification.notification")}
      </p>

      {/* area for notification */}
      <div className=" flex flex-col gap-[24px]">
        {/* comp 1 */}
        {data.length === 0 ? (
          <div className="text-sm text-[#ACB5BB]">
            {t("notification.noNotification")}
          </div>
        ) : (
          <>
            {data?.map((data, index) => {
              if (data.notification_type === "coins") {
                return (
                  <ReceintNotComp
                    header={data.notification_title}
                    notification={data.notification_subject}
                    time={"2m"}
                  />
                );
              } else {
                return (
                  <ProjectNotification
                    image={grey}
                    name={data.notification_title}
                    message={data.notification_subject}
                    time={"30h"}
                  />
                );
              }
            })}
            <div
              className="border border-[#DCE4E8] cursor-pointer rounded-[8px] py-[16px]"
              onClick={() => {
                navigate("notification");
                setOpen(false);
              }}
            >
              <p className="text-base font-semibold text-center">
                {t("notification.seeMore")}
              </p>
            </div>
          </>
        )}
      </div>

      {/* see more info */}
    </div>
  );
};

export default HiddenNotification;
