import React from "react";

const ProjectStory = ({ details }) => {
  return (
    <div className="mb-[30px]">
      <p className="font-normal text-sm md:text-lg">{details}</p>
    </div>
  );
};

export default ProjectStory;
