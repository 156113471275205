import React, { useState } from "react";
import { Link } from "react-router-dom";

const SearchImageComp = ({ image, theme }) => {
  console.log("image", image);
  const [open, setOpen] = useState(false);
  return (
    <Link to={image.image.contextLink}>
      <div
        className="relative shadow-md search-image-main rounded-[8px] cursor-pointer h-fit"
        onMouseLeave={() => {
          console.log("on mouse leave");
          setOpen(false);
        }}
      >
        {/* image */}
        <div className="relative w-full">
          <img
            className={`w-full h-auto lg:h-[197px] rounded-t-[8px] object-cover`}
            src={image.link}
            style={{
              height: `${image.image.height}px !important`,
              width: `${image.image.width}px !important`,
            }}
            alt=""
          ></img>
          {/* gradient */}
          <div className="search-gradient absolute w-full h-[100px] bottom-0"></div>
          {/* words */}
          <div className="search-image-word absolute bottom-[20px] left-[20px]">
            {/* <p className="text-xs text-white">{image.mine}</p> */}
            <p className="text-xs text-white">{image.displayLink}</p>
          </div>
        </div>

        {/* dets */}
        <div
          onMouseOver={() => {
            console.log("on mouse over");
            setOpen(true);
          }}
          className={
            theme
              ? "p-[12px] rounded-b-[8px] bg-white "
              : "bg-[#1a1c1e] p-[12px] rounded-b-[8px] "
          }
        >
          {/* <p>{image.title}</p> */}
          <p className="text-sm">{image.snippet.substring(0, 20)}...</p>
        </div>
        {/* hover snippet */}

        <div
          className={
            open
              ? "block absolute z-20 left-[20px] bottom-[-20px] px-[5px] py-[5px] border border-[grey] bg-[white] w-full"
              : "hidden"
          }
        >
          <p className="text-[10px]">{image.snippet}</p>
        </div>
      </div>
    </Link>
  );
};

export default SearchImageComp;
