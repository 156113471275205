import React from "react";
import loader from "../../assests/webapp/loader-svg.gif";

const FormButtonComp = ({ label, start, onSubmit, loading }) => {
  return (
    <div
      className={
        start
          ? "col-start-0  sm:col-start-4 bg-[#0B7026] py-[16px] px-[24px] rounded-[8px] w-full cursor-pointer"
          : " bg-[#0B7026] py-[16px] px-[24px] rounded-[8px] w-full cursor-pointer"
      }
      onClick={() => {
        onSubmit();
      }}
    >
      <div className="h-[24px]">
        {loading ? (
          <div className=" mx-auto w-[24px] ">
            <img src={loader} alt="" className="w-full h-full object-fit"></img>
          </div>
        ) : (
          <p className="text-[white] text-center font-semibold">{label}</p>
        )}
      </div>
    </div>
  );
};

export default FormButtonComp;
