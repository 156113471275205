import React, { useEffect, useState } from "react";
import { db } from "../../firebase/firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
// assests
import arrow from "../../assests/webapp/arrow-back-btn.svg";
// components

import ProjectCreateOne from "./ProjectCreateOne";
import ProjectCreateTwo from "./ProjectCreateTwo";
import { useNavigate, useParams } from "react-router-dom";
import CreateAccSuccessComp from "./CreateAccSuccessComp";
import { useTranslation } from "react-i18next";

const ProjectCreate = ({ setNavlink }) => {
  const params = useParams();
  const [project, setProject] = useState();
  // page 1 form dets
  const [fullName, setFullName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [projectGoal, setProjectGoal] = useState("");
  const [companyLogo, setCompanyLogo] = useState([]);
  const [selected, setSelected] = useState(null);
  const [region, setRegion] = useState("");
  const [phone, setPhone] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [incorporated, setIncorporated] = useState("");
  const [imageUrl1, setImageUrl1] = useState("");
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setNavlink("Create");
  }, []);

  const fetchProjectById = async () => {
    if (params.id) {
      const docRef = doc(db, "projects", params.id);
      try {
        const querySnapshot = await getDoc(docRef);
        // Handle the data or dispatch an action here
        setProject(querySnapshot.data());
      } catch (error) {
        // Handle the error or show an error message to the user
        console.log("Error fetching document:", error);
      }
    }
  };

  useEffect(() => {
    fetchProjectById();
  }, []);

  return (
    <div className=" bg-[white] rounded-[16px] px-[12px] py-[24px] md:px-[38px] md:py-[38px] relative">
      {/* header */}
      <div className=" ">
        {/* back button */}
        {page === 2 && (
          <div
            className="cursor-pointer  absolute top-[30px] sm:top-[50px] left-[12px] md:left-[38px] flex gap-[10px] items-center text-base font-semibold"
            onClick={() => {
              setPage(1);
            }}
          >
            <div className=" ">
              <img src={arrow} alt=""></img>
            </div>
            <p className="text-sm sm:text-base">{t("projectCreate.back")}</p>
          </div>
        )}
        <p className=" absolute top-[25px] sm:top-[38px] right-[12px] md:right-[38px] text-[#ACB5BB] text-[24px] sm:text-[32px] leading-[28px] sm:leading-[48px] font-bold ">
          {page === 1 ? "1/2" : "2/2"}
        </p>

        {params.id ? (
          <>
            <p className=" text-center text-[24px] sm:text-[32px] leading-[28px] sm:leading-[48px] font-bold mt-[60px] sm:mt-0">
              {t("projectCreate.personalInfoEdit")}
            </p>
            <p className=" mt-[4px] text-sm  sm:text-base font-medium text-center text-[#A8A8A8]">
              {t("projectCreate.editInfo")}
            </p>
          </>
        ) : (
          <>
            <p className=" text-center text-[24px] sm:text-[32px] leading-[28px] sm:leading-[48px] font-bold mt-[60px] sm:mt-0">
              {t("projectCreate.personalInfo")}
            </p>
            <p className=" mt-[4px] text-sm  sm:text-base font-medium text-center text-[#A8A8A8]">
              {t("projectCreate.letUsKnow")}
            </p>
          </>
        )}
      </div>

      {/* details */}
      <form className=" w-full md:w-[80%] mx-auto mt-[45px] flex flex-col gap-[24px]">
        {page === 1 ? (
          <ProjectCreateOne
            project={project}
            setPage={setPage}
            setFullName={setFullName}
            setProjectName={setProjectName}
            setCompanyLogo={setCompanyLogo}
            setFacebook={setFacebook}
            setInstagram={setInstagram}
            setLinkedIn={setLinkedIn}
            setRegion={setRegion}
            setPhone={setPhone}
            setCompanyName={setCompanyName}
            setProjectGoal={setProjectGoal}
            setIncorporated={setIncorporated}
            fullName={fullName}
            projectGoal={projectGoal}
            companyName={companyName}
            projectName={projectName}
            phone={phone}
            facebook={facebook}
            instagram={instagram}
            linkedIn={linkedIn}
            incorporated={incorporated}
            selected={selected}
            setSelected={setSelected}
            // setImageUrl1={setImageUrl1}
          />
        ) : page === 2 ? (
          <ProjectCreateTwo
            setPage={setPage}
            fullName={fullName}
            projectName={projectName}
            companyLogo={companyLogo}
            facebook={facebook}
            instagram={instagram}
            linkedIn={linkedIn}
            region={selected}
            phone={phone}
            projectGoal={projectGoal}
            companyName={companyName}
            incorporated={incorporated}
            project={project}
            // imageUrl1={imageUrl1}
          />
        ) : (
          <CreateAccSuccessComp
            words={
              params.id
                ? "Your Project has been updated successfully"
                : "Your Project has been uploaded to be reviewed by the admin"
            }
            button={"no"}
          />
        )}
      </form>
    </div>
  );
};

export default ProjectCreate;
