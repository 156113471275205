import React, { useState } from "react";
// assests
import csv from "../../assests/webapp/export-csv.svg";
import filter from "../../assests/webapp/table-filter.svg";
import SearchComponent from "./SearchComponent";
import FilterComp from "./FilterComp";
import AntTableComp from "./AntTableComp";
import { set } from "date-fns";
import { useTranslation } from "react-i18next";

const ProjectAntTableComp = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  return (
    <div>
      <div className=" flex sm:flex-row flex-col rounded-t-[16px] justify-between sm:items-center py-[16px] px-[24px] bg-[white] gap-[15px]">
        {/* left */}
        <div className="w-full sm:w-[304px]">
          <SearchComponent
            placeholder={t("projectTable.search")}
            bg={"white"}
            setValue={setValue}
          />
        </div>

        {/* right */}
        <div className="flex gap-[30px] items-center justify-between">
          {/* left */}
          {/* <div className="">
            <FilterComp image={filter} label={"filter"} />
          </div> */}

          {/* right */}
          <div className="w-full sm:w-fit">
            {" "}
            <FilterComp image={csv} label={t("projectTable.export")} />
          </div>
        </div>
      </div>

      <AntTableComp value={value} />
    </div>
  );
};

export default ProjectAntTableComp;
