import React from "react";
import loader from "../../assests/webapp/loader-svg.gif";
import {
  collection,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  doc,
  where,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { useState } from "react";
import { useEffect } from "react";
import ProjectMainContainer from "./ProjectMainContainer";
import ProjectComp from "./ProjectComp";
import GreenContainer from "../../components/website/GreenContainer";
// import { projects } from "../../firebase/projects";
import WebsiteButton from "./WebsiteButton";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const FetchProjects = ({ theme, all, projectId, showSearch }) => {
  const [allProjects, setAllProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = JSON.parse(localStorage.getItem("language"));

  // console.log("filter-projects", filteredProjects);
  // fetching all projects
  const fetchPost = async () => {
    setLoading(true);
    const querySnapshot = query(
      collection(db, "projects"),
      where("project_status", "==", "approved"),
      orderBy("coins_collected", "desc")
    );

    onSnapshot(querySnapshot, (snapshot) => {
      const newData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setAllProjects(newData);

      // console.log("new data", newData);
    });
  };

  const updateCountry = async () => {
    // const oldProjects = projects;

    allProjects.forEach(async (pro) => {
      const docRef = doc(db, "projects", `${pro.id}`);

      // const findProject = oldProjects.find((val) => {
      //   return val.name === pro.project_name;
      // });

      // const country = findProject.location;

      // let newValue = "";
      // if(country === "KEN") {
      //   newValue = "Kenya";
      // }
      // if(country === "ZMB") {
      //   newValue = "Zambia";
      // }
      // if(country === "TZA") {
      //   newValue = "Tanzania";
      // }
      // if(country === "RWA") {
      //   newValue = "Rwanda";
      // }
      // if(country === "COD") {
      //   newValue = "Cote D'ivoire";
      // }
      // if(country === "BDI") {
      //   newValue = "Burundi";
      // }
      // if(country === "BGD") {
      //   newValue = "Bangladesh";
      // }
      // if(country === "UGA") {
      //   newValue = "Uganda";
      // }
      // if(country === "ETH") {
      //   newValue = "Ethiopia";
      // }
      // if(country === "MOZ") {
      //   newValue = "Mozambique";
      // }
      // if(country === "BWA") {
      //   newValue = "Botswana";
      // }
      // if(country === "BTN") {
      //   newValue = "Bhutan";
      // }
      // if(country === "CMR") {
      //   newValue = "Cameroon";
      // }
      // if(country === "NWI") {
      //   newValue = "Malawi";
      // }
      // if(country === "IND") {
      //   newValue = "India";
      // }
      // if(country === "ZWE") {
      //   newValue = "Zimbabwe";
      // }
      // if(country === "NPL") {
      //   newValue = "Nepal";
      // }
      // if(country === "IDN") {
      //   newValue = "Indonesia";
      // }
      // if(country === "SOM") {
      //   newValue = "Somalia";
      // }
      // if(country === "SSD") {
      //   newValue = "South Sudan";
      // }
      // if(country === "GAB") {
      //   newValue = "Gambia";
      // }

      // if (pro.country_of_application === "") {
      //   await updateDoc(docRef, { country_of_application: "Nigeria" });
      // }
      // console.log("done");
    });
  };

  const filterProjects = () => {
    setLoading(true);
    const fp = [];
    const energyProjects = [];
    const remainingProjects = [];

    allProjects.forEach((val) => {
      // if (val.category_of_project.toLowerCase().trim() === "clean energy") {
      const isFeatured = val.featured ?? false;
      if (val.category_of_project.toLowerCase().trim() === "tree planting") {
        energyProjects.push(val);
      } else {
        remainingProjects.push(val);
      }
    });

    fp.push(...energyProjects);
    fp.push(...remainingProjects);
    setFilteredProjects(fp);
    setLoading(false);
  };

  // console.log("all projects", allProjects);

  // useEffect to filter the trees project first
  useEffect(() => {
    // setLoading(true);
    fetchPost();
  }, []);

  useEffect(() => {
    filterProjects();
  }, [allProjects]);

  // useEffect to initially set filtered projects to all projects

  // useEffect to search project
  useEffect(() => {
    const fp = [];
    const energyProjects = [];
    const remainingProjects = [];

    const f = filterValue.toLowerCase();

    if (f.trim() === "") {
      filterProjects();

      return;
    }
    setLoading(true);

    const searchedProjects = allProjects.filter((val) => {
      return (
        val.category_of_project.toLowerCase().includes(f) ||
        val.country_of_application.toLowerCase().includes(f) ||
        val.project_name.toLowerCase().includes(f)
      );
    });

    searchedProjects.forEach((val) => {
      // if (val.category_of_project.toLowerCase().trim() === "clean energy") {
      const isFeatured = val.featured ?? false;
      if (isFeatured) {
        energyProjects.push(val);
      } else {
        remainingProjects.push(val);
      }
    });

    fp.push(...energyProjects);
    fp.push(...remainingProjects);

    setFilteredProjects(fp);
    setTimeout(() => {
      setLoading(false);
    }, [1000]);
  }, [filterValue]);

  return (
    <div>
      {showSearch ? (
        <div className=" grid grid-cols-1 sm:grid-cols-1 w-full p-[20px] rounded-[8px] bg-[#2CD85D] text-white mb-[60px] mx-auto  max-w-[700px] mt-[50px]">
          <div className=" w-full ">
            <p className="font-semibold text-center md:text-start text-lg md:text-xl md:leading-[31px]">
              {t("allProjects.searchProjects")}
            </p>
            <div className=" flex flex-col sm:flex-row gap-[16px] sm:gap-[6px] mt-[15px]">
              {/* input */}
              <input
                onChange={(e) => {
                  setFilterValue(e.target.value);
                }}
                placeholder="Search by Name, Project, Country etc"
                className="w-full min-w-[100px] text-sm text-[#1a1c1e] header-input-white rounded-[8px] py-[12px] px-[24px]"
              ></input>
              {/* button */}
              {/* <div
        onClick={() => {
          updateCountry();
        }}
        className="w-full sm:w-[288px] mx-auto cursor-pointer mt-[12px] md:mt-0"
      >
        <WebsiteButton label={"See all Projects"} />
      </div> */}
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {loading ||
      (filteredProjects?.length <= 0 && allProjects?.length <= 0) ? (
        <div className=" h-[300px] w-full flex justify-center items-center ">
          <div className=" w-[40px] ">
            <img className="w-full" src={loader} alt=""></img>
          </div>
        </div>
      ) : (
        <ProjectMainContainer>
          {/* comp1 */}
          {filteredProjects?.length > 0 ? (
            filteredProjects?.map((data, index) =>
              data.id !== projectId && all ? (
                <ProjectComp
                  index={index}
                  id={data.id}
                  title={data.project_name}
                  logo={data.company_logo}
                  description={
                    lang?.value === "en"
                      ? data.project_description_translated.en
                      : lang?.value === "fr"
                      ? data.project_description_translated.fr
                      : lang?.value === "ge"
                      ? data.project_description_translated.de
                      : lang?.value === "ch"
                      ? data.project_description_translated.zh
                      : data.project_description_translated.en
                  }
                  total_coins={data.coins_collected}
                  project_goal={data.project_goal}
                  location={data.country_of_application}
                  gallery={data.project_picture}
                  category={data.category_of_project}
                  theme={theme}
                />
              ) : projectId && data.id !== projectId && index <= 6 ? (
                <ProjectComp
                  index={index}
                  id={data.id}
                  title={data.project_name}
                  logo={data.company_logo}
                  description={
                    lang?.value === "en"
                      ? data.project_description_translated.en
                      : lang?.value === "fr"
                      ? data.project_description_translated.fr
                      : lang?.value === "ge"
                      ? data.project_description_translated.de
                      : lang?.value === "ch"
                      ? data.project_description_translated.zh
                      : data.project_description_translated.en
                  }
                  total_coins={data.coins_collected}
                  project_goal={data.project_goal}
                  location={data.country_of_application}
                  gallery={data.project_picture}
                  category={data.category_of_project}
                  theme={theme}
                />
              ) : !projectId && index <= 5 ? (
                <ProjectComp
                  index={index}
                  id={data.id}
                  title={data.project_name}
                  logo={data.company_logo}
                  description={
                    lang?.value === "en"
                      ? data.project_description_translated.en
                      : lang?.value === "fr"
                      ? data.project_description_translated.fr
                      : lang?.value === "ge"
                      ? data.project_description_translated.de
                      : lang?.value === "ch"
                      ? data.project_description_translated.zh
                      : data.project_description_translated.en
                  }
                  total_coins={data.coins_collected}
                  project_goal={data.project_goal}
                  location={data.country_of_application}
                  gallery={data.project_picture}
                  category={data.category_of_project}
                  theme={theme}
                />
              ) : (
                <></>
              )
            )
          ) : (
            <p className="text-center ">No data </p>
          )}
        </ProjectMainContainer>
      )}
    </div>
  );
};

export default FetchProjects;
