import React from "react";
import ButtonToggle from "./ButtonToggle";

const NewTabArea = ({ header, setValue, value }) => {
  return (
    <div className="flex items-center justify-between ">
      <p className="text-[16px] md:text-[24px] leading-[27px] font-medium">
        {header}
      </p>

      {/* button toggle */}
      <ButtonToggle setValue={setValue} value={value} />
    </div>
  );
};

export default NewTabArea;
