import React, { useState } from "react";

// assests

import pic from "../../assests/website/web-gallery.png";
import circle from "../../assests/website/circle.svg";
import gradient from "../../assests/website/gradient-black.svg";
import rec from "../../assests/website/rec-dot.svg";
import card from "../../assests/website/project-card.png";
import svgs1 from "../../assests/website/home-back-svg.svg";
import backBlack from "../../assests/website/back_nav.svg";
import backWhite from "../../assests/website/back_nav_black.svg";
import loader from "../../assests/webapp/loader-svg.gif";

import InputComp from "../../components/webapp/InputComp";
import ProjectComp from "./ProjectComp";
import WebsiteButton from "./WebsiteButton";
import Socials from "./Socials";
import DetailsNavComp from "../webapp/DetailsNavComp";
import WebsiteProjectStory from "./WebsiteProjectStory";
import WebsiteProjectGallery from "./WebsiteProjectGallery";
import GreenContainer from "./GreenContainer";
import LeafHeader from "./LeafHeader";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDoc,
  doc,
  onSnapshot,
  increment,
  arrayUnion,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { useEffect } from "react";
import { toast } from "react-toastify";
import FetchProjects from "./FetchProjects";

import treeplanting1 from "../../assests/website/treeplanting/1.jpg";

import oceanplastics1 from "../../assests/website/oceanplastics/1.jpg";

import cleanenergy1 from "../../assests/website/cleanenergy/1.jpg";

import housing1 from "../../assests/website/housing/1.jpg";

import image1 from "../../assests/website/firstsiximages/cookinstove.jpg";
import image2 from "../../assests/website/firstsiximages/image-1.jpg";
import image3 from "../../assests/website/firstsiximages/micro-hydro.jpg";
import image4 from "../../assests/website/firstsiximages/portable-power.jpg";
import image5 from "../../assests/website/firstsiximages/rural-solar.jpg";
import image6 from "../../assests/website/firstsiximages/wind-energy.jpg";

import { useTranslation } from "react-i18next";
import FormButtonComp from "../webapp/FormButtonComp";

const ProjectDetails = ({ theme }) => {
  const [detailsNav, setDetailsNav] = useState("Story");
  const [amount, setAmount] = useState("");
  const [donateClicked, setDonateClicked] = useState(false);
  const [project, setProject] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const { t } = useTranslation();

  const lang = JSON.parse(localStorage.getItem("language"));

  const params = useParams();
  // console.log("id", params.id);

  const fetchProjectById = async () => {
    setLoading(true);
    const docRef = doc(db, "projects", params.id);
    try {
      onSnapshot(docRef, (querySnapshot) => {
        setProject(querySnapshot.data());
        setLoading(false);
      });
      // const querySnapshot = await getDoc(docRef);
      // Handle the data or dispatch an action here
    } catch (e) {
      // Handle the error or show an error message to the user
      error(e);
      // console.log("Error fetching document:", error);
      setLoading(false);
    }
  };

  // console.log("projects-id", project);

  console.log("current-user", lang);

  useEffect(() => {
    fetchProjectById();
  }, [params.id]);

  const donateProject = async () => {
    setLoading2(true);
    const donors = project.donors ?? [];

    if (currentUser === null || currentUser.user_type !== "search_user") {
      toast.error("Please login to back this project.");
      setLoading2(false);
      return;
    }

    if (Number(amount) < 5) {
      toast.error("Minimum number of coins required is 5.");
      setLoading2(false);
      return;
    }

    // update rewards donors list

    try {
      const docRef = doc(db, "projects", params.id);
      if (!donors.includes(currentUser.uid)) {
        await updateDoc(docRef, {
          donors: arrayUnion(currentUser.uid),
          donors_data: arrayUnion({ uid: currentUser.uid }), //"name": `${user_data.first_name} ${user_data.last_name}`, "email": user_data.email
          total_support: increment(1),
        });
      }
      await updateDoc(docRef, {
        coins_collected: increment(Number(`${amount}`)),
      });
      // update user coins
      const userDocRef = doc(db, "users", currentUser.uid);
      await updateDoc(userDocRef, {
        total_coins: increment(Number(`${amount}`) * -1),
      });
      // project.donors.push(currentUser.uid);
      setLoading2(false);
      setDonateClicked(false);
      toast.success("Project donated successfully.");
    } catch (e) {
      toast.error(`An error occurred. Please try again. ${e}`);
      setLoading2(false);
      setDonateClicked(false);
    }
  };

  const getImageByCategory = () => {
    const sixImages = {
      EVChaja: image2,
      "Ethanol Cookstove for sustainable cooking": image1,
      "Rural Solar  Energy Project": image5,
      "Portable solar powered irrigation systems ": image4,
      HuluBeje: image3,
      "Mpepu Micro-grid Project ": image6,
    };
    if (sixImages[project.project_name] !== undefined) {
      return sixImages[project.project_name];
    }
    if (project.category_of_project === "Tree planting") {
      return treeplanting1;
    }
    if (project.category_of_project === "Ocean plastics") {
      return oceanplastics1;
    }
    if (project.category_of_project === "Clean Energy") {
      return cleanenergy1;
    }
    if (project.category_of_project === "Housing") {
      return housing1;
    }
    return treeplanting1;
  };

  return project ? (
    <div
      className={theme ? "relative text-[#1A1C1E]" : "relative text-[white]"}
    >
      <div className="absolute hidden sm:block right-[0] top-[-70px]">
        <img src={svgs1} alt=""></img>
      </div>

      {loading ? (
        <div className=" h-[300px] w-full flex justify-center items-center ">
          <div className=" w-[40px] ">
            <img className="w-full" src={loader} alt=""></img>
          </div>
        </div>
      ) : (
        <div className=" max-w-[1200px] mx-[24px]  xl:mx-auto  relative  mb-[140px]">
          {/* back button */}
          <div
            onClick={() => {
              navigate("../all-projects");
            }}
            className="cursor-pointer"
          >
            {theme ? (
              <div className="cursor-pointer flex gap-[10px] mt-[50px] md:mt-[60px]">
                <img src={backWhite} alt=""></img>
                <p>{t("projectDetails.back")}</p>
              </div>
            ) : (
              <div className="cursor-pointer flex gap-[10px] mt-[50px] md:mt-[60px]">
                <img src={backBlack} alt=""></img>
                <p>{t("projectDetails.back")}</p>
              </div>
            )}
          </div>
          {/* profile box */}

          <div className="  relative grid grid-cols-1 lg:grid-cols-2 pt-[30px] pb-[50px] md:pt-[40px] md:pb-[50px] lg:pb-[70px] items-center">
            {/* left */}
            <div className=" row-start-2  lg:row-start-1 ">
              <div className=" hidden lg:block ">
                <div className="flex items-center gap-[8px]">
                  <p className="text-[24px] font-bold ">
                    {t("projectDetails.projectTitle")}
                  </p>
                  <div
                    className={
                      theme
                        ? "bg-[white] lg:bg-[#1A1C1E] w-[100px] h-[1px] md:h-[2px]"
                        : "bg-[white] w-[100px] h-[1px] md:h-[2px]"
                    }
                  ></div>
                </div>

                <p className="text-[54px] font-semibold leading-[54px] mb-[16px]">
                  {project?.project_name.length <= 50
                    ? project?.project_name
                    : `${project?.project_name.substring(0, 50)}...`}
                </p>
                <p className="text-[18px] leading-[29px] font-semibold">
                  {project?.company_name}
                </p>
                <p className="text-[18px] leading-[29px] font-semibold text-[#C2B484]">
                  {t("projectDetails.projectOwner")}
                </p>
              </div>

              <div className=" mt-[30px]">
                <GreenContainer
                  coinsCollected={project?.coins_collected}
                  // redeem={true}
                  projectGoal={project?.project_goal}
                />
              </div>
            </div>

            {/* right */}
            <div className="row-start-1  relative flex flex-col items-end ">
              {/* image */}
              <div className=" relative  w-[100%] sm:w-[80%] md:w-[65%] lg:w-[90%] mx-auto lg:mx-0  mb-[25px]">
                <div className="  w-full h-[500px]">
                  {
                    <img
                      className="w-full h-full object-cover"
                      src={getImageByCategory()}
                      alt=""
                    ></img>
                  }
                  {/* {project.company_logo ? (<img
                  className="w-full"
                  src={project?.company_logo}
                  alt=""
                ></img>) : project.project_picture.length > 0 ? (<img
                  className="w-full"
                  src={project?.project_picture[0]}
                  alt=""
                ></img>) : (
                  <img
                  className="w-full"
                  src={getImageByCategory()}
                  alt=""
                ></img>
                  // <div className="bg-[gray] w-full h-[350px]"></div>
                )} */}
                </div>
                {/* gradient */}
                <div className=" absolute bottom-0 block lg:hidden  w-full ">
                  <img className=" w-full" src={gradient} alt=""></img>
                </div>
                {/* tag */}
                <div className=" absolute top-[20px] right-[20px] sm:top-[50px] sm:right-[50px] p-[5px] w-fit bg-[#2CD85D] rounded-[8px] text-white font-bold text-[15px] leading-[22px]">
                  <p>{project.country_of_application}</p>
                </div>
                {/* absolute */}
                <div className=" absolute w-[55px] lg:w-[94px] md:w-fit top-[-20px] left-[-20px] md:top-[-30px] md:left-[-5%] ">
                  <img className="w-full" src={circle} alt=""></img>
                </div>

                <div className="text-[white] absolute left-[20px] bottom-[20px] lg:hidden block">
                  <div className="flex  items-center gap-[8px]">
                    <p className="text-base lg:text-[24px] font-bold ">
                      {t("projectDetails.projectTitle")}
                    </p>
                    <div
                      className={
                        theme
                          ? "bg-[white] lg:bg-[#1A1C1E] w-[100px] h-[1px] md:h-[2px]"
                          : "bg-[white] w-[100px] h-[1px] md:h-[2px]"
                      }
                    ></div>
                  </div>

                  <p className="text-[28px] lg:text-[54px] font-semibold leading-[30px] lg:leading-[54px] mb-[5px] lg:mb-[16px]">
                    {project?.project_name.length <= 50
                      ? project?.project_name
                      : `${project?.project_name.substring(0, 50)}...`}
                  </p>
                  <p className="text-base lg:text-[18px] leading-[29px]  font-semibold">
                    {project?.company_name}
                  </p>
                  <p className="text-sm lg:text-[19px] leading-[20px] lg:leading-[29px] font-semibold text-[#C2B484]">
                    {t("projectDetails.projectOwner")}
                  </p>
                </div>
              </div>
              {/* down */}
              <div className="w-full sm:w-fit mx-auto lg:mx-0 ">
                {/* button */}
                {!donateClicked && (
                  <>
                    <div className="w-full sm:w-[288px] mb-[18px] cursor-pointer">
                      <WebsiteButton
                        label={t("projectDetails.donateToProject")}
                        onClick={() => {
                          setDonateClicked(true);
                        }}
                      />
                    </div>
                    <div className=" w-fit">{/* <Socials /> */}</div>
                  </>
                )}
                {donateClicked && (
                  <>
                    <div className="flex flex-col gap-[24px] mb-[32px] text-[#000000]">
                      <InputComp
                        label={t("projectDetails.amountInCoins")}
                        setValue={setAmount}
                        required={true}
                      />
                      <div className="cursor-pointer ">
                        <FormButtonComp
                          label={t("projectDetails.donateBtn")}
                          loading={loading2}
                          onSubmit={donateProject}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* absolute */}
            {/* <div className="absolute bottom-[30px] ">
            <img src={card} alt=""></img>
          </div> */}
          </div>

          {/* story and gallery */}
          <div className="mt-0 lg:mt-[30px]">
            {/* header nav */}
            <div className=" flex">
              {/* nav button */}
              {detailsNav === "Story" ? (
                <DetailsNavComp
                  label={t("projectDetails.story")}
                  setDetailsNav={setDetailsNav}
                  isActive={true}
                  color={"green"}
                />
              ) : (
                <DetailsNavComp
                  label={t("projectDetails.story")}
                  setDetailsNav={setDetailsNav}
                />
              )}
              {detailsNav === "Gallery" ? (
                <DetailsNavComp
                  label={t("projectDetails.gallery")}
                  setDetailsNav={setDetailsNav}
                  isActive={true}
                  color={"green"}
                />
              ) : (
                <DetailsNavComp
                  label={t("projectDetails.gallery")}
                  setDetailsNav={setDetailsNav}
                />
              )}
            </div>

            {/* main */}
            <div className=" mt-[30px]">
              {detailsNav === "Story" ? (
                <WebsiteProjectStory
                  theme={theme}
                  story={
                    lang?.value === "en"
                      ? project?.project_description_translated.en
                      : lang?.value === "fr"
                      ? project?.project_description_translated.fr
                      : lang?.value === "ge"
                      ? project?.project_description_translated.de
                      : lang?.value === "ch"
                      ? project?.project_description_translated.zh
                      : project?.project_description_translated.en
                  }
                />
              ) : (
                <WebsiteProjectGallery images={project?.project_picture} />
              )}
            </div>
          </div>

          {/* main */}
          <div className="mt-[50px] w-fit mx-auto">
            <LeafHeader label={t("projectDetails.other")} />
          </div>

          <FetchProjects
            theme={theme}
            projectId={params.id}
            showSearch={false}
          />
        </div>
      )}
    </div>
  ) : (
    <div></div>
  );
};

export default ProjectDetails;
