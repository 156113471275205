import React, { useEffect, useState } from "react";

// assests
import login from "../../assests/website/login-pic.png";
import login1 from "../../assests/website/login-pic1.png";
import login2 from "../../assests/website/login-pic2.png";

// import LogoBig from "../../components/website/LogoBig";
import InputComp from "../../components/webapp/InputComp";
import PasswordInputComp from "../../components/webapp/PasswordInputComp";
import FormButtonComp from "../../components/webapp/FormButtonComp";
import LoginFlexComp from "../../components/webapp/LoginFlexComp";
import LoginFlexLeftComp from "../../components/webapp/LoginFlexLeftComp";
import LoginFlexRightComp from "../../components/webapp/LoginFlexRightComp";
import LoginComp from "../../components/webapp/LoginComp";
import { Link, useNavigate } from "react-router-dom";
import {
  browserLocalPersistence,
  browserSessionPersistence,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth, db } from "../../firebase/firebaseConfig";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../../redux/UserAuthSlice";
import { arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";
import store from "../../redux/Store";
import { fetchUser, setData } from "../../redux/UserSlice";
import { useTranslation } from "react-i18next";
import HandleLanguageChange from "../../globals/handleLanguageChange";

// components

const LoginApp = ({ theme }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  HandleLanguageChange();
  const storedUser = JSON.parse(localStorage.getItem("currentUser"));
  // states
  const [loading, setLoading] = useState(false);
  // form value
  const [error, setError] = useState("");
  const [firebaseError, setFirebaseError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  let user_type = localStorage.getItem("user_type");
  // console.log("stored user", JSON.parse(storedUser).user_type);

  console.log("remember", remember);
  // console.log("stored", storedUser?.user_type);
  // console.log("persistence)
  // use effect to check if there is an auth to auto signin
  console.log("user typelogin", user_type);
  console.log("isauthlogin", auth);
  console.log("storedlogin", storedUser);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        if (
          user_type === "search_user" &&
          storedUser?.user_type === "search_user"
        ) {
          // console.log("search_user");
          navigate("../profile");
          return;
        } else if (
          user_type === "search_user" &&
          storedUser?.user_type === "climate_innovator"
        ) {
          return;
        } else if (
          user_type === "climate_innovator" &&
          storedUser?.user_type === "search_user"
        ) {
          return;
        } else if (
          user_type === "climate_innovator" &&
          storedUser?.user_type === "climate_innovator"
        ) {
          navigate("../dashboard/");
          return;
        } else {
          return;
        }
      } else {
        return;
      }
    });
    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [onAuthStateChanged]);
  const handleSubmit = async () => {
    setLoading(true);

    try {
      setPersistence(
        auth,
        remember ? browserLocalPersistence : browserSessionPersistence
      )
        .then(async () => {
          await signInWithEmailAndPassword(auth, email.toLowerCase(), password)
            .then(async (userCredential) => {
              const docRef = doc(db, "users", userCredential.user.uid);
              const querySnapshot = await getDoc(docRef);
              const mUser = querySnapshot.data();
              localStorage.setItem(
                "currentUser",
                JSON.stringify({
                  user_type: mUser.user_type,
                  ...userCredential.user,
                })
              );

              console.log(userCredential.user);
              console.log("user", mUser);
              if (mUser.blocked) {
                setError("Your account is blocked. Please contact Admin.");
                localStorage.removeItem("currentUser");
                await signOut(auth);
                return;
              }

              // check for user type
              if (user_type !== mUser.user_type) {
                if (user_type === "search_user") {
                  setError(`please sign in as a Climate Innovator`);
                } else {
                  setError(`please sign in as a Search user`);
                }
                localStorage.removeItem("currentUser");
                await signOut(auth);

                return;
              }

              // if (!mUser.email_verified) {
              //   if (userCredential.user.emailVerified) {
              //     const docRef = doc(db, "users", mUser.id);
              //     updateDoc(docRef, {
              //       email_verified: true,
              //     });
              //   } else {
              //     // setError("Please verify your email");
              //     // signOut(auth);
              //     // return;
              //     console.log("email not verified");
              //   }
              // }

              // Save the user's authentication state in local storage

              if (mUser.user_type === "search_user") {
                console.log("search_user");
                navigate("../profile");
                return;
              }
              console.log("climate_innovator");
              // window.location.reload();

              navigate("../dashboard/");
              // dispatch(fetchUser());
            })
            .catch((err) => {
              localStorage.removeItem("currentUser");
              signOut(auth);

              setFirebaseError(true);
              setError(err.message);
              console.log(err.message);
            });
          setLoading(false);
        })
        .catch((error) => {
          // Handle Errors here.
          // const errorCode = error.code;
          setError(error.message);
        });
    } catch (err) {
      localStorage.removeItem("currentUser");
      signOut(auth);

      setError(err.message);
      setFirebaseError(true);
      setLoading(false);
      console.log("signup error", err);
    }
  };
  return (
    <LoginFlexComp>
      {" "}
      {/* left */}
      <LoginFlexLeftComp
        image={{ image1: login, image2: login1, image3: login2 }}
        carousel={true}
      />
      {/* right */}
      <LoginFlexRightComp theme={theme}>
        {/* words */}
        <div className="">
          <p className="text-[24px] md:text-[36px] leading-[36px] md:leading-[54px] font-bold text-center">
            {t("login.welcomeBack")}
          </p>
          <p className="text-sm md:font-normal text-[#ACB5BB] text-center mt-[14px]">
            {t("login.everySearchYou")}
          </p>
        </div>

        {/* login comp */}
        <LoginComp theme={theme} header={t("login.loginInToYourAcc")}>
          {/* input forms */}
          <div className="flex flex-col gap-[24px] mb-[32px]">
            {/* input comp */}
            <InputComp label={t("login.email")} setValue={setEmail} />
            {/* password comp */}
            <PasswordInputComp
              label={t("login.password")}
              setValue={setPassword}
            />
          </div>

          {/* remember me & forgot password */}
          <div className=" flex justify-between mb-[32px] gap-[10px]">
            {/* checkbox */}
            <div className=" flex gap-[8px]">
              <input
                type="checkbox"
                className=""
                onChange={(e) => {
                  console.log("remember-check", e.target.checked);
                  setRemember(e.target.checked);
                  // localStorage.setItem("remember", e.target.checked);
                }}
              ></input>
              <label className="font-semibold">{t("login.remember")}</label>
            </div>

            {/* forgot */}
            <Link to="../forgot-password" className=" cursor-pointer">
              <p className="text-[#58BA59] font-semibold">
                {t("login.forgotPass")}
              </p>
            </Link>
          </div>

          {/* submit button */}
          <FormButtonComp
            label={t("login.button")}
            onSubmit={handleSubmit}
            loading={loading}
          />
          {/* error */}

          {error && firebaseError ? (
            <div className="bg-[#ffb4b4] mt-[10px] py-[10px]">
              {" "}
              <p className="text-center">{error.substring(9)}</p>
            </div>
          ) : (
            error && (
              <div className="bg-[#ffb4b4] mt-[10px] py-[10px]">
                {" "}
                <p className="text-center">{error}</p>
              </div>
            )
          )}

          {/* dont have an account */}
          <div className=" mt-[32px]">
            <p className="text-center text-base font-semibold">
              {t("login.DontHaveAnAccount")}?{"   "}
              <Link to={"../signup"}>
                {" "}
                <i className="text-[#58BA59] not-italic cursor-pointer ml-1">
                  {" "}
                  {t("login.RegisterHere")}
                </i>
              </Link>
            </p>
          </div>
        </LoginComp>
      </LoginFlexRightComp>
    </LoginFlexComp>
  );
};

export default LoginApp;
