import React from "react";
import curvy1 from "../../assests/website/home-back-svg.svg";
import curvy2 from "../../assests/website/search-curve-svg.svg";
import LeafHeader from "../../components/website/LeafHeader";
import PrivacyPolComp from "../../components/website/PrivacyPolComp";
import { useTranslation } from "react-i18next";
import HandleLanguageChange from "../../globals/handleLanguageChange";

const TermsOfService = ({ theme }) => {
  const { t } = useTranslation();
  // handle language change
  HandleLanguageChange();
  return (
    <div
      className={
        theme
          ? "relative  text-[#1a1c1e] mb-[100px]"
          : "relative  text-white mb-[100px]"
      }
    >
      {/* margin p */}
      <div
        className={
          theme
            ? "pt-[80px] md:pt-[100px] pb-[100px] md:pb-[150px]  bg-[#F7F7F7] mb-[70px]"
            : "pt-[80px] md:pt-[100px] pb-[100px] md:pb-[150px]  bg-[#1a1c1e] mb-[70px] "
        }
      >
        {/* absolute */}
        <div className="absolute hidden sm:block left-0 top-[0px] z-20">
          <img src={curvy2} alt=""></img>
        </div>

        {/* absolute */}
        <div className="absolute hidden sm:block right-0 z-20 top-[0px] ">
          <img src={curvy1} alt=""></img>
        </div>

        {/* header */}
        <div className=" max-w-[788px] mx-[24px]  xl:mx-auto  relative ">
          <p className="text-[28px] md:text-[60px] font-extrabold leading-[47px] md:leading-[59px] text-center">
            {t("terms.header")}
          </p>
          <p className="text-sm md:text-lg font-normal leading-[28px] md:leading-[33px] mt-[16px] text-center">
            {t("terms.words")}
          </p>
        </div>
      </div>

      {/* marginp */}
      <div className="max-w-[1200px] mx-[24px] grid grid-cols-1 gap-[54px]  xl:mx-auto  relative">
        {/* comp */}
        <PrivacyPolComp
          header={t("terms.one.header")}
          words={t("terms.one.words")}
        />

        <PrivacyPolComp
          header={t("terms.two.header")}
          words={t("terms.two.words")}
        />

        <PrivacyPolComp
          header={t("terms.three.header")}
          words={t("terms.three.words")}
        />

        <PrivacyPolComp
          header={t("terms.four.header")}
          words={t("terms.four.words")}
        />

        <PrivacyPolComp
          header={t("terms.five.header")}
          words={t("terms.five.words")}
        />

        <PrivacyPolComp
          header={t("terms.six.header")}
          words={t("terms.six.words")}
        />

        <PrivacyPolComp
          header={t("terms.seven.header")}
          words={t("terms.seven.words")}
        />
      </div>
    </div>
  );
};

export default TermsOfService;
