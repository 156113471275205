import React, { useTransition } from "react";
import info from "../../assests/webapp/info-circle.svg";
import see from "../../assests/webapp/see-arrow.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BigWhiteContainer = ({ children, header, link, theme }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div
      className={
        theme
          ? "bg-[#F4F4F7] rounded-[16px] p-[24px]"
          : "bg-white rounded-[16px] p-[24px]"
      }
    >
      {/* heading */}
      <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-y-[15px]">
        {/* left */}
        <div className="flex items-center gap-[6px]">
          <p className="text-lg font-semibold">{header}</p>
          {/* image */}
          <div className="cursor-pointer">
            <img src={info} alt=""></img>
          </div>
        </div>
        {/* right */}
        <div
          className=" flex  items-center gap-[6px] cursor-pointer"
          onClick={() => {
            navigate(link);
          }}
        >
          <p className="font-normal text-[#ACB5BB] text-sm">
            {t("dashboard.seeAll")}
          </p>
          {/* image */}
          <div className="cursor-pointer">
            <img src={see} alt=""></img>
          </div>
        </div>
      </div>

      {/* details */}
      <div className="mt-[24px] flex flex-col gap-[32px]">{children}</div>
    </div>
  );
};

export default BigWhiteContainer;
