import React from "react";
import image from "../../assests/website/web-gallery.png";

const WebsiteProjectGallery = ({ images }) => {
  return (
    <div className="mt-[50px] mb-[100px]">
      {/* gallery */}
      {images.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-[5px] gap-y-[30px]">
          {/* image */}
          {images.map((data, index) => (
            <div className=" cursor-pointer ">
              <img
                className="w-full h-full object-cover"
                src={data}
                alt=""
              ></img>
            </div>
          ))}
        </div>
      ) : (
        <p className="my-[100px]">No images available</p>
      )}
    </div>
  );
};

export default WebsiteProjectGallery;
