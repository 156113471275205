import React, { useEffect, useState } from "react";
import InputComp from "./InputComp";
import CityComp from "./CityComp";
import FormButtonComp from "./FormButtonComp";
import FormButtonTwo from "./FormButtonTwo";
import DateComp from "./DatePickerComp";
import ComponentDate from "./ComponentDate";
import { useDispatch, useSelector } from "react-redux";
// import { fetchUser } from "../../redux/UserSlice";
import { db } from "../../firebase/firebaseConfig";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { Country, State, City } from "country-state-city";
import loader from "../../assests/webapp/loader-svg.gif";
// Import Interfaces`
import { ICountry, IState, ICity } from "country-state-city";

import moment from "moment";
import { use } from "i18next";
import CountryComp from "./CountryComp";
import { toast } from "react-toastify";
import { setData, setLoading } from "../../redux/UserSlice";
import { useTranslation } from "react-i18next";

// import { DatePicker } from "antd";

const PersonalInfo = ({ setNavlink, theme }) => {
  // const [required, setRequired] = useState(true);
  // const [user, setUser] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [dob, setDob] = useState(null);
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(null);
  const [province, setProvince] = useState(null);
  const [error, setError] = useState();
  const [countryArray, setCountryArray] = useState([]);
  const [stateArray, setStateArray] = useState([]);
  const [cityArray, setCityArray] = useState([]);
  const [timestamp, setTimestamp] = useState();
  const [loadingTwo, setLoadingTwo] = useState();
  // const [loading, setLoading] = useState(false);

  const storedUser = JSON.parse(localStorage.getItem("currentUser"));
  // fetching all slices
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.data);
  const loading = useSelector((state) => state.user.loading);
  console.log("user-info", user);
  const { t } = useTranslation();

  useEffect(() => {
    setNavlink(t("appHeader.personalInfo"));
    setTimestamp(moment(user?.d_o_b).format("yyyy-mm-dd"));
  }, []);

  useEffect(() => {
    console.log("setting user");
  }, [user]);

  const fetchUser = async () => {
    dispatch(setLoading(true));
    console.log("enter fetchuser");
    const docRef = doc(db, "users", storedUser.uid);
    try {
      const querySnapshot = await getDoc(docRef);
      // Handle the data or dispatch an action here
      // console.log("snapshot userslice", querySnapshot.data());
      dispatch(setData(querySnapshot.data()));
      dispatch(setLoading(false));
    } catch (error) {
      // Handle the error or show an error message to the user
      dispatch(setLoading(false));
      // console.log("Error fetching document:", error);
    }
  };

  // handleSubmit
  const handleSubmit = async () => {
    setLoadingTwo(true);
    const updatedUser = {
      first_name: firstName ? firstName : user?.first_name,
      last_name: lastName ? lastName : user?.last_name,
      email: email ? email : user?.email,
      d_o_b: dob !== null ? dob?.toString() : user?.d_o_b,
      country: country ? country.name : user?.country,
      city: city ? city.name : user?.city,
      province: province ? province.name : user?.province,
    };
    const docRef = doc(db, "users", user?.id);
    console.log(updatedUser);
    try {
      await updateDoc(docRef, updatedUser);
      toast.success("Settings saved successfully.");
      fetchUser();
      setLoadingTwo(false);

      // window.location.reload();
    } catch (e) {
      setLoadingTwo(false);
      console.log(e);
      toast.error(e);
    }
  };

  useEffect(() => {
    setCountryArray(Country.getAllCountries());
  }, []);

  useEffect(() => {
    // console.log("Entering get states");
    // console.log("country", country);
    setStateArray(State.getStatesOfCountry(country?.isoCode));
  }, [country]);

  useEffect(() => {
    // console.log("Entering get province");
    setCityArray(City.getCitiesOfState(country?.isoCode, province?.isoCode));
  }, [province]);

  // console.log("time from db", dob);
  // console.log("time from db string", dob?.toString());
  // console.log("time from timestamp", timestamp);

  return (
    <div className=" flex flex-col gap-[32px] ">
      {loading ? (
        <div className=" h-[300px] w-full flex justify-center items-center ">
          <div className=" w-[40px] ">
            <img className="w-full" src={loader} alt=""></img>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-[32px] ">
          <div className="flex flex-col gap-[32px] ">
            <InputComp
              label={t("setting.firstName")}
              value={user?.first_name}
              setValue={setFirstName}
            />
            <InputComp
              label={t("setting.lastName")}
              value={user?.last_name}
              setValue={setLastName}
            />
            <InputComp
              label={t("setting.email")}
              value={user?.email}
              setValue={setEmail}
            />
            <ComponentDate
              label={t("setting.DOB")}
              value={dob ? dob : user?.d_o_b}
              setValue={setDob}
              disabled={
                user?.d_o_b === "" || user?.d_o_b === null ? false : true
              }
            />
            {/* <InputComp label={"Date of Birth"} /> */}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-[20px]">
            {/* city comp */}
            <CountryComp
              label={t("setting.country")}
              value={country ? country.name : user?.country}
              setValue={setCountry}
              list={countryArray}
            />
            {/* city comp */}
            <CountryComp
              label={t("setting.province")}
              value={province ? province.name : user?.province}
              setValue={setProvince}
              list={stateArray}
            />
            {/* city comp */}
            <CountryComp
              label={t("setting.city")}
              value={city ? city.name : user?.city}
              setValue={setCity}
              list={cityArray}
            />
          </div>
        </div>
      )}

      {/* line area */}
      <div className="border border-[#EDF1F3]"></div>
      {/* button area */}
      <div className="grid grid-cols-1 sm:grid-cols-4 gap-[24px]">
        {/* cancel */}
        <FormButtonTwo label={t("setting.cancel")} theme={theme} />
        {/* yes */}
        <FormButtonComp
          label={t("setting.save")}
          onSubmit={handleSubmit}
          loading={loadingTwo}
        />
      </div>
    </div>
  );
};

export default PersonalInfo;
