import React, { useState, useEffect } from "react";
import cancelBlack from "../../assests/website/cancel.svg";
import cancelWhite from "../../assests/website/cancel-black.svg";
import loader from "../../assests/webapp/loader-svg.gif";
import { onAuthStateChanged, signOut } from "firebase/auth";
import WebNavComp from "./WebNavComp";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase/firebaseConfig";
import { useTranslation } from "react-i18next";
import LogOutModal from "../webapp/LogOutModal";

const SwitchNavComp = ({ setOpenHam, setSign, setChangeView, theme }) => {
  const { t } = useTranslation();
  const navigate = useNavigate("");
  const [open, setOpen] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  let user_type = localStorage.getItem("user_type");
  const [loading, setLoading] = useState(false);
  // console.log(currentUser);

  useEffect(() => {}, [onAuthStateChanged]);

  return (
    <div className="">
      {/* cancel button */}
      <div className=" flex justify-end mb-[45px]">
        <div
          onClick={() => {
            setOpenHam(false);
          }}
          className="cursor-pointer"
        >
          {theme ? (
            <img src={cancelWhite} alt=""></img>
          ) : (
            <img src={cancelBlack} alt=""></img>
          )}
        </div>
      </div>

      {/* main */}
      <div className="">
        {/* flex */}
        <div className=" flex flex-col">
          {/* comp */}
          <WebNavComp
            label={t("home.header.navigation.home")}
            link={"/"}
            close={setOpenHam}
            theme={theme}
          />
          <WebNavComp
            label={t("home.header.navigation.about")}
            link={"/how-pap-works"}
            close={setOpenHam}
            theme={theme}
          />
          <WebNavComp
            label={t("home.header.navigation.search")}
            link={"/search"}
            close={setOpenHam}
            theme={theme}
          />
          {auth.currentUser && currentUser?.user_type === "search_user" && (
            <WebNavComp
              label={t("home.header.navigation.profile")}
              link={"/profile"}
              close={setOpenHam}
              theme={theme}
            />
          )}

          <WebNavComp
            label={t("home.header.navigation.rewards")}
            link={"/pap-rewards"}
            close={setOpenHam}
            theme={theme}
          />
          <WebNavComp
            label={t("home.header.navigation.settings")}
            link={"/settings"}
            close={setOpenHam}
            theme={theme}
          />
          {/* logout button */}
          {/* if current user is equals to null and it is equals to search user */}
          {auth.currentUser === null ||
          currentUser?.user_type !== "search_user" ? (
            ""
          ) : (
            <div className="flex flex-col gap-[15px] mt-[34px]">
              <div
                onClick={async () => {
                  setOpen(true);
                }}
                className="cursor-pointer border border-[red] rounded-[8px] py-[12px] flex items-center justify-center w-full"
              >
                <p className="text-[14px] font-semibold leading-[20px]">
                  {t("home.header.navigation.logout")}
                </p>
              </div>

              <LogOutModal open={open} setOpen={setOpen} theme={theme} />
            </div>
          )}

          {/* buttons */}
          {auth.currentUser !== null &&
          currentUser?.user_type === "search_user" ? (
            ""
          ) : (
            <div className="flex flex-col gap-[15px] mt-[34px]">
              {/* one */}
              <div
                onClick={() => {
                  setChangeView(true);
                  setSign("Sign in");
                  // navigate("/login");
                }}
                className="cursor-pointer bg-[#0B7026] rounded-[8px] py-[12px] flex items-center justify-center w-full"
              >
                <p
                  className={
                    theme
                      ? "text-white text-[14px] font-semibold leading-[20px]"
                      : "text-[14px] font-semibold leading-[20px]"
                  }
                >
                  {t("home.header.navigation.signin")}
                </p>
              </div>
              {/* two */}
              <div
                onClick={() => {
                  // navigate("/signup");
                  setChangeView(true);
                  setSign("Sign up");
                }}
                className="cursor-pointer border border-[#0B7026] rounded-[8px] py-[12px] flex items-center justify-center w-full"
              >
                <p
                  className={
                    theme
                      ? "text-[14px] text-[#0B7026] font-semibold leading-[20px]"
                      : "text-[14px] font-semibold leading-[20px]"
                  }
                >
                  {t("home.header.navigation.signup")}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SwitchNavComp;
