import React, { useState, useEffect } from "react";

const Typewriter = ({ text, delay }) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [mounted, setMounted] = useState();
  const [mounting, setMounting] = useState(false);
  const [delaying, setDelaying] = useState(false);
  // Typing logic
  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);

      return () => clearTimeout(timeout);
    }
  }, [currentIndex]);

  useEffect(() => {
    setMounting(true);
    console.log("first----------");
  }, []);

  // checking if component has mounted
  useEffect(() => {
    mounting && localStorage.setItem("mount", "false");
    console.log("second----------");
  }, [text]);

  return (
    <>
      {mounting && JSON.parse(localStorage.getItem("mount")) ? (
        <>
          <div dangerouslySetInnerHTML={{ __html: text }} />
          <span class="__blinker"></span>
        </>
      ) : mounting && !JSON.parse(localStorage.getItem("mount")) ? (
        <>
          <div dangerouslySetInnerHTML={{ __html: currentText }} />
          <span class="__blinker"></span>
        </>
      ) : (
        !mounting && (
          <>
            {/* <span class="__blinker"></span> */}
            wanker
          </>
        )
      )}
    </>
  );
};

export default Typewriter;
