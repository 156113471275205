import React from "react";
import logo from "../../assests/website/logo-medium.svg";

const LogoMedium = () => {
  return (
    <div className="">
      <img src={logo} alt=""></img>
    </div>
  );
};

export default LogoMedium;
