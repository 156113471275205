import React from "react";

import logo from "../../assests/website/footer-logo.svg";
import { useNavigate } from "react-router";

const LogoBig = () => {
  const navigate = useNavigate();
  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div
      className="w-full"
      onClick={() => {
        moveToTop();
        navigate("/");
      }}
    >
      <img className="w-full" src={logo} alt=""></img>
    </div>
  );
};

export default LogoBig;
