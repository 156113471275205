import React, { useState } from "react";
// assests
import image from "../../assests/website/create-acc-pic.png";
import success from "../../assests/website/success.svg";
import google from "../../assests/website/google-icon.svg";
// components
import LoginFlexComp from "../../components/webapp/LoginFlexComp";
import LoginFlexLeftComp from "../../components/webapp/LoginFlexLeftComp";
import LoginFlexRightComp from "../../components/webapp/LoginFlexRightComp";
import LoginComp from "../../components/webapp/LoginComp";
import InputComp from "../../components/webapp/InputComp";
import PasswordInputComp from "../../components/webapp/PasswordInputComp";
import FormButtonComp from "../../components/webapp/FormButtonComp";
import SignInWithComp from "../../components/webapp/SignInWithComp";
import CreateAccComp from "../../components/webapp/CreateAccComp";
import CreateAccSuccessComp from "../../components/webapp/CreateAccSuccessComp";

const CreateAcc = ({ theme }) => {
  const [success, setSuccess] = useState(false);
  return (
    <LoginFlexComp>
      <LoginFlexLeftComp image={image} />
      <LoginFlexRightComp theme={theme}>
        {success ? (
          <CreateAccSuccessComp
            words={" Check your mail to verify account"}
            button={"yes"}
            theme={theme}
          />
        ) : (
          <CreateAccComp setSuccess={setSuccess} theme={theme} />
        )}
      </LoginFlexRightComp>
    </LoginFlexComp>
  );
};

export default CreateAcc;
