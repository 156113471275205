import { Divider, Radio, Table } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";

import eye from "../../assests/webapp/eye.svg";
import edit from "../../assests/webapp/edit.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../../firebase/firebaseConfig";
import { addProject } from "../../redux/ProjectSlice";
import {
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import TableMobile from "./TableMobile";
import { addCommas } from "separate-comma-numbers";
import { use } from "i18next";
import { useTranslation } from "react-i18next";

const AntTableComp = ({ value }) => {
  const [filtered, setFiltered] = useState();
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.project.data);
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const { t } = useTranslation();
  // console.log("current-user", currentUser);

  // fetching the projects from the database
  const fetchPost = async (user_id) => {
    const querySnapshot = query(
      collection(db, "projects"),
      where("user_id", "==", currentUser.uid),
      orderBy("timestamp", "desc")
    );

    onSnapshot(querySnapshot, (snapshot) => {
      const newData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      dispatch(addProject(newData));
      // console.log("new data", newData);
    });
  };

  useEffect(() => {
    fetchPost();
  }, []);

  useEffect(() => {
    setFiltered(projects);
  }, [projects]);

  console.log("value", value);

  // filter projects
  const handleFilter = () => {
    const searchFiltered = projects?.filter((data) => {
      const lowerCaseValue = value.toLowerCase();
      return data.project_name.toLowerCase().indexOf(lowerCaseValue) !== -1;
    });

    console.log("filter", searchFiltered);
    setFiltered(searchFiltered);
  };

  useEffect(() => {
    handleFilter();
  }, [value]);

  console.log("projects2", projects);
  const navigate = useNavigate();
  const dataSource = [];

  const columns = [
    {
      title: "#",
      dataIndex: "",
      key: "Name",
      render: (row, col, _) => (
        <>
          {/* {console.log("row", row)}, */}
          {console.log("col", _)}
          <p className="">{_ + 1}</p>
        </>
      ),
    },
    {
      title: t("projectTable.projectName"),
      dataIndex: "project_name",
      key: "age",
    },
    {
      title: t("projectTable.creationDate"),
      dataIndex: "",
      key: "",
      render: ({ created_at }) => (
        <div>
          <p className="">{moment(created_at).format("DD-MM-YYYY")}</p>
          <p className="font-medium text-[#ACB5BB]">
            {moment(created_at).format("h:mm A")}
            {/* {moment(created_at, "hh:mm").format("A")} */}
          </p>
        </div>
      ),
    },
    {
      title: t("projectTable.coinsCollected"),
      dataIndex: "",
      key: "",
      render: ({ coins_collected, project_goal }) => (
        <div>
          <p className="">{`${coins_collected}(${
            (coins_collected / project_goal) * 100
          }%)`}</p>
          <p className="font-medium text-[#ACB5BB]">
            {addCommas(project_goal)}
          </p>
        </div>
      ),
    },

    {
      title: t("projectTable.support"),
      dataIndex: "total_support",
      key: "",
    },
    {
      title: t("projectTable.status"),
      dataIndex: "project_status",
      key: "",
      render: (project_status) => (
        <>
          <div
            className={
              project_status === "approved"
                ? "py-[6px] px-[12px] w-fit rounded-[4px] bg-[#ECF8F0] text-[#1C8C6E]"
                : project_status === "pending"
                ? "py-[6px] w-fit px-[12px] rounded-[4px] bg-[#FBF4E4] text-[#FBBC04]"
                : "py-[6px] w-fit px-[12px] rounded-[4px] bg-[#FFEFEF] text-[#FF0319]"
            }
          >
            <p className="text-sm font-bold">{project_status}</p>
          </div>
        </>
      ),
    },
    {
      title: t("projectTable.action"),
      dataIndex: "",
      key: "",
      render: (row) => (
        <div className="flex gap-[20px]">
          {/* hide */}
          <div
            className="cursor-pointer "
            onClick={() => {
              navigate(`../details/${row.id}`);
            }}
          >
            <img src={eye} alt=""></img>
          </div>
          {/* edit */}
          <div
            className="cursor-pointer "
            onClick={() => {
              navigate(`../edit/${row.id}`);
            }}
          >
            <img src={edit} alt=""></img>
          </div>
        </div>
      ),
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    // getCheckboxProps: (record) => ({
    //   disabled: record.name === "Disabled User",
    //   // Column configuration not to be checked
    //   name: record.name,
    // }),
  };
  return (
    <div>
      {/* mobile screens */}
      <div className="lg:hidden ">
        <TableMobile dataSource={filtered} />
      </div>
      {/* large screens */}
      <div className="hidden lg:block">
        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          pagination={{
            showSizeChanger: true,
            total: projects.length,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          dataSource={filtered}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default AntTableComp;
