import React from "react";
import LogoBig from "../website/LogoBig";
import LogoMedium from "../website/LogoMedium";
import { useNavigate } from "react-router-dom";

const LoginFlexRightComp = ({ children, theme }) => {
  const navigate = useNavigate();
  return (
    <div
      className={
        theme
          ? " basis-1/2 bg-[white] text-[#1a1c1e] px-[12px] md:px-[30px] lg:px-[85px]  py-[52px] md:py-[120px] overflow-auto"
          : " basis-1/2 bg-[#1a1c1e] text-[white] px-[12px] md:px-[30px] lg:px-[85px]  py-[52px] md:py-[120px] overflow-auto"
      }
    >
      <div className="">
        {/* logo */}
        <div
          onClick={() => {
            navigate("/");
          }}
          className="cursor-pointer w-fit m-auto mb-[15px] md:mb-[24px]"
        >
          <LogoMedium />
        </div>
        {children}

        {/* copy right */}
        <div className="mt-[48px] md:mt-[58px]">
          <p className="text-center text-sm font-normal text-[#888888]">
            © 2023 People and Planet. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginFlexRightComp;
