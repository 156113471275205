import React from "react";
import tree from "../../assests/webapp/treeSearch.svg";
import { addCommas } from "separate-comma-numbers";

const ProjectCardComp = ({ wordL, numberL, wordR, numberR }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 items-center mx-auto bg-[#A2A6AA] p-[17px] text-white w-full sm:w-fit rounded-[8px]">
      {/* left */}
      <div className="pr-[17px] ">
        {/* up */}
        <div className="flex items-center gap-[4px] mb-[12px] w-fit mx-auto sm:mx-0 ">
          <div className=" w-[15px] h-[17px] ">
            <img
              src={tree}
              alt=""
              className="w-full h-full object-cover "
            ></img>
          </div>
          <p className="text-xs font-semibold">{wordL}</p>
        </div>
        {/* down */}
        <div className="">
          <p className="text-[26px] leading-[39px] font-bold text-center sm:text-start">
            {numberL}
          </p>
        </div>
      </div>

      {/* right */}
      <div className="border-t sm:border-l-0 sm:border-l sm:border-t-0 border-[#8D8D8D] pt-[17px] pl-0 sm:pt-0 sm:pl-[17px] ">
        {/* up */}
        <div className="flex items-center gap-[4px] mb-[12px] w-fit mx-auto sm:mx-0">
          <div className=" w-[15px] h-[17px]">
            <img
              src={tree}
              alt=""
              className="w-full h-full object-cover "
            ></img>
          </div>
          <p className="text-xs font-semibold">{wordR}</p>
        </div>
        {/* down */}
        <div className="">
          <p className="text-[26px] leading-[39px] font-bold text-center sm:text-start">
            {addCommas(numberR)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProjectCardComp;
