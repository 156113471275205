import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
} from "firebase/firestore";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { db } from "../../firebase/firebaseConfig";
import isValidEmail from "../../globals/isEmailValid";
import { toast } from "react-toastify";
import FormButtonComp from "../webapp/FormButtonComp";

const SubscribeBtn = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    // TODO: Check if email is a valid email address
    if (!isValidEmail(email)) {
      setLoading(false);
      setError("Please input a valid mail");
      toast.error("Please input a valid mail");
      setEmail("");

      return;
    }

    // TODO check for duplicate mails in db
    // const docRef = doc(db, "subscribers");

    try {
      const q = query(collection(db, "subscribers"));

      const querySnapshot = await getDocs(q);

      let emailExists = false;

      querySnapshot.forEach((doc) => {
        if (doc.data().email === email) {
          emailExists = true;
          toast.error("Email already exists");
          setLoading(false);
          return; // This will exit the forEach loop early
        }
      });

      if (emailExists) {
        return; // Exit the function if the email exists
      }
      // Create a new document reference in the "subscribers" collection
      const docRef = doc(collection(db, "subscribers"));

      // Data to be added to the document
      const emailSubmitted = {
        email: email, // Assuming email is a variable containing the email address
      };

      // Set the document data in Firestore
      await setDoc(docRef, emailSubmitted);
      toast.success("Email added successfully");

      console.log("Email added successfully.");
      setLoading(false);
      setEmail("");
    } catch (e) {
      console.error("Error adding email:", e);
      toast.error("Error adding email");
      setEmail("");
      setLoading(false);
    }
  };
  return (
    <div className=" mt-[21px] xl:mt-[60px] w-full  sm:w-fit">
      {" "}
      <p className="text-sm font-normal mb-[8px] text-start xl:text-end">
        {t("footer.subscribe.words")}
      </p>
      <div className="flex gap-[8px]">
        {/* input */}
        <input
          placeholder={t("footer.subscribe.placeholder")}
          type="email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          className="border border-[#D5DAE1] dark-theme-input rounded-[8px] shadow-[0px 1px 2px #EF3D5B] w-full sm:w-[267px] shadow-sm py-[11px] px-[14px]"
        ></input>

        {/* button */}
        {/* <div
          className="bg-[green] py-[11px] px-[18px] rounded-[8px]"
          onClick={handleSubmit}
        >
          <p className="text-base font-medium text-[white] ">
            {t("footer.subscribe.button")}
          </p>

        </div> */}
        <FormButtonComp
          label={t("footer.subscribe.button")}
          onSubmit={handleSubmit}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default SubscribeBtn;
