import React from "react";

const WebsiteProjectStory = ({ theme, story }) => {
  function insertParagraphs(inputString) {
    const sentences = inputString.split(".");
    const result = [];

    for (let i = 0; i < sentences.length; i++) {
      if (i % 2 === 0) {
        result.push("<p>");
      }
      result.push(sentences[i].trim());
      if (i % 2 === 0) {
        result.push("</p>");
      }
    }

    return <div>{result.join("")}</div>;
  }
  return (
    <div
      className={
        theme
          ? "mb-[100px] text-[#1A1C1E] mt-[50px]"
          : "mb-[100px] text-white mt-[50px]"
      }
    >
      <p className="font-normal text-sm  leading-[28px] md:text-base ">
        {story}
      </p>
    </div>
  );
};

export default WebsiteProjectStory;
