import React, { useEffect } from "react";
import WebsiteButton from "./WebsiteButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { useSelector } from "react-redux";

const CarouselHomeLeft = ({ number, header, step }) => {
  const lang = useSelector((state) => state.language.langState);
  const { t } = useTranslation();

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };

  const navigate = useNavigate("");
  return (
    <div className="  ">
      <div className="write-current">
        <p className="text-[#4E4E4E] text-[131px] md:text-[160px] text-center lg:text-start font-extrabold leading-[85px] md:leading-[100px] mb-[45px]">
          {" "}
          {number}
        </p>

        <div
          className={
            number == 3 ? "mb-[60px] md:h-[180px] " : " mb-[60px] md:h-[110px]"
          }
        >
          <p className="text-[28px] md:text-[36px] text-center lg:text-start leading-[42px] font-medium">
            {header}
          </p>
          <p className="text-sm text-center lg:text-start md:text-[18px] font-normal leading-[28px] md:leading-[33px]">
            {step}
          </p>
        </div>
      </div>

      {/* button */}
      <div className="w-full sm:w-[310px] mx-auto lg:mx-0 cursor-pointer">
        <div
          onClick={() => {
            navigate("/how-pap-works/");
            moveToTop();
          }}
        >
          <WebsiteButton
            label={`${t("home.howPapWorksHome.carousel.button")}`}
          />
        </div>
      </div>
    </div>
  );
};

export default CarouselHomeLeft;
