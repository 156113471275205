import React, { useState } from "react";
import arrow from "../../assests/website/back_nav.svg";

const SearchNavigation = ({ currentIndex, nextSearch, theme }) => {
  const [pages, setPages] = useState(5);
  return (
    <div className=" flex justify-between items-center mt-[120px] mb-[50px]">
      {/* left arrow */}
      <div
        className=" cursor-pointer "
        onClick={() => {
          if (currentIndex === 1) {
            return;
          }
          nextSearch(currentIndex - 1);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
        >
          <path
            stroke={theme ? "#1a1c1e" : "#fff"}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="3"
            d="M15.09 4.08 8.57 10.6c-.77.77-.77 2.03 0 2.8l6.52 6.52"
          />
        </svg>
      </div>

      <div className="flex gap-[20px] items-center ">
        {[1, 2, 3, 4, 5].map((val, index) => (
          <p
            className={
              currentIndex === val
                ? "border-b-4 border-[green] text-lg  text-[green] p-[5px] font-bold cursor-pointer"
                : "p-[5px] text-lg font-bold cursor-pointer"
            }
            onClick={() => {
              nextSearch(val);
            }}
          >
            {val}
          </p>
        ))}
      </div>

      {/* right arrow */}
      <div
        className="cursor-pointer rotate-180 "
        onClick={() => {
          if (currentIndex === 5) {
            return;
          }
          nextSearch(currentIndex + 1);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
        >
          <path
            stroke={theme ? "#1a1c1e" : "#fff"}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="3"
            d="M15.09 4.08 8.57 10.6c-.77.77-.77 2.03 0 2.8l6.52 6.52"
          />
        </svg>
      </div>
    </div>
  );
};

export default SearchNavigation;
