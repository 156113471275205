import React, { useState } from "react";
import curvy1 from "../../assests/website/home-back-svg.svg";
import curvy2 from "../../assests/website/search-curve-svg.svg";
import WebsiteButton from "../../components/website/WebsiteButton";
import LeafHeader from "../../components/website/LeafHeader";
import { toast } from "react-toastify";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import WebsiteInputComp from "../../components/website/WebsiteInputComp";

const DeleteData = ({ theme }) => {
    const [mailAddress, setMailAddress] = useState("");

    return (
        <div
            className={theme ? " relative text-[#1A1C1E]" : " relative text-[white]"}
        >
            {/* absolute */}
            <div className="hidden md:block absolute left-0 top-0 z-20">
                <img src={curvy2} alt=""></img>
            </div>

            {/* absolute */}
            <div className="hidden md:block absolute right-0 z-20 top-[150px]">
                <img src={curvy1} alt=""></img>
            </div>

            {/* section 2*/}
            <div className=" mt-[100px] max-w-[1200px] mx-[24px]  xl:mx-auto  relative mb-[100px] md:mb-[150px]">
                {/* header */}
                <div className="w-fit mx-auto mb-[8px]">
                    <LeafHeader label={"Delete Your Data"} />
                </div>

                {/* body */}
                <div className="max-w-[1000px] mx-auto flex flex-col gap-[15px]">
                    <p className="text-sm md:text-lg leading-[28px] text-center font-normal">
                        By deleting your account, you won't have access to the PAP resources anymore. <br />Your coins will also be affected.<br /><br />
                    </p>

                    {/* your email */}
                    <WebsiteInputComp
                        label={"Your PAP Email Address"}
                        theme={theme}
                        setValue={setMailAddress}
                    />
                </div><br /><br />

                {/* button */}
                <div
                    onClick={async () => {
                        if (mailAddress.trim() === "") {
                            return;
                        }
                        const colRef = collection(db, "account-deletion");
                        await addDoc(colRef, {
                            "email": mailAddress.toLowerCase().trim(),
                        });
                        toast.success("Account will be deleted in the next 24 hrs.");
                        setTimeout(() => {
                            window.location.href = "/";
                        }, 5000);
                    }}
                    className="w-full sm:w-[245px] mx-auto cursor-pointer"
                >
                    <WebsiteButton label={"Delete"} />
                </div>
            </div>
        </div>
    );
};

export default DeleteData;