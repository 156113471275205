import React, { useEffect, useState } from "react";

import image from "../../assests/webapp/project-image.png";
import circle from "../../assests/webapp/cirlcle-line-ball.svg";
import arrow from "../../assests/webapp/arrow-back-btn.svg";
import edit from "../../assests/webapp/edit.svg";

// components
import FormButtonComp from "./FormButtonComp";
import Socials from "../website/Socials";
import ProjectCardComp from "./ProjectCardComp";
import ProjectsNavComp from "./ProjectsNavComp";
import ProjectStory from "./ProjectStory";
import ProjectGallery from "./ProjectGallery";
import DetailsNavComp from "./DetailsNavComp";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { db } from "../../firebase/firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { addCommas } from "separate-comma-numbers";
import moveToTop from "../../globals/moveToTop";
import { useTranslation } from "react-i18next";

const ProjectDetails = () => {
  const params = useParams();
  const { t } = useTranslation();
  const [detailsNav, setDetailsNav] = useState(t("projectDet.story"));
  const projects = useSelector((state) => state.project.data);
  const [project, setProject] = useState();
  const lang = JSON.parse(localStorage.getItem("language"));

  const navigate = useNavigate();

  const fetchProjectById = async () => {
    const docRef = doc(db, "projects", params.id);
    try {
      const querySnapshot = await getDoc(docRef);
      // Handle the data or dispatch an action here
      setProject(querySnapshot.data());
    } catch (error) {
      // Handle the error or show an error message to the user
      console.log("Error fetching document:", error);
    }
  };

  useEffect(() => {
    fetchProjectById();
  }, []);
  return (
    <div className=" bg-[white] rounded-[16px] p-[38px]">
      {/* back button */}
      <div
        className=" flex gap-[20px] items-center text-base font-semibold"
        onClick={() => {
          navigate(-1);
        }}
      >
        <div className=" cursor-pointer">
          <img src={arrow} alt=""></img>
        </div>
        <p>{t("projectDet.projectsDets")}</p>
      </div>

      {/* image */}
      <div className=" w-fit mx-auto relative mt-[30px]">
        {/* circle ball */}
        <div className=" absolute top-[-30px] left-[-30px]">
          <img src={circle} alt=""></img>
        </div>

        {/* main image */}
        <div className="h-[250px] w-[250px]">
          {project?.company_logo && project?.company_logo.length > 0 ? (
            <img
              className="w-full h-full object-cover"
              src={project?.company_logo}
              alt=""
            ></img>
          ) : project?.project_picture &&
            project?.project_picture.length > 0 ? (
            <img
              className="w-full h-full object-cover"
              src={project[0]?.project_picture}
              alt=""
            ></img>
          ) : (
            <div className="h-full w-full bg-[gray]"></div>
          )}
        </div>
        {/* tag */}
        <div className=" absolute top-[30px] right-[30px] p-[5px] w-fit bg-[#2CD85D] rounded-[8px] text-white font-bold text-[15px] leading-[22px]">
          <p>Nigeria</p>
        </div>
      </div>

      {/* words paragraphs */}
      <div className=" my-[15px] w-fit mx-auto text-center flex flex-col gap-[10px]">
        <p className="font-bold text-[18px]">{t("projectDet.projectTitle")}</p>
        <p className="font-semibold text-[28px]  md:text-[43px] leading-[41px]">
          {project?.project_name}
        </p>
        <p className="font-semibold text-[18px] leading-[27px]">
          {project?.fullname}
        </p>
        <p className="font-semibold text-sm text-[#C2B484]">
          {t("projectDet.projectOwner")}
        </p>
      </div>

      {/* card */}
      <ProjectCardComp
        wordL={t("projectDet.projectCoins")}
        numberL={project?.coins_collected}
        wordR={t("projectDet.projectGoal")}
        numberR={project?.project_goal}
      />

      <div
        className="flex items-center gap-[10px] cursor-pointer w-fit mx-auto my-[40px]"
        onClick={() => {
          navigate(`../edit/${project.id}`);
          moveToTop();
        }}
      >
        <p className="text-base">{t("projectDet.editProjects")}</p>
        <div className="w-[24px] h-[24px]">
          <img className="w-full h-full object-fit" src={edit} alt=""></img>
        </div>
      </div>

      {/* button */}
      {/* <div className=" my-[15px] w-full sm:w-[265px] mx-auto">
        <FormButtonComp label={"share"} />
      </div> */}

      {/* socials */}
      {/* <div className=" mx-auto w-fit">
        <Socials />
      </div> */}

      {/* ************** main **************/}
      <div className=" mt-[30px]">
        {/* header nav */}
        <div className=" flex">
          {/* nav button */}
          {detailsNav === t("projectDet.story") ? (
            <DetailsNavComp
              label={t("projectDet.story")}
              setDetailsNav={setDetailsNav}
              isActive={true}
              color={"green"}
            />
          ) : (
            <DetailsNavComp
              label={t("projectDet.story")}
              setDetailsNav={setDetailsNav}
            />
          )}
          {detailsNav === t("projectDet.gallery") ? (
            <DetailsNavComp
              label={t("projectDet.gallery")}
              setDetailsNav={setDetailsNav}
              isActive={true}
              color={"green"}
            />
          ) : (
            <DetailsNavComp
              label={t("projectDet.gallery")}
              setDetailsNav={setDetailsNav}
            />
          )}
        </div>

        {/* main */}
        <div className=" mt-[30px]">
          {detailsNav === t("projectDet.story") ? (
            <ProjectStory
              details={
                lang?.value === "en"
                  ? project?.project_description_translated.en
                  : lang?.value === "fr"
                  ? project?.project_description_translated.fr
                  : lang?.value === "ge"
                  ? project?.project_description_translated.de
                  : lang?.value === "ch"
                  ? project?.project_description_translated.zh
                  : project?.project_description_translated.en
              }
            />
          ) : (
            <ProjectGallery images={project?.project_picture} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
