import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { auth } from "./firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";

const ProtectedRoute = ({ children }) => {
  const [authChecked, setAuthChecked] = useState(false);
  const navigate = useNavigate();
  // console.log("auth", auth);
  const storedUser = localStorage.getItem("currentUser");
  const user = JSON.parse(storedUser);
  // console.log("current-user", storedUser);
  // console.log("onAuthChange", onAuthStateChanged);

  useEffect(() => {
    // Use Firebase's onAuthStateChanged to listen for authentication state changes
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // User is authenticated
        setAuthChecked(authUser);
      } else {
        // User is not authenticated, navigate to login or another route
        // navigate("/login"); //TODO: Redirect to login page or any other route?
        navigate("/login");
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [navigate]);

  if (user?.user_type !== "climate_innovator") {
    return <Navigate to={"/login"} />;
  }
  return children;
};

export default ProtectedRoute;
