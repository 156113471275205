import React from "react";

const Tags = ({ label, active, setTag }) => {
  return (
    <div
      onClick={() => {
        setTag(label);
      }}
      className={
        active
          ? "px-[9px] py-[7px] bg-[#137C42] text-[white] cursor-pointer rounded-[8px] w-fit"
          : "px-[9px] py-[7px] bg-[#EBEBEB] text-[#888888] cursor-pointer rounded-[8px] w-fit"
      }
    >
      <p className="font-bold text-[12px]  ">{label}</p>
    </div>
  );
};

export default Tags;
