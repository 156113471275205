import React from "react";

const ProjectMainContainer = ({ children }) => {
  return (
    <div className="relative grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-[80px] md:mt-[100px] gap-y-[100px] sm:gap-x-[30px]  lg:gap-x-[50px]">
      {children}
    </div>
  );
};

export default ProjectMainContainer;
