import React, { useEffect } from "react";
import image from "../../assests/website/cleanenergy/1.jpg";
import image1 from "../../assests/website/hero-image.png";
import image2 from "../../assests/website/how-pap-works-img.png";
import image3 from "../../assests/website/maps-home.png";
import SearchImageComp from "./SearchImageComp";
const AllImages = ({ theme, images }) => {
  useEffect(() => {
    localStorage.setItem("mount", "true");
  }, []);
  // console.log("images", images);
  return (
    <div
      className={
        theme
          ? "z-20 relative mb-[100px] text-[#1a1c1e]"
          : "z-20 relative mb-[100px] text-white"
      }
    >
      {/* header and filter part if needed */}
      <div></div>
      {/* main */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-[10px]">
        {/* image comp */}
        {images.map(
          (image, index) =>
            image !== undefined && (
              <SearchImageComp image={image} theme={theme} />
            )
        )}
        {/* <SearchImageComp image={image} theme={theme} />
        <SearchImageComp image={image1} theme={theme} />
        <SearchImageComp image={image} theme={theme} />
        <SearchImageComp image={image1} theme={theme} />
        <SearchImageComp image={image} theme={theme} />
        <SearchImageComp image={image1} theme={theme} />
        <SearchImageComp image={image2} theme={theme} />
        <SearchImageComp image={image3} theme={theme} />
        <SearchImageComp image={image} theme={theme} />
        <SearchImageComp image={image1} theme={theme} />
        <SearchImageComp image={image2} theme={theme} />
        <SearchImageComp image={image3} theme={theme} /> */}
      </div>
    </div>
  );
};

export default AllImages;
