import React, { useEffect } from "react";
import i18n from "i18next";

const HandleLanguageChange = () => {
  const lang = JSON.parse(localStorage.getItem("language"));

  useEffect(() => {
    i18n.changeLanguage(lang?.value);
  }, []);
};

export default HandleLanguageChange;
