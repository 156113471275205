import React, { useEffect, useRef, useState } from "react";
import bell from "../../assests/webapp/bell.svg";
import HiddenNotification from "./HiddenNotification";
import { db } from "../../firebase/firebaseConfig";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import BellComp from "./BellComp";

const BellNotification = ({ theme }) => {
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState();
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const ref = useRef();
  const ref1 = useRef();

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    // console.log("ref", ref);
    // console.log("ref1", ref1);
    // console.log("ref-current", ref?.current);
    // console.log("ref1-current", ref1?.current);
    function handleClickOutside(event) {
      if (
        ref?.current &&
        !ref?.current.contains(event.target) &&
        !ref1?.current.contains(event.target)
      ) {
        console.log("1st condition");
        console.log("open", open);
        setOpen(false);
      } else if (
        ref?.current &&
        !ref1?.current.contains(event.target) &&
        !ref1.current
      ) {
        console.log("2nd condition");
        console.log("open", open);
        setOpen(false);
      } else {
        console.log("else condition");
      }
    }
    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);

  // fetching notification
  const fetchNotification = async () => {
    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, "notifications"),
          where("user_id", "==", currentUser.uid),
          orderBy("timestamp", "desc")
        )
      );
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      // return
      console.log("notification-newdata", newData);

      setNotification(newData);
    } catch (error) {
      console.log("Error fetching document:", error);
    }
  };

  useEffect(() => {
    // fetchPost();
    fetchNotification();
  }, []);

  // useOutsideAlerter();
  return (
    <div className="md:relative">
      {/* bell */}
      <div
        className="relative"
        ref={ref1}
        onClick={() => {
          open ? setOpen(false) : setOpen(true);
        }}
      >
        <div
          className={
            theme
              ? "rounded-full  p-[12px] bg-[white] cursor-pointer"
              : "rounded-full  p-[12px] dark-theme-comp-bg cursor-pointer"
          }
        >
          <BellComp theme={theme} />
        </div>
        {/* green dot */}
        {/* <div className="bg-[#58BA59] w-[8px] h-[8px] rounded-[100%] absolute top-[5px] right-[2px]"></div> */}
      </div>

      {/* hidden notification */}

      {open && (
        <HiddenNotification setOpen={setOpen} reff={ref} data={notification} />
      )}
    </div>
  );
};

export default BellNotification;
