import React, { useState } from "react";
// assests
import nigeria from "../../assests/webapp/Nigeria.svg";
// components
import InputComp from "./InputComp";

import CityComp from "./CityComp";
import UploadComp from "./UploadComp";
import CountryComp from "./CountryComp";
import PhoneNumberComp from "./PhoneNumberComp";
import FormButtonComp from "./FormButtonComp";
import { useDispatch, useSelector } from "react-redux";
import { addProject } from "../../redux/ProjectSlice";
import { useTranslation } from "react-i18next";

const ProjectCreateOne = ({
  setPage,
  setFacebook,
  setFullName,
  setIncorporated,
  setLinkedIn,
  setPhone,
  setProjectName,
  setRegion,
  setInstagram,
  setCompanyLogo,
  setCompanyName,
  setProjectGoal,
  fullName,
  companyName,
  projectName,
  projectGoal,
  phone,
  facebook,
  instagram,
  linkedIn,
  incorporated,
  selected,
  setSelected,
  project,
}) => {
  // dispatch
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [value, setValue] = useState();
  const { t } = useTranslation();

  const handleSubmit = () => {
    if (project) {
      setPage(2);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    if (
      fullName.trim() === "" ||
      phone?.trim() === "" ||
      projectGoal.trim() === "" ||
      projectName.trim() === "" ||
      companyName.trim() === "" ||
      selected === null
    ) {
      setError(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
      // setPage(2);
    } else {
      setPage(2);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }

    // }
  };

  console.log("phone", phone);
  console.log("country", selected);
  return (
    <>
      {" "}
      {/* 1st row */}
      <div className=" grid grid-cols-1 sm:grid-cols-2 gap-[32px] items-center">
        <InputComp
          label={t("projectCreate.applicantName")}
          required={true}
          value={project ? project.fullname : fullName}
          setValue={setFullName}
          type={"text"}
          error={fullName.trim() === "" && error}
        />
        <InputComp
          label={t("projectCreate.nameOfProject")}
          required={true}
          value={project ? project.project_name : projectName}
          setValue={setProjectName}
          type={"text"}
          error={projectName.trim() === "" && error}
        />
      </div>
      {/* 2nd row */}
      <div className=" grid grid-cols-1 sm:grid-cols-2 gap-[32px] items-center">
        <InputComp
          label={t("projectCreate.companyName")}
          required={true}
          value={project ? project.company_name : companyName}
          setValue={setCompanyName}
          type={"text"}
          error={companyName.trim() === "" && error}
        />
        <InputComp
          label={t("projectCreate.projectGoal")}
          required={true}
          value={project ? project.project_goal : projectGoal}
          setValue={setProjectGoal}
          type={"number"}
          min={"1"}
          error={projectGoal.trim() === "" && error}
        />
      </div>
      {/* 3rd row */}
      <div>
        <UploadComp
          label={t("projectCreate.companyLogo")}
          setImageUrl={setCompanyLogo}
          storeUrlPath={"company_logo_files"}
        />
      </div>
      {/* 4th row */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-[32px]  items-center">
        {/* country-region */}
        <CountryComp
          label={t("projectCreate.country")}
          required={true}
          image={nigeria}
          selected={
            project
              ? {
                  countryCode: project.country_code,
                  label: project.country_of_application,
                }
              : selected
          }
          setSelected={setSelected}
          error={selected === null && error}
          country={true}
          disabled={project ? true : false}
        />

        {/* phone number comp */}
        <PhoneNumberComp
          label={t("projectCreate.phoneNumber")}
          required={true}
          setValue={setPhone}
          phone={project ? project.phone_number : phone}
          error={phone?.trim() === "" && error}
        />
      </div>
      {/* 5th row */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-[32px] items-center">
        <InputComp
          label={t("projectCreate.facebook")}
          value={project ? project.facebook : facebook}
          setValue={setFacebook}
          type={"text"}
        />
        <InputComp
          label={t("projectCreate.instagram")}
          value={project ? project.instagram : instagram}
          setValue={setInstagram}
          type={"text"}
        />
      </div>
      {/* 6th row */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-[32px] items-center">
        <InputComp
          label={t("projectCreate.linkedIn")}
          value={project ? project.linkedin : linkedIn}
          setValue={setLinkedIn}
          type={"text"}
        />
        <CityComp
          label={t("projectCreate.incorporated.question")}
          value={project ? project.incorporate : incorporated}
          setValue={setIncorporated}
          list={[
            t("projectCreate.incorporated.yes"),
            t("projectCreate.incorporated.no"),
          ]}
        />
      </div>
      {/* submit button */}
      <div className="mt-[50px] w-full sm:w-[80%] md:w-[416px] mx-auto ">
        <FormButtonComp
          label={t("projectCreate.continue")}
          onSubmit={handleSubmit}
        />
      </div>
    </>
  );
};

export default ProjectCreateOne;
