import React, { useEffect, useState } from "react";
// assests
import sdg1 from "../../assests/webapp/sdg-one.svg";
import sdg2 from "../../assests/webapp/sdg-two.svg";
import sdg3 from "../../assests/webapp/sdg-three.svg";
import sdg4 from "../../assests/webapp/sdg-four.svg";
import sdg5 from "../../assests/webapp/sdg-five.svg";
import sdg6 from "../../assests/webapp/sdg-six.svg";
import sdg7 from "../../assests/webapp/sdg-seven.svg";
import sdg8 from "../../assests/webapp/sdg-eight.svg";
import sdg9 from "../../assests/webapp/sdg-nine.svg";
import sdg10 from "../../assests/webapp/sdg.ten.svg";
import sdg11 from "../../assests/webapp/sdg-eleven.svg";
import sdg12 from "../../assests/webapp/sdg-twelve.svg";
import sdg13 from "../../assests/webapp/sdg.thirteen.svg";
import sdg14 from "../../assests/webapp/sdg-fouteen.svg";
import sdg15 from "../../assests/webapp/sdg-fifteen.svg";
import sdg16 from "../../assests/webapp/sdg-sixteen.svg";
import sdg17 from "../../assests/webapp/sdg-seventeen.svg";

// components
import UploadComp from "./UploadComp";
import InputComp from "./InputComp";
import CountryComp from "./CountryComp";
import FormButtonComp from "./FormButtonComp";
import CityComp from "./CityComp";
import TextAreaComp from "./TextAreaComp";
import {
  addDoc,
  collection,
  setDoc,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ProjectCreateTwo = ({
  setPage,
  fullName,
  projectName,
  companyLogo,
  facebook,
  instagram,
  linkedIn,
  region,
  phone,
  incorporated,
  companyName,
  projectGoal,
  project,
}) => {
  // const projects = useSelector((state) => state.project.data);
  // console.log("projects", projects);

  // dates
  const date = new Date();
  console.log("date", date);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  // form states
  const [projectTitle, setProjectTitle] = useState("");
  const [projectCategory, setProjectCategory] = useState("");
  const [projectProblem, setProjectProblem] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [SDGs, setSDGs] = useState("");
  const [projectInvestor, setProjectInvestor] = useState("");
  const [CO2, setCO2] = useState("");
  const [imageUrl2, setImageUrl2] = useState([]);
  const [pictures, setPictures] = useState("");
  const [imageArray, setImageArray] = useState(project?.project_picture);
  const { t } = useTranslation();

  // console.log("images", imageUrl2);

  // sdgs list
  const sdgList = [
    {
      name: "No Poverty",
      image: sdg1,
    },
    {
      name: "Zero Hunger",
      image: sdg2,
    },
    {
      name: "Good Health & Well Being",
      image: sdg3,
    },
    {
      name: "Quality education",
      image: sdg4,
    },
    {
      name: "Gender Equality",
      image: sdg5,
    },
    {
      name: "Clean Water Sanitation",
      image: sdg6,
    },
    {
      name: "Affordable and Clean Energy",
      image: sdg7,
    },
    {
      name: "Decent Work and Economic Growth",
      image: sdg8,
    },
    {
      name: "Industry Innovation and Infrastructure",
      image: sdg9,
    },
    {
      name: "Reduced Inequalities",
      image: sdg10,
    },
    {
      name: "Sustainable Cities and Communities",
      image: sdg11,
    },
    {
      name: "Responsible Consumption and Production",
      image: sdg12,
    },
    {
      name: "Climate Action",
      image: sdg13,
    },
    {
      name: "Life Below Water",
      image: sdg14,
    },
    {
      name: "Life on Land",
      image: sdg15,
    },
    {
      name: "Peace, Justice and Strong Intuition",
      image: sdg16,
    },
    {
      name: "Partnership for the Goals",
      image: sdg17,
    },
  ];

  // get userid from local storage
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  // console.log("current-user", currentUser);

  // var imageArray = project?.project_picture;
  // useEffect to push images
  useEffect(() => {
    var addedImages = [];
    if (project?.project_picture && project?.picture_picture?.length > 0) {
      console.log("entering here added >0");
      addedImages = [...imageArray, imageUrl2];
    } else {
      console.log("entering here<0");
      addedImages = imageUrl2;
    }

    setImageArray(addedImages);
  }, [imageUrl2]);
  console.log("projects from db", project);
  console.log("image from db", project?.project_picture);
  console.log("image from form", imageUrl2);
  console.log("combined", imageArray);

  const handleSubmit = async () => {
    if (
      project
        ? false
        : CO2.trim() === "" ||
          projectDescription.trim() === "" ||
          projectProblem.trim() === ""
    ) {
      setError(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      setLoading(true);

      try {
        const projectId = doc(collection(db, "projects")).id;
        if (project) {
          const docRef = doc(db, "projects", project.id);
          const editedProject = {
            fullname: project ? project.fullname : fullName,
            project_name: projectName ? projectName : project.project_name,
            company_name: companyName ? companyName : project.company_name,
            project_goal: projectGoal ? projectGoal : project.project_goal,
            company_logo: companyLogo ? companyLogo : project.company_logo,
            // country_of_application:"",
            phone_number: phone ? phone : project.phone_number,
            facebook: facebook ? facebook : project.facebook,
            instagram: instagram ? instagram : project.instagram,
            linkedin: linkedIn ? linkedIn : project.linkedin,
            incorporate: incorporated ? incorporated : project.incorporate,
            project_name: projectName ? projectName : project.project_name,
            category_of_project: projectCategory
              ? projectCategory
              : project.category_of_project,
            problem_to_solve: projectProblem
              ? projectProblem
              : project.problem_to_solve,
            project_description: projectDescription
              ? projectDescription
              : project.project_description,
            sdg_type: SDGs ? SDGs : project.sdg_type,
            project_investor: projectInvestor
              ? projectInvestor
              : project.project_investor,
            capacity_to_remove_co2: CO2 ? CO2 : project.capacity_to_remove_co2,
            project_picture: imageArray,
          };
          console.log("edited-project", editedProject);
          await updateDoc(docRef, editedProject);
        } else {
          const docRef = doc(db, "projects", projectId);
          const createdProject = {
            id: projectId,
            user_id: currentUser.uid,
            project_name: projectName,
            project_status: "pending",

            project_investor: projectInvestor,
            project_description: projectDescription,
            project_picture: imageUrl2,
            fullname: fullName,
            company_name: companyName,
            company_logo: companyLogo?.[0] ? companyLogo[0] : companyLogo,
            country_of_application: region.label,
            country_code: region.countryCode,
            phone_code: "",
            phone_number: phone,
            facebook: facebook,
            instagram: instagram,
            linkedin: linkedIn,
            incorporate: incorporated,
            category_of_project: projectCategory,
            problem_to_solve: projectProblem,
            sdg_type: SDGs,
            capacity_to_remove_co2: CO2,
            project_goal: projectGoal,
            total_support: 0,
            coins_collected: 0,
            created_at: date.toString(),
            timestamp: serverTimestamp(),
          };
          console.log("created-project", createdProject);
          await setDoc(docRef, createdProject);
        }

        // user_id: user.uid,

        // };
        setPage(3);
        setLoading(false);
        // window.location.reload();
      } catch (err) {
        setLoading(false);
        toast.error(JSON.stringify(err));
        console.log("error for projects", err);
      }
    }
  };
  return (
    <>
      {/* 1st row */}
      <div className=" grid grid-cols-1 sm:grid-cols-2 gap-[32px] items-center">
        {/* <InputComp
          label={"Title of Project"}
          required={true}
          value={project ? project.project_name : ""}
          setValue={setProjectTitle}
          error={projectTitle.trim() === "" && error}
        /> */}
        <CityComp
          value={project ? project.category_of_project : ""}
          setValue={setProjectCategory}
          label={t("projectCreate.category.select")}
          list={["Ocean Plastics", "Tree Planting", "Housing", "Clean Energy"]}
        />
      </div>
      {/* 2nd row */}
      <div>
        {/* text area */}
        <TextAreaComp
          required={true}
          label={t("projectCreate.projectProblem")}
          value={project ? project.problem_to_solve : projectProblem}
          setValue={setProjectProblem}
          max={100}
          error={projectProblem?.trim() === "" && error}
        />
      </div>
      {/* 3rd row */}
      <div>
        <TextAreaComp
          required={true}
          label={t("projectCreate.projectDescription")}
          setValue={setProjectDescription}
          value={project ? project.project_description : projectDescription}
          max={100}
          error={projectDescription?.trim() === "" && error}
        />
      </div>
      {/* 4th row */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-[32px] items-center">
        {/* SDGs */}
        <CountryComp
          label={t("projectCreate.SDGs")}
          required={true}
          // image={sdg}
          selected={project ? project.sdg_type : ""}
          setValue={setSDGs}
          list={sdgList}
          error={error}
        />

        {/* input comp */}
        <InputComp
          label={t("projectCreate.investors")}
          value={project ? project.project_investor : ""}
          setValue={setProjectInvestor}
        />
      </div>
      {/* 5th row */}
      <div>
        <TextAreaComp
          required={true}
          label={t("projectCreate.CO2")}
          value={project ? project.capacity_to_remove_co2 : CO2}
          setValue={setCO2}
          max={100}
          inf={true}
          error={CO2?.trim() === "" && error}
        />
      </div>
      {/* 6th row */}
      <div>
        <UploadComp
          label={t("projectCreate.projectPictures")}
          setImageUrl={setImageUrl2}
          storeUrlPath={"company_project_files"}
        />
      </div>
      {/* submit button */}
      <div className="mt-[50px]  w-full sm:w-[80%] md:w-[416px] mx-auto ">
        <FormButtonComp
          label={project ? t("projectCreate.update") : t("projectCreate.done")}
          onSubmit={handleSubmit}
          loading={loading}
        />
      </div>
    </>
  );
};

export default ProjectCreateTwo;
