import React, { useEffect, useState } from "react";
import co2 from "../../assests/website/co2.svg";
import SlotCounter from "react-slot-counter";
import search from "../../assests/website/search-gif.gif";
import tree from "../../assests/website/tree.svg";
// import { AnimatedCounter } from "react-animated-counter";
import { addCommas } from "separate-comma-numbers";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  increment,
  updateDoc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { useSelector } from "react-redux";

const NumberCountComp = ({ theme, left }) => {
  const [counterValue, setCounterValue] = useState("");
  const lang = useSelector((state) => state.language.langState);
  const { t } = useTranslation();

  const getCounterStart = async () => {
    const docRef = doc(db, "stats", "search");
    onSnapshot(docRef, (snapshot) => {
      const data = snapshot.data();
      setCounterValue(data?.total_search);
    });
  };

  useEffect(() => {
    getCounterStart();
  }, []);

  // console.log("counter", counterValue);
  return (
    <div className=" flex flex-col xs:flex-row items-center gap-[4px]  mx-auto w-full ">
      {/* left */}
      <div className="w-[42px] md:w-[64px]">
        <img className="w-full" src={tree} alt=""></img>
      </div>
      {/* right */}
      <div className=" max-w-full md:max-w-[400px] ">
        {/* up */}
        <p
          className={
            left
              ? "w-fit text-[48px] mx-auto lg:mx-0  font-extrabold bobly-one "
              : " w-fit text-[48px] mx-auto font-extrabold bobly-one  "
          }
        >
          <SlotCounter value={addCommas(counterValue)} />
        </p>
        {/* down */}
        <p className=" text-[#C2B484]  text-[10px] md:text-[16px] text-center lg:text-start font-normal mt-[-10px]">
          {t("home.heroSection.numberComponent")}
        </p>
      </div>
    </div>
  );
};

export default NumberCountComp;
