import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginApp from "./pages/webapp/LoginApp";
import CreateAcc from "./pages/webapp/CreateAcc";
import ResetPassword from "./pages/webapp/ResetPassword";
import Header from "./components/website/Header";
import AppHeader from "./components/webapp/AppHeader";
import AppFooter from "./components/webapp/AppFooter";
import MainContainer from "./components/webapp/MainContainer";
import MainContainerTwo from "./components/website/MainContainer";
import CreateNewPassword from "./components/webapp/CreateNewPassword";
import ProtectedRoute from "./firebase/ProtectedRoute";
import VerifyEmail from "./components/webapp/VerifyEmail";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "./redux/UserSlice";
import RememberUser from "./firebase/RememberUser";
import LogoutWhenTabIsClosed from "./globals/LogoutWhenTabIsClosed";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "./firebase/firebaseConfig";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

function App() {
  var theme = JSON.parse(localStorage.getItem("theme"));
  const lang = JSON.parse(localStorage.getItem("language"));
  const change = JSON.parse(localStorage.getItem("languageChange"));
  const [countryLocation, setCountryLocation] = useState();
  const { t } = useTranslation();
  // log out when user closes tab

  useEffect(() => {
    if (theme === null) {
      theme = false;
    }
  }, []);

  // get country location
  const getCountryByLocation = () => {
    fetch("https://ipapi.co/json/")
      .then(function (response) {
        return response.json();
      })
      .then(function (payload) {
        setCountryLocation(payload?.country_name);
      })
      .catch((e) => {
        // console.log("error", e);
      });
  };

  useEffect(() => {
    getCountryByLocation();
  }, []);

  // useEffect(() => {
  //   localStorage.setItem("languageChange", true);
  // }, [lang]);

  useEffect(() => {
    if (!change) {
      if (countryLocation === "France") {
        i18n.changeLanguage("fr");
        localStorage.setItem(
          "language",
          JSON.stringify({
            name: t("settings.websiteLang.options.french"),
            value: "fr",
          })
        );
      } else if (countryLocation === "Germany") {
        i18n.changeLanguage("ge");
        localStorage.setItem(
          "language",
          JSON.stringify({
            name: t("settings.websiteLang.options.german"),
            value: "ge",
          })
        );
      } else if (countryLocation === "China") {
        i18n.changeLanguage("ch");
        localStorage.setItem(
          "language",
          JSON.stringify({
            name: t("settings.websiteLang.options.chinese"),
            value: "ch",
          })
        );
      } else {
        i18n.changeLanguage("en");
        localStorage.setItem(
          "language",
          JSON.stringify({
            name: t("settings.websiteLang.options.english"),
            value: "en",
          })
        );
      }
    }
  }, [countryLocation]);

  // console.log("country-region", countryLocation);
  // console.log("language", lang);
  // console.log("change", change);
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="login/"
            element={
              // <RememberUser>
              <LoginApp theme={theme} />
              // </RememberUser>
            }
          />
          {/* supposed to be protected or unique */}
          <Route path="verify-email" element={<VerifyEmail theme={theme} />} />
          <Route path="signup" element={<CreateAcc theme={theme} />} />
          <Route
            path="forgot-password"
            element={<ResetPassword theme={theme} />}
          />
          <Route
            path="reset-password"
            element={<CreateNewPassword theme={theme} />}
          />

          {/* to enter this route check if there is a stored user and if it is a search user otherwise logout */}
          <Route path="/*" element={<MainContainerTwo theme={theme} />} />

          <Route
            path="dashboard/*"
            element={
              <ProtectedRoute>
                <MainContainer theme={theme} />
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
      {/* <LoginApp /> */}
      {/* <CreateAcc /> */}
      {/* <ResetPassword /> */}
      {/* <Header /> */}
    </div>
  );
}

export default App;
