import React, { useEffect, useRef, useState } from "react";

// assests
import filter from "../../assests/webapp/filter.svg";
import grey from "../../assests/webapp/project-image.svg";

// components
import SearchComponent from "../../components/webapp/SearchComponent";
import ReceintNotComp from "../../components/webapp/ReceintNotComp";
import AppHeadingNav from "../../components/webapp/AppHeadingNav";
import ProjectNotification from "../../components/webapp/ProjectNotification";
import { db } from "../../firebase/firebaseConfig";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { useTranslation } from "react-i18next";

const Notification = ({ theme }) => {
  const [notification, setNotification] = useState();
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const { t } = useTranslation();
  // fetching notification
  const fetchNotification = async () => {
    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, "notifications"),
          where("user_id", "==", currentUser.uid),
          orderBy("timestamp", "desc")
        )
      );
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      // return
      console.log("notification-newdata", newData);

      setNotification(newData);
    } catch (error) {
      console.log("Error fetching document:", error);
    }
  };

  useEffect(() => {
    // fetchPost();
    fetchNotification();
  }, []);
  return (
    <div className="">
      <AppHeadingNav
        left={t("appHeader.dashboard")}
        right={t("appHeader.notifications")}
        date={false}
        theme={theme}
      />
      <div className="bg-white rounded-[16px] p-[24px]">
        {/* up */}
        <div className="flex items-center mb-[24px] justify-between">
          <div className="w-[434px]">
            <SearchComponent
              placeholder={t("notification.search")}
              bg={"white"}
            />
          </div>

          {/* filter comp */}
          <div className="cursor-pointer flex px-[18px] py-[12px] rounded-[8px] gap-[16px] border border-[#DCE4E8]">
            {/* image */}
            <div>
              <img src={filter} alt=""></img>
            </div>

            <p className="font-semibold text-base">
              {t("notification.rangeDate")}
            </p>
          </div>
        </div>

        {/* down */}
        <div className=" flex flex-col gap-[24px]">
          {/* comp 1 */}

          {notification?.length === 0 ? (
            <div className="text-sm text-[#ACB5BB]">
              {t("notification.noNotification")}
            </div>
          ) : (
            notification?.map((data, index) => {
              if (data.notification_type === "coins") {
                return (
                  <ReceintNotComp
                    header={data.notification_title}
                    notification={data.notification_subject}
                    time={"2m"}
                  />
                );
              } else {
                return (
                  <ProjectNotification
                    image={grey}
                    name={data.notification_title}
                    message={data.notification_subject}
                    time={"30h"}
                  />
                );
              }
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default Notification;
