import React, { useEffect, useState } from "react";
import WebsiteButton from "./WebsiteButton";
import map from "../../assests/website/maps-home.png";
import co2 from "../../assests/website/co2.svg";
import leaf from "../../assests/webapp/leaf.svg";
// import { AnimatedCounter } from "react-animated-counter";
import { useNavigate } from "react-router-dom";
import NumberCountComp from "./NumberCountComp";
import { useTranslation } from "react-i18next";

const WeInvestInAllGeo = ({ theme }) => {
  const { t } = useTranslation();
  // console.log("counter", counterValue);

  const navigate = useNavigate();
  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };
  const handleClick = () => {
    navigate("/all-projects");
  };
  return (
    <div className={theme ? "text-[#1A1C1E]" : "text-[white]"}>
      {/* header */}
      <div className="mb-[10px] md:mb-[30px] text-center">
        <div className=" relative">
          {/* image */}
          <div className="absolute w-[11px] md:w-[30px] top-[0px] md:top-[-10px] left-[16%]">
            <img className="w-full" src={leaf} alt=""></img>
          </div>

          <p className="text-[28px] mb-[30px] md:text-[48px] font-semibold leding-[47px] md:leading-[67px] max-w-[900px] mx-auto ">
            {t("home.weInvestInAllGeo.header")}
          </p>
        </div>

        <p className="text-sm leading-[28px] md:text-base font-normal  max-w-[600px] mx-auto">
          {t("home.weInvestInAllGeo.words")}
        </p>
      </div>

      {/* number */}
      <div className=" mx-auto w-fit">
        <NumberCountComp theme={theme} />
      </div>

      {/* big image map */}
      <div className="">
        <img src={map} alt=""></img>
      </div>

      {/* button */}
      <div
        onClick={() => {
          handleClick();
          moveToTop();
        }}
        className="w-full sm:w-[288px] mx-auto cursor-pointer mt-[12px] md:mt-0"
      >
        <WebsiteButton label={t("home.weInvestInAllGeo.button")} />
      </div>
    </div>
  );
};

export default WeInvestInAllGeo;
