import React from "react";
import app from "../../assests/website/appStore.svg";
import play from "../../assests/website/playStore.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const JoinOurGreen = ({ words }) => {
  const { t } = useTranslation();
  return (
    <div className=" text-[white] mb-[150px]">
      {/* encourage  */}
      <div className=" max-w-[1200px] mx-[24px]  xl:mx-auto  relative">
        <div className=" curve-green px-[27px] md:px-[100px] pt-[90px] pb-[150px] sm:py-[60px]  rounded-[24px]">
          <p className=" text-[28px] text-center md:text-start  md:text-[42px] leading-[30px] md:leading-[46px] font-bold  max-w-[651px] ">
            {t("home.joinOurGreen")}
          </p>
          <div className=" flex flex-col sm:flex-row mt-[30px] mx-auto md:mx-0  w-fit gap-[8px]">
            {/* image */}
            <Link
              to={"https://apps.apple.com/ng/app/pap-earth/id1666003378"}
              className="cursor-pointer"
            >
              <img src={app} alt=""></img>
            </Link>
            {/* playstore */}
            <Link
              to={"https://play.google.com/store/apps/details?id=com.pap.earth"}
              className="cursor-pointer"
            >
              <img src={play} alt=""></img>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinOurGreen;
