import React from "react";

const CoinComp = ({ image, word, theme, bg, bgC }) => {
  return (
    <div
      className={
        theme
          ? bg
            ? `min-w-[78px] py-[8px] pr-[16px] pl-[8px] font-semibold bg-[${bgC}] rounded-[8px] gap-[4px] flex items-center`
            : "min-w-[78px] py-[8px] pr-[16px] pl-[8px] font-semibold bg-[#EAEAEA] rounded-[8px] gap-[4px] flex items-center"
          : bg
          ? `min-w-[78px] py-[8px] pr-[16px] pl-[8px] font-semibold bg-[${bgC}] rounded-[8px] gap-[4px] flex items-center`
          : "min-w-[78px] py-[8px] pr-[16px] pl-[8px] font-semibold bg-[#EAEAEA] dark-theme-h-bg rounded-[8px] gap-[4px] flex items-center"
      }
    >
      <div className="w-[28px] h-[28px] ">
        <img
          src={image}
          className="w-full h-full object-cover rounded-full"
          alt=""
        ></img>
      </div>

      <p>{word}</p>
    </div>
  );
};

export default CoinComp;
