import React, { useEffect, useRef, useState } from "react";
import logout from "../../assests/webapp/log-out.svg";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/firebaseConfig";
import { useNavigate } from "react-router-dom";
import FormButtonComp from "./FormButtonComp";
import FormButtonTwo from "./FormButtonTwo";
import LogOutModal from "./LogOutModal";

const LogOutComp = ({ theme }) => {
  const [open, setOpen] = useState(false);

  // useEffect(() => {
  //   /**
  //    * Alert if clicked on outside of element
  //    */
  //   function handleClickOutside(event) {
  //     if (ref.current && !ref.current.contains(event.target)) {
  //       setOpen(false);
  //       console.log("click outside");
  //     }
  //   }
  //   // Bind the event listener
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     // Unbind the event listener on clean up
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [ref]);

  return (
    <div className=" ">
      <div
        onClick={() => {
          setOpen(true);
        }}
        className={
          theme
            ? "rounded-full  p-[12px] bg-[white] cursor-pointer"
            : "rounded-full  p-[12px] dark-theme-comp-bg cursor-pointer"
        }
      >
        <img src={logout} alt="" className=""></img>
      </div>

      {/* hidden modal */}
      <LogOutModal open={open} setOpen={setOpen} theme={theme} />
    </div>
  );
};

export default LogOutComp;
