import React from "react";
import coin from "../../assests/website/header-coins.svg";

const ResultComp = ({ result, theme }) => {
  // console.log("results", result);
  return (
    <div className="flex flex-col gap-[5px] max-w-[600px] ">
      {/* link */}
      <div className=" flex items-center gap-[12px]">
        {/* image */}
        {result?.pagemap?.["cse_thumbnail"] !== undefined && (
          <div className="w-[28px] h-[28px] bg-white rounded-full flex items-center justify-center">
            <div className="w-[18px] h-[18px]">
              {result?.pagemap?.cse_thumbnail.map((val, index) => (
                <img
                  src={val.src}
                  className="w-full h-full object-cover"
                  alt=""
                ></img>
              ))}
            </div>
          </div>
        )}
        <div>
          <p className="text-[14px] font-normal"></p>
          <p className="text-[12px] leading-[15px] font-normal">
            <a href={result?.link}>{result?.displayLink}</a>
          </p>
        </div>
      </div>

      {/* name */}
      <p
        className={
          theme
            ? "text-[#1a0dab] text-[20px] leading-[24px] font-medium result-comp"
            : "text-[20px] leading-[24px] font-medium text-[#8ab4f8] result-comp"
        }
      >
        <a href={result.link}>{result.title}</a>
      </p>

      {/* details */}
      <p className="text-[14px] leading-[22px] font-normal">{result.snippet}</p>
    </div>
  );
};

export default ResultComp;
