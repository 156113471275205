import React from "react";

const LoginComp = ({ children, header, theme }) => {
  return (
    <div
      className={
        theme
          ? "bg-[#F4F4F7] max-w-[700px] mx-auto text-[#1a1c1e] mt-[48px] md:mt-[56px] rounded-[12px] px-[16px] py-[32px]  md:px-[32px]"
          : "bg-[white] max-w-[700px] mx-auto text-[#1a1c1e] mt-[48px] md:mt-[56px] rounded-[12px] px-[16px] py-[32px]  md:px-[32px]"
      }
    >
      <form>
        <p className="text-[24px] md:text-[32px] leading-[36px ] md:leading-[48px] font-bold mb-[32px]">
          {header}
        </p>
        {children}
      </form>
    </div>
  );
};

export default LoginComp;
