import React, { useEffect, useState } from "react";

const InputComp = ({ label, required, setValue, type, min, error, value }) => {
  const [check, setCheck] = useState("");

  return (
    <div className="relative">
      <label className="flex gap-[2px] text-[#ACB5BB] font-medium text-[12px] block mb-[3px]">
        <p>{label}</p>
        {required && <p className="text-[#D80027]">*</p>}
      </label>
      {error && (
        <div className="absolute top-[-30px] right-0 my-3 py-[6px] w-fit px-[12px] rounded-[4px] bg-[#FFEFEF] text-[#FF0319]">
          <p className="text-xs">missing field</p>
        </div>
      )}
      <input
        defaultValue={value}
        // value={value}
        type={type}
        min={type === "number" && min}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        className="px-[24px] py-[18px] w-full border border-[#DCE4E8] text-[14px] rounded-[8px]"
      ></input>
    </div>
  );
};

export default InputComp;
