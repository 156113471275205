import React from "react";
import { useNavigate } from "react-router-dom";
import { addCommas } from "separate-comma-numbers";

const OngoingProjectsDash = ({
  image,
  name,
  date,
  goal,
  percentage,
  key,
  status,
  theme,
  id,
}) => {
  const navigate = useNavigate();
  return (
    <div
      key={key}
      className="cursor-pointer flex-col gap-y-[20px] flex sm:flex-row sm:items-center gap-x-[10px] sm:justify-between "
      onClick={() => {
        navigate(`projects/my-projects/details/${id}`);
      }}
    >
      {/* left */}
      <div className=" flex justify-between gap-[12px] items-center">
        {/* left left */}
        <div
          className={
            theme
              ? "bg-[white] flex items-center justify-center min-w-[52px] w-[52px] h-[52px] rounded-[100%]"
              : "bg-[#F4F4F7] flex items-center justify-center min-w-[52px] w-[52px] h-[52px] rounded-[100%]"
          }
        >
          <div className=" w-fit">
            <img src={image} alt=""></img>
          </div>
        </div>
        {/* left right */}
        <div className=" flex flex-col gap-[6px]">
          <p className="text-base font-semibold">{name}</p>
          <p className="font-normal text-sm text-[#ACB5BB]">{date}</p>
        </div>
      </div>
      {/* right */}
      <div className=" flex justify-between gap-[8px] items-center">
        {/* right left */}
        <div className=" flex flex-col gap-[6px]">
          <p className="text-base font-semibold">{addCommas(goal)}</p>
          <p className="font-normal text-sm text-[#ACB5BB] text-end">
            {percentage}
          </p>
        </div>

        {/* right right */}
        <div className="flex items-center gap-[8px]">
          {/* status */}
          <div
            className={
              status === "approved"
                ? "py-[6px] px-[12px] text-sm w-fit rounded-[4px] bg-[#ECF8F0] text-[#1C8C6E]"
                : status === "pending"
                ? "py-[6px] text-sm w-fit px-[12px] rounded-[4px] bg-[#FBF4E4] text-[#FBBC04]"
                : "py-[6px] text-sm w-fit px-[12px] rounded-[4px] bg-[#FFEFEF] text-[#FF0319]"
            }
          >
            {status}
          </div>
          {/* dot */}
          {/* <div className="bg-[#58BA59] w-[8px] h-[8px] rounded-[100%]"></div> */}
        </div>
      </div>
    </div>
  );
};

export default OngoingProjectsDash;
