import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect } from "react";
import { db } from "../firebase/firebaseConfig";
// getting user from local storage
const currentUser = JSON.parse(localStorage.getItem("currentUser"));

// Ensure currentUser and currentUser.uid are defined
// if (!currentUser || !currentUser.uid) {
//   console.log("User not found or does not have uid");
// } else {
//   console.log("User found ");
// }

// Initial State
const initialState = {
  data: [],
  loading: false,
  error: "",
};

// method to fetch the user from the database
export const fetchNotification = createAsyncThunk(
  "user/fetchNotification",
  async () => {
    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, "notifications"),
          where("user_id", "==", currentUser.uid),
          orderBy("timestamp", "desc")
        )
      );
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      // return
      console.log("notification-newdata", newData);

      return newData;
    } catch (error) {
      console.log("Error fetching document:", error);
    }
  }

  // const docRef = doc(db, "notifications", currentUser.uid);
  // try {
  //   const querySnapshot = await getDocs(docRef);
  //   // Handle the data or dispatch an action; here
  //   console.log("query-snap", querySnapshot);
  //   return querySnapshot.data();
  // } catch (error) {
  //   // Handle the error or show an error message to the user
  //   console.log("Error fetching document:", error);
  // }
  // }
);

export const NotificationSlice = createSlice({
  // name
  name: "notification",

  // Initial State
  initialState,

  // reducers
  extraReducers: (builder) => {
    builder.addCase(fetchNotification.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchNotification.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(fetchNotification.rejected, (state, action) => {
      state.loading = true;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

// export const {  } = NotificationSlice.actions;
export default NotificationSlice.reducer;
