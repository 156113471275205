import React, { useState } from "react";
import upload from "../../assests/webapp/upload.svg";
import { collection, doc } from "firebase/firestore";
import { db, storage } from "../../firebase/firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// import { auth, db, storage } from "../../FirebaseConfig";

const UploadComp = ({ label, setImageUrl, storeUrlPath }) => {
  const [imageDetails, setImageDetails] = useState();
  const handleChange = async (event) => {
    const file = event.target.files;
    setImageDetails(Object.values(file));
    const urls = [];
    let index = 0;

    for (const f of file) {
      const reff = doc(collection(db, storeUrlPath));
      const storageRef = ref(storage, `/${storeUrlPath}/${reff.id}`);
      const uploadTask = uploadBytesResumable(storageRef, f);
      uploadTask.on("state_changed", null, null, async () => {
        const url = await getDownloadURL(uploadTask.snapshot.ref);
        urls.push(url);
      });
      // index += 1;

      // if (index === file.length) {
      console.log("entering here");
      setImageUrl(urls);
      // }
    }

    console.log("urls", urls);
  };

  console.log("image details file", imageDetails);

  return (
    <div>
      <label className="text-[#ACB5BB] font-medium text-[12px] mb-[3px] block">
        {label}
      </label>
      <div className="border border-[#DCE4E8] rounded-[8px] py-[30px]">
        <label for="files" className=" mx-auto w-fit  cursor-pointer ">
          <div className="mx-auto w-fit mb-[9px]">
            <img src={upload} alt=""></img>
          </div>
          <input
            className="hidden"
            type="file"
            name="files"
            id="files"
            onChange={(e) => handleChange(e)}
            accept="image/*"
            multiple={storeUrlPath === "company_project_files"}
          ></input>

          {imageDetails?.length > 0 ? (
            imageDetails?.map((data) => (
              <p className="text-center text-[#ACB5BB] font-medium text-[12px]">
                {data.name}
              </p>
            ))
          ) : (
            <p className="text-[#ACB5BB] text-center font-medium text-[12px]">
              Tap here to upload{" "}
            </p>
          )}
        </label>
      </div>
    </div>
  );
};

export default UploadComp;
