import React from "react";
import searchWhite from "../../assests/website/header-search-white.svg";
import SearchComponent from "../webapp/SearchComponent";
import { useSearchParams } from "react-router-dom";

const SearchCompBtn = ({ placeholder, onSubmit, setValue, value, theme }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div className="flex gap-0 sm:gap-[4px] items-center w-full">
      <div className="hidden sm:block w-full">
        <SearchComponent
          placeholder={placeholder}
          onSubmit={onSubmit}
          setValue={setValue}
          value={value}
          theme={theme}
        />
      </div>

      <div className="block sm:hidden w-full">
        <SearchComponent
          placeholder={placeholder}
          onSubmit={onSubmit}
          phone={true}
          setValue={setValue}
          value={value}
          theme={theme}
        />
      </div>

      {/* button */}
      <div
        onClick={() => {
          if (value === "" || value === null) {
            return;
          }
          onSubmit();
          // localStorage.setItem("global_search", value);
          // window.location.href = `/website/search?q=${value}`;
        }}
        className={
          "bg-[#0B7026] p-[13px] rounded-l-0 rounded-r-[8px] sm:rounded-[8px] cursor-pointer"
        }
      >
        <div className="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="none"
          >
            <path
              stroke={theme ? "#FFFFFF" : "#1A1C1E"}
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2.5"
              d="m20 20-4.343-4.343m0 0A8 8 0 1 0 4.345 4.344a8 8 0 0 0 11.312 11.313Z"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default SearchCompBtn;
