import { createSlice } from "@reduxjs/toolkit";
import { collection } from "firebase/firestore";

// collectionRef = collection("your-collection");

const data = [
  // {
  //   id: "P023",
  //   project_name: "Recycling plastic waste (CMR)",
  //   project_status: "accepted",
  //   project_investor: "",
  //   project_description: "",
  //   project_picture: "",
  //   fullname: "",
  //   company_name: "",
  //   company_logo: "",
  //   country_of_application: "",
  //   phone_code: "",
  //   facebook: "",
  //   instagram: "",
  //   linkedin: "",
  //   incorporate: false,
  //   category_of_project: "",
  //   problem_to_solve: "",
  //   sdg_type: "",
  //   capacity_to_remove_co2: "",
  //   creation_date: "May 02 , 2023",
  //   creation_time: "01:32 PM",
  // },
  // {
  //   id: "P024",
  //   project_name: "Salubata (NGR)",
  //   creation_date: "May 02 , 2023",
  //   creation_time: "01:32 PM",
  //   coins_collected: "4 (0.004%)",
  //   total_coins: "100,000",
  //   support: "4",
  //   status: "Pending Approval",
  // },
  // {
  //   id: "P024",
  //   project_name: "Fight against pollution (BDI)",
  //   creation_date: "May 02 , 2023",
  //   creation_time: "01:32 PM",
  //   coins_collected: "4 (0.004%)",
  //   total_coins: "100,000",
  //   support: "4",
  //   status: "Declined",
  // },
];

export const ProjectSlice = createSlice({
  // name
  name: "project",

  // Initial State
  initialState: {
    data,
  },

  // reducers
  reducers: {
    addProject: (state, action) => {
      state.data = action.payload;
    },
    updateProject: (state, action) => {},
    deleteProject: (state, action) => {},
  },
});

export const { addProject, deleteProject, updateProject } =
  ProjectSlice.actions;
export default ProjectSlice.reducer;
