import React from "react";
import logo from "../../assests/website/pap-logo.svg";
import { useNavigate } from "react-router";

const LogoSmall = () => {
  const navigate = useNavigate();
  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div
      className="min-w-[39px]"
      onClick={() => {
        moveToTop();
        navigate("/");
      }}
    >
      <img src={logo} alt=""></img>
    </div>
  );
};

export default LogoSmall;
