import React from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import nigeria from "../../assests/webapp/Nigeria.svg";
import arrow from "../../assests/webapp/arrow-down.svg";

const PhoneNumberComp = ({ label, required, setValue, phone, error }) => {
  console.log("phone-value", phone);
  return (
    <div className="relative">
      <label className="flex gap-[2px] text-[#ACB5BB] font-medium text-[12px] block mb-[3px]">
        <p>{label}</p>
        {required && <p className="text-[#D80027]">*</p>}
      </label>{" "}
      {error && (
        <div className="absolute top-[-30px] right-0 my-3 py-[6px] w-fit px-[12px] rounded-[4px] bg-[#FFEFEF] text-[#FF0319]">
          <p className="text-xs">missing field</p>
        </div>
      )}
      <PhoneInput
        placeholder="Enter phone number"
        value={phone}
        onChange={setValue}
      />
    </div>
  );
};

export default PhoneNumberComp;
