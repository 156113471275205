import React, { useEffect, useRef } from "react";

import svgs2 from "../../assests/website/home-image-svg.svg";
import line from "../../assests/website/home-image-line.svg";
import play from "../../assests/website/home-play-btn.svg";
import gradient from "../../assests/website/gradient-black.svg";
import cursor from "../../assests/website/cursor-pointer.svg";

import chrome from "../../assests/website/chrome.svg";
import co2 from "../../assests/website/co2.svg";

// import homeVid from "../../assests/website/home-video.MP4";

import circle from "../../assests/website/circle.svg";
import home from "../../assests/website/hero-image2.png";
import AddToChromeBtn from "../../components/website/AddToChromeBtn";
import { useSelector } from "react-redux";
import { useState } from "react";
import PlayStoreAppStore from "../../components/website/PlayStoreAppStore";
import AddToPlayStore from "../../components/website/AddToPlayStore";
// import { AnimatedCounter } from "react-animated-counter";
import { addCommas } from "separate-comma-numbers";
import NumberCountComp from "../../components/website/NumberCountComp";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import moveToTop from "../../globals/moveToTop";
import HandleLanguageChange from "../../globals/handleLanguageChange";
import { auth } from "../../firebase/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";

const HomePage = ({ theme, setIsPlaying }) => {
  const { t } = useTranslation();
  const lang = JSON.parse(localStorage.getItem("language"));
  const remember = JSON.parse(localStorage.getItem("remember"));
  console.log("remember", remember);

  console.log("language2", lang);

  // move to the top
  moveToTop();

  useEffect(() => {
    // Listen for changes in the user's authentication state
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        console.log("app-user", user);
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  return (
    <div className=" max-w-[1200px] mx-[24px]  xl:mx-auto  pt-[80px]  lg:pt-[60px] z-20 relative">
      <div
        className={
          theme
            ? "grid grid-cols-1 lg:grid-cols-2 gap-[80px] relative items-center  text-[#1A1C1E]"
            : "grid grid-cols-1 lg:grid-cols-2 gap-[80px] relative items-center  text-[white]"
        }
      >
        {/* left */}
        <div className="  ">
          {/* header */}
          <p className="text-[28px] md:text-[60px] leading-[47px] md:leading-[59px] font-extrabold mb-[5px] md:mb-[11px] text-center lg:text-start">
            {t("home.heroSection.header")}
          </p>
          {/* words */}
          <p className="sm:block hidden text-base mx-auto max-w-[550px] md:max-w-full md:text-lg leading-[28px]  font-normal mb-[30px] text-center lg:text-start">
            {t("home.heroSection.word")}
          </p>

          {/* button */}
          <div className="relative mx-auto lg:mx-0 mb-[50px] lg:mb-[40px] mt-[20px] sm:mt-0">
            {" "}
            <AddToChromeBtn theme={theme} />
            <AddToPlayStore />
            {/* cursor */}
            {/* <div className="absolute hidden lg:block bottom-[-20px] left-[200px]">
              <img src={cursor} alt=""></img>
            </div> */}
          </div>

          {/* number */}
          <div className=" lg:mx-0 mx-auto w-fit">
            <NumberCountComp theme={theme} left={true} />
          </div>
        </div>

        {/* right */}
        <div className=" flex justify-end sm:justify-center ">
          {/* image box */}
          <div className=" relative  w-[90%] sm:w-[60%] lg:w-full">
            {/* abosulte svg 1 */}
            <div className="absolute bottom-[50px] w-[190px] sm:w-[263px] lg:w-[363px] left-[-50px] sm:left-[-70px] lg:left-[-100px]">
              <img className="w-full" src={svgs2}></img>
            </div>

            {/* abosulte svg 2*/}
            <div className="absolute w-[68px] md:w-[129px] bottom-[-30px] md:bottom-[-40px] left-[-30px] md:left-[-50px] z-20">
              <img className="w-full" src={circle} alt=""></img>
            </div>

            {/* absolute gradient */}
            <div className="bg-gradient absolute top-0 z-20 rotate-180 left-0 "></div>

            {/* <div className="absolute z-20 top-[30px] left-[30px] flex items-center gap-[20px] ">
              <div
                className="cursor-pointer"
                onClick={() => {
                  setIsPlaying(true);
                }}
              >
                <img src={play} alt=""></img>
              </div>
              <div>
                <p className="font-bold text-lg text-white">
                  {" "}
                  {t("home.heroSection.video.header")}
                </p>
                <p className="text-white">
                  {" "}
                  {t("home.heroSection.video.word")}
                </p>
              </div>
            </div> */}

            {/* relative home image */}
            <div className="relative z-10  ">
              <img
                className="w-full rounded-bl-[32px] lg:rounded-bl-[64px]"
                src={home}
                alt=""
              ></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
