import React, { useEffect, useState } from "react";
import app, { auth, db } from "../../firebase/firebaseConfig";
import curvy1 from "../../assests/website/home-back-svg.svg";
import curvy2 from "../../assests/website/search-curve-svg.svg";
import CreateAccSuccessComp from "./CreateAccSuccessComp";
import { onAuthStateChanged, updateEmail } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import LogoSmall from "../website/LogoSmall";
import { useSearchParams } from "react-router-dom";
// const admin = require("firebase-admin");

const VerifyEmail = ({ theme }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  // admin.initializeApp({
  //   credential: admin.credential.cert(serviceAccount),
  //   databaseURL: "https://your-project.firebaseio.com",
  // });

  // const oobCode = searchParams.get("oobCode"); // Get the oobCode from the URL query parameter

  // app
  //   .auth()
  //   .verifyOobCode(oobCode)
  //   .then((decodedClaims) => {
  //     // The oobCode is valid
  //     if (decodedClaims.email) {
  //       // You can mark the user's email as verified in Firebase Authentication
  // return admin.auth().updateUser(decodedClaims.uid, {
  //         emailVerified: true,
  //       });
  //     } else {
  //       throw new Error("Invalid oobCode");
  //     }
  //   })
  //   .then(() => {
  //     // Email verification is complete
  //     console.log("Email verification successful!");
  //   })
  //   .catch((error) => {
  //     console.error("Error verifying oobCode:", error);
  //     // res.status(400).send("Email verification failed.");
  //   });

  // const [user, setUser] = useState();
  // useEffect(() => {
  //   // Listen for changes in the user's authentication state
  //   const unsubscribe = onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       // User is signed in
  //       console.log("app-user", user);
  //       // setUser(user);
  //       const docRef = doc(db, "users", user.id);
  //       updateDoc(docRef, {
  //         email_verified: true,
  //       });
  //     }
  //   });

  //   // Clean up the listener when the component unmounts
  //   return () => unsubscribe();
  // }, []);

  return (
    <div
      className={
        theme
          ? "relative bg-[#F4F4F7] min-h-screen flex items-center justify-center"
          : "relative bg-[#1a1c1e]  min-h-screen flex items-center justify-center px-[12px]"
      }
    >
      {/* absolute */}
      <div className="hidden md:block absolute left-0 top-0 z-20">
        <img src={curvy2} alt=""></img>
      </div>

      {/* absolute */}
      <div className="hidden md:block absolute right-0 z-20 top-[50px]">
        <img src={curvy1} alt=""></img>
      </div>

      <div className="">
        <div className="mx-auto w-fit">
          <LogoSmall />
        </div>

        <CreateAccSuccessComp theme={theme} words={"Email Verified"} />
      </div>
    </div>
  );
};

export default VerifyEmail;
