import React, { useEffect, useRef, useState } from "react";
import FormButtonTwo from "./FormButtonTwo";
import FormButtonComp from "./FormButtonComp";
import cancel from "../../assests/website/cancel.svg";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/firebaseConfig";
import { useNavigate } from "react-router-dom";
import ClickOutsideRef from "../../globals/clickOutsideRef";
import { useTranslation } from "react-i18next";

const LogOutModal = ({ open, theme, setOpen }) => {
  const navigate = useNavigate();
  const ref = useRef();
  const { t } = useTranslation();
  ClickOutsideRef(ref, setOpen);

  const handleLogout = async () => {
    try {
      await signOut(auth)
        .then(() => {
          // Remove the user's authentication state from local storage
          localStorage.removeItem("currentUser");

          navigate("/login");
        })
        .catch((error) => {
          // Handle sign-out errors
        });
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleOpenModal = () => {
    setOpen(false);
  };
  return (
    <div
      className={
        open
          ? "block modal-background z-50 absolute h-screen w-screen top-0 right-0 flex items-center justify-center"
          : "hidden"
      }
    >
      {/* <div className="absolute top-[30px] right-[30px] text-[white] cursor-pointer">
        <img src={cancel} alt=""></img>
      </div> */}
      {/* white box */}
      <div
        ref={ref}
        className="bg-white p-[32px] rounded-[16px] w-[80%] max-w-[600px]"
      >
        <p className={"text-xl font-semibold text-center text-[#1a1c1e]"}>
          {t("logout.question")}
        </p>

        <div className=" flex gap-[16px] mt-[32px]">
          <FormButtonComp label={t("logout.yes")} onSubmit={handleLogout} />
          <FormButtonTwo
            label={t("logout.no")}
            theme={theme}
            onSubmit={handleOpenModal}
          />
        </div>
      </div>
    </div>
  );
};

export default LogOutModal;
