import React, { useState } from "react";
import logo from "../../assests/website/footer-logo.svg";
import app from "../../assests/website/appStore.svg";
import play from "../../assests/website/playStore.svg";
import footCurveBlack from "../../assests/website/footer-curve.svg";
import footCurveWhite from "../../assests/website/curve-top-white.svg";
import LogoBig from "./LogoBig";
import Socials from "./Socials";
import { Link, useNavigate } from "react-router-dom";
import SubscribeBtn from "./SubscribeBtn";
import { useTranslation } from "react-i18next";
import HandleLanguageChange from "../../globals/handleLanguageChange";

const Footer = ({ theme }) => {
  const navigate = useNavigate();
  HandleLanguageChange();
  const { t } = useTranslation();

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="relative z-20">
      {/* footer curve */}
      <div className="">
        {theme ? (
          <img src={footCurveWhite} alt="" className="w-full"></img>
        ) : (
          <img src={footCurveBlack} alt="" className="w-full"></img>
        )}
      </div>
      <div
        className={
          theme
            ? "bg-[#F7F7F7] pt-[75px] pb-[90px]"
            : "dark-theme pt-[75px] pb-[90px]"
        }
      >
        {/* footer rec */}
        <div className="  max-w-[1200px] mx-[24px] xl:mx-auto">
          {/* one */}
          <div className="border-b border-[#D2D2D2] ">
            {/* up */}
            <div className=" flex flex-col xl:flex-row   xl:justify-between">
              <div className=" flex flex-col max-w-[800px] xl:hidden gap-[8px] mb-[21px]">
                <div className="cursor-pointer max-w-[41px]">
                  <LogoBig />
                </div>

                <div>
                  <p className="text-[12px] leading-[24px] font-normal">
                    {t("footer.words")}
                  </p>
                </div>
              </div>
              {/* left */}
              <div className=" flex flex-col lg:flex-row justify-between w-full">
                {/* left left */}

                {/* left right */}
                <div className=" w-full sm:w-auto  flex flex-col gap-[21px] md:flex-row  md:gap-[70px]">
                  {/* what we do */}
                  {/* row 1 */}
                  <div className="flex justify-between sm:gap-[70px]">
                    <div className="">
                      <p className="font-bold text-sm sm:text-lg mb-[21px]">
                        {t("footer.navigation.whatWeDo")}
                      </p>
                      <a href={"mailto:info@pap.earth"} className="">
                        <p className="text-xs sm:text-base font-medium">
                          {t("footer.navigation.InvestInUs")}
                        </p>
                      </a>
                    </div>

                    {/* company */}
                    <div className="">
                      <p className="font-bold  text-sm sm:text-lg mb-[21px]">
                        {t("footer.navigation.company")}
                      </p>
                      <div className="flex flex-col">
                        <Link
                          to={"./how-pap-works"}
                          onClick={() => {
                            moveToTop();
                          }}
                          className="text-xs sm:text-base font-medium mb-[16px]"
                        >
                          {t("footer.navigation.aboutUs")}
                        </Link>
                        <Link
                          to={"./search"}
                          onClick={() => {
                            moveToTop();
                          }}
                          className="text-xs sm:text-base font-medium mb-[16px]"
                        >
                          {t("footer.navigation.search")}
                        </Link>
                      </div>
                    </div>
                  </div>

                  {/* row 2 */}
                  <div className="flex justify-between sm:gap-[70px]">
                    {/* legal */}
                    <div className="">
                      <p className="font-bold  text-sm sm:text-lg mb-[21px]">
                        {t("footer.navigation.legal")}
                      </p>
                      <div className="flex flex-col">
                        <Link
                          to={"./privacy-policy"}
                          onClick={() => {
                            moveToTop();
                          }}
                          className="text-xs sm:text-base font-medium mb-[16px]"
                        >
                          {t("footer.navigation.privacyPolicy")}
                        </Link>
                        <Link
                          to={"./terms-of-service"}
                          onClick={() => {
                            moveToTop();
                          }}
                          className="text-xs sm:text-base font-medium mb-[16px]"
                        >
                          {t("footer.navigation.termsOfService")}
                        </Link>
                      </div>
                    </div>

                    {/* connect with us */}
                    <div className="">
                      <p className="font-bold  text-sm sm:text-lg mb-[21px] text-end lg:text-start">
                        {t("footer.navigation.connectWithUs")}
                      </p>
                      <Socials />
                    </div>
                  </div>
                </div>

                {/* right */}
                <div className="mt-[21px] lg:mt-0 ">
                  <p className="font-bold  text-sm sm:text-lg mb-[21px]">
                    {t("footer.navigation.apps")}
                  </p>
                  <div className="flex lg:flex-col flex-row gap-[16px]">
                    {/* google */}
                    <Link
                      to={
                        "https://apps.apple.com/ng/app/pap-earth/id1666003378"
                      }
                      className="cursor-pointer"
                    >
                      <img src={app} alt=""></img>
                    </Link>
                    {/* playstore */}
                    <Link
                      to={
                        "https://play.google.com/store/apps/details?id=com.pap.earth"
                      }
                      className="cursor-pointer"
                    >
                      <img src={play} alt=""></img>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* down */}
            <div className=" flex justify-between gap-[60px] mt-0 xl:mt-[42px] mb-[37px] xl:mb-[83px]">
              <div className="hidden xl:flex gap-[61px]">
                <div
                  onClick={() => {
                    navigate("/");
                  }}
                  className="cursor-pointer min-w-[84px]"
                >
                  <LogoBig />
                </div>

                <div>
                  <p className="text-[12px] leading-[24px] font-normal">
                    {t("footer.words")}
                  </p>
                </div>
              </div>

              {/* subscribe button */}
              <SubscribeBtn />
            </div>
          </div>

          {/* two */}
          <div className="mt-[18px] xl:mt-[40px]">
            <p
              className={
                theme
                  ? "text-center font-normal leading-[29px] text-[#1A1C1E]"
                  : "text-center font-normal leading-[29px] dark-theme-copy-right"
              }
            >
              {`© ${new Date().getFullYear()} ${t("footer.copyRight")}`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
