import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

import { db } from "../firebase/firebaseConfig";
// getting user from local storage
const currentUser = JSON.parse(localStorage.getItem("currentUser"));

// Ensure currentUser and currentUser.uid are defined
// if (!currentUser || !currentUser.uid) {
//   console.log("User not found or does not have uid");
// } else {
//   console.log("User found ", currentUser);
// }

// Initial State
const initialState = {
  data: {},
  loading: false,
  error: "",
};

// method to fetch the user from the database
// export const fetchUser = createAsyncThunk("user/fetchUser", async () => {
//   console.log("enter fetchuser");
//   const docRef = doc(db, "users", currentUser.uid);
//   try {
//     const querySnapshot = await getDoc(docRef);
//     // Handle the data or dispatch an action here
//     console.log("snapshot userslice", querySnapshot.data());
//     return querySnapshot.data();
//   } catch (error) {
//     // Handle the error or show an error message to the user
//     console.log("Error fetching document:", error);
//   }
// });

// method to Update User details

export const UserSlice = createSlice({
  // name
  name: "user",

  // Initial State
  initialState,

  // reducers
  // extraReducers: (builder) => {
  //   builder.addCase(fetchUser.pending, (state) => {
  //     state.loading = true;
  //   });
  //   builder.addCase(fetchUser.fulfilled, (state, action) => {
  //     state.loading = false;
  //     state.data = action.payload;
  //     state.error = "";
  //   });
  //   builder.addCase(fetchUser.rejected, (state, action) => {
  //     state.loading = true;
  //     state.data = [];
  //     state.error = action.error.message;
  //   });
  // },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setData, setLoading } = UserSlice.actions;
export default UserSlice.reducer;
