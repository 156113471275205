import React from "react";
import image from "../../assests/webapp/gallery-image.png";

const ProjectGallery = ({ images }) => {
  // console.log("images", images);
  return (
    <div className="mb-[30px]">
      {/* gallery */}
      <div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-[5px] gap-y-[20px]">
        {/* image */}
        {images?.map((data, index) => {
          return (
            <div className=" cursor-pointer ">
              <img className="w-full h-full object-fit" src={data} alt=""></img>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProjectGallery;
