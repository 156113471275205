import React, { useState } from "react";

// assests
import profile from "../../assests/webapp/profile-settings.svg";
import profileG from "../../assests/webapp/profile-settings-green.svg";
import preferences from "../../assests/webapp/preferences-settings.svg";
import preferencesG from "../../assests/webapp/preferences-settings-green.svg";
import security from "../../assests/webapp/security-settings.svg";
import securityG from "../../assests/webapp/security-settings-green.svg";

// components
import AppHeadingNav from "../../components/webapp/AppHeadingNav";
import SettingsSideComp from "../../components/webapp/SettingsSideComp";
import PersonalInfo from "../../components/webapp/PersonalInfo";
import Security from "../../components/webapp/Security";
import Preferences from "../../components/webapp/Preferences";
import { Navigate, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Settings = ({ theme }) => {
  const { t } = useTranslation();
  const [navlink, setNavlink] = useState(t("appHeader.personalInfo"));
  console.log("navlink", navlink);

  return (
    <div className="">
      {/* nav */}
      <AppHeadingNav
        left={t("appHeader.settings")}
        right={navlink}
        date={false}
        arrow={false}
        theme={theme}
      />

      {/* main */}
      <div className=" grid grid-cols-1 lg:grid-cols-7 gap-[25px]">
        {/* left */}
        <div className="drop-shadow-md  w-[50%]  lg:w-full hidden md:flex bg-white rounded-[16px] col-span-2 p-[24px] flex-col gap-[12px]">
          {/* side comp */}
          <SettingsSideComp
            imgOne={profile}
            imgTwo={profileG}
            label={t("appHeader.personalInfo")}
            link={"../settings/personal-info"}
            setNavlink={setNavlink}
          />
          {/* side comp */}
          {/* <SettingsSideComp
            imgOne={preferences}
            imgTwo={preferencesG}
            label={"Preferences"}
            link={"../settings/preferences"}
            setNavlink={setNavlink}
          /> */}

          {/* side comp */}
          <SettingsSideComp
            imgOne={security}
            imgTwo={securityG}
            label={t("appHeader.security")}
            link={"../settings/security"}
          />
        </div>

        {/* right */}
        <div className="drop-shadow-md  lg:mx-0 w-full md:w-[80%] lg:w-full   bg-white rounded-[16px] col-span-5 px-[12px] py-[24px] md:px-[24px]">
          <Routes>
            <Route index element={<Navigate to="personal-info" replace />} />
            <Route
              path="personal-info"
              element={<PersonalInfo setNavlink={setNavlink} theme={theme} />}
            />
            <Route
              path="security"
              element={<Security setNavlink={setNavlink} />}
            />
            <Route
              path="preferences"
              element={<Preferences setNavlink={setNavlink} />}
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Settings;
