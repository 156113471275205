import React, { useState } from "react";

// import image from "../../assests/website/how-pap-works-img.png";
import image from "../../assests/website/hero-image2.png";
import reward from "../../assests/webapp/reward.png";
import curvy1 from "../../assests/website/home-back-svg.svg";
import curvy2 from "../../assests/website/search-curve-svg.svg";
import drought from "../../assests/website/drought.png";
import curveDown from "../../assests/website/home-curve.svg";
import circle from "../../assests/website/circle.svg";
import dot from "../../assests/website/rec-dot.svg";
import use from "../../assests/website/use.svg";
import curveWhite from "../../assests/website/curve-bottom-white.svg";
import curveLight from "../../assests/website/inverted-curve-white.svg";
import curveDark from "../../assests/website/inverted-curve-dark.svg";
import loader from "../../assests/webapp/loader-svg.gif";

import LeafHeader from "../../components/website/LeafHeader";
import WebsiteButton from "../../components/website/WebsiteButton";
import InputComp from "../../components/webapp/InputComp";
import AddToChromeBtn from "../../components/website/AddToChromeBtn";
import WebsiteInputComp from "../../components/website/WebsiteInputComp";
import WebsiteTextAreaComp from "../../components/website/WebsiteTextAreaComp";
import PlayStoreAppStore from "../../components/website/PlayStoreAppStore";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { useTranslation } from "react-i18next";
import HandleLanguageChange from "../../globals/handleLanguageChange";
import AddToPlayStore from "../../components/website/AddToPlayStore";

const HowPapWorks = ({ theme }) => {
  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };
  const navigate = useNavigate("");

  const [mailName, setMailName] = useState("");
  const [mailAddress, setMailAddress] = useState("");
  const [mailBody, setMailBody] = useState("");
  const [mailSubject, setMailSubject] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  // handle language change
  HandleLanguageChange();

  const sendMail = async () => {
    if (mailName === "" || mailAddress === "" || mailBody === "") {
      toast.info("All fields must be filled");
      return;
    }

    setLoading(true);

    const colRef = collection(db, "mail");
    await addDoc(colRef, {
      from: "PAP Earth<info@pap.earth>", //`${mailName}<${mailAddress.toLowerCase()}>`,
      to: "info@pap.earth", //["fela@pap.earth", "yewande@pap.earth"],
      message: {
        subject: mailSubject, //`New Mail From PAP Website: ${mailName}`,
        html: `Name: ${mailName} <br><br>Email Address: ${mailAddress.toLowerCase()} <br><br>Message: ${mailBody}`,
      },
      replyTo: `${mailName}<${mailAddress.toLowerCase()}>`,
    });
    toast.success("Email sent successfully");
    setMailAddress("");
    setMailSubject("");
    setMailBody("");
    setMailName("");
    setLoading(false);
    window.location.href = window.location.href;
  };

  return (
    <div
      className={theme ? " relative text-[#1A1C1E]" : " relative text-[white]"}
    >
      {/* absolute */}
      <div className="hidden md:block absolute left-0 top-0 z-20">
        <img src={curvy2} alt=""></img>
      </div>

      {/* absolute */}
      <div className="hidden md:block absolute right-0 z-20 top-[150px]">
        <img src={curvy1} alt=""></img>
      </div>
      {/* section 1 */}
      <div
        className={
          theme
            ? "relative pt-[80px] lg:pt-[100px] pb-[300px] bg-[#F7F7F7]"
            : "relative pt-[80px] lg:pt-[100px] pb-[300px] bg-[#1a1c1e]"
        }
      >
        <div className=" max-w-[1200px] mx-[24px]  xl:mx-auto  relative">
          {" "}
          {/* header */}
          <p className=" max-w-[994px] text-[28px] md:text-[48px] leading-[47px] md:leading-[59px] font-extrabold mb-[33px] text-center mx-auto">
            {t("about.header")}
          </p>
          {/* button with pic */}
          <div className="w-full sm:w-[357px] mx-auto mb-[70px]">
            <AddToChromeBtn />
            <AddToPlayStore />
            {/* <PlayStoreAppStore /> */}
          </div>
          {/* image */}
        </div>
      </div>

      <div className=" max-w-[1200px] mx-[24px]  xl:mx-auto  relative">
        <div className=" mx-auto mt-[-320px] lg:mt-[-250px] w-[90%] lg:w-fit  relative z-20">
          <img className="rounded-br-[50px] w-full" src={image} alt=""></img>
          {/* absolute */}
          <div className="absolute w-[55px] md:w-fit bottom-[-20px] md:bottom-[-50px] right-[-20px] md:right-[-50px]">
            <img className="w-full" src={circle} alt=""></img>
          </div>
        </div>
      </div>

      {/* section 2*/}
      <div className=" mt-[100px] max-w-[1200px] mx-[24px]  xl:mx-auto  relative mb-[100px] md:mb-[150px]">
        {/* header */}
        <div className="w-fit mx-auto mb-[8px]">
          <LeafHeader label={t("about.aboutUs.header")} />
        </div>

        {/* body */}
        <div className="max-w-[1000px] mx-auto flex flex-col gap-[15px]">
          <p className="text-sm md:text-[18px] leading-9 text-center font-normal ">
            {t("about.aboutUs.words")}
          </p>
        </div>
      </div>

      {/* section 3 */}
      <div
        className={
          theme
            ? "bg-[#F7F7F7] relative pt-[80px] lg:pt-[100px] pb-[300px]"
            : " relative pt-[80px] lg:pt-[100px] pb-[300px] bg-[#1a1c1e]"
        }
      >
        <div className=" max-w-[1200px] mx-[24px]  xl:mx-auto  relative ">
          {/* header */}
          <p className="text-[28px] md:text-[42px]  font-semibold leading-[29px] md:leading-[57px] text-center mb-[11px]">
            {t("about.theProblem.header")}
          </p>

          {/* words */}
          <div className="">
            <p className="text-sm md:text-[18px] leading-9 max-w-[1000px] mx-auto text-center font-normal ">
              {t("about.theProblem.wordsOne")}
            </p>
          </div>
        </div>
      </div>

      {/* mid sec3 */}
      <div className=" max-w-[1200px] mx-[24px]  xl:mx-auto  relative mt-[-300px]">
        {/* image */}
        <div className="">
          <img className="mx-auto " src={drought} alt=""></img>
        </div>

        {/* words */}
        <div className="mt-[30px] md:mt-[0] mb-[60px]">
          {/* header */}
          <p className="text-[28px] md:text-[42px]  font-semibold leading-[29px] md:leading-[57px] text-center mb-[11px]">
            {t("about.theProblem.headerTwo")}
          </p>
          <p className="text-sm md:text-[18px] leading-9 max-w-[1000px] mx-auto text-center font-normal ">
            {t("about.theProblem.wordsTwo")}
          </p>
        </div>

        {/* head */}
        <div className="mb-[30px] w-fit mx-auto">
          <div className="">
            <img src="" alt=""></img>
          </div>

          <div className="relative ">
            {/* images */}
            <div className="absolute top-[-10px] left-[-23px] ">
              <img src={use} alt=""></img>
            </div>
            <p className="bobly-one text-[28px] md:text-[40px] font-extrabold leading-[40px] md:leading-[54px]">
              PAP.Earth
            </p>
          </div>
        </div>

        {/* button */}
        <div
          onClick={() => {
            // navigate("#contact");
            window.location.href = `${window.location.href.replace(
              "#contact",
              ""
            )}#contact`;
          }}
          className="w-full sm:w-[245px] mx-auto cursor-pointer"
        >
          <WebsiteButton label={t("about.theProblem.button")} />
        </div>
      </div>

      {/* section 4 */}
      <div className=" mt-[100px]">
        <div className="grid grid-cols-1 lg:grid-cols-2 items-center max-w-[1200px] mx-[24px]  xl:mx-auto  relative gap-y-[50px]">
          {/* left */}
          <div className="">
            {/* leaf header */}
            <div className=" w-fit mx-auto lg:mx-0 mb-[20px]">
              <LeafHeader label={t("about.earnRewards.header")} />
            </div>

            {/* words */}
            <div className="flex flex-col gap-[18px] mb-[35px]">
              <p className="text-sm md:text-lg leading-[28px] text-center lg:text-start font-normal ">
                {t("about.earnRewards.wordsOne")}
              </p>

              <p className="text-sm md:text-lg leading-[28px] text-center lg:text-start font-normal ">
                {t("about.earnRewards.wordsTwo")}
              </p>
            </div>

            {/* button */}
            <div
              onClick={() => {
                navigate("/pap-rewards");
                moveToTop();
              }}
              className="w-full sm:w-[245px]  mx-auto lg:mx-0"
            >
              <WebsiteButton label={t("about.earnRewards.button")} />
            </div>
          </div>

          {/* right */}
          <div className=" flex  justify-end ">
            <div className=" relative  w-fit mx-auto lg:mx-0">
              <div className=" ">
                <img className="" src={reward} alt=""></img>
              </div>
              {/* absolute */}
              <div className="absolute w-[94px] md:w-fit top-[40px] left-[40px] md:top-[0px] md:left-[0px]">
                <img className="w-full" src={circle} alt=""></img>
              </div>

              {/* absolute */}
              <div className="absolute  w-fit bottom-[0px] right-[40px] ">
                <img className="w-full" src={dot} alt=""></img>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* section last */}

      {/* curve top */}
      <div className="rotate-180 w-full mt-[150px]" id="contact">
        {theme ? (
          <img className="w-full" src={curveWhite} alt=""></img>
        ) : (
          <img className="w-full" src={curveDown} alt=""></img>
        )}
      </div>
      <div
        className={
          theme
            ? "bg-[#F7F7F7] pt-[50px] pb-[200px]  "
            : " bg-[#1a1c1e] pt-[50px]  pb-[200px] "
        }
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 max-w-[1200px] mx-[24px] gap-y-[34px]  xl:mx-auto  relative ">
          {/* left */}
          <div className="">
            <div className="max-w-[433px] mx-auto lg:mx-0">
              {/* leaf */}
              <div className=" text-center lg:text-start">
                <LeafHeader label={t("about.letStayConnected.header")} />
              </div>

              <p className="mt-[21px] text-center lg:text-start text-sm md:text-lg font-normal ">
                {t("about.letStayConnected.words")}
              </p>
            </div>
          </div>

          {/* right */}
          <div
            className={
              theme
                ? " bg-[white] py-[40px] px-[15px] md:p-[40px] rounded-[6px] w-full max-w-[700px]  mx-auto "
                : "  bg-[#292929] py-[40px] px-[15px] md:p-[40px] rounded-[6px] w-full max-w-[700px]  mx-auto"
            }
          >
            <div className="flex flex-col gap-[30px]">
              {/* your name */}
              <WebsiteInputComp
                label={t("about.letStayConnected.contactUs.name")}
                theme={theme}
                setValue={setMailName}
              />

              {/* your email */}
              <WebsiteInputComp
                label={t("about.letStayConnected.contactUs.email")}
                theme={theme}
                setValue={setMailAddress}
              />

              {/* your subject */}
              <WebsiteInputComp
                label={t("about.letStayConnected.contactUs.subject")}
                theme={theme}
                setValue={setMailSubject}
              />

              {/* mesage */}
              <WebsiteTextAreaComp
                label={t("about.letStayConnected.contactUs.message")}
                theme={theme}
                setValue={setMailBody}
              />
              {loading ? (
                <div className=" h-[40px] w-full flex justify-center items-center ">
                  <div className=" w-[40px] ">
                    <img className="w-full" src={loader} alt=""></img>
                  </div>
                </div>
              ) : (
                <div
                  className="w-full mx-auto sm:w-[288px] lg:w-full cursor-pointer "
                  onClick={() => {
                    sendMail();
                  }}
                >
                  <WebsiteButton
                    label={t("about.letStayConnected.contactUs.button")}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* curve bottom */}
      <div className="w-full mb-[100px] md:mb-[150px] mt-[-20px] xs:mt-[-20px] sm:mt-[-50px] md:mt-[-60px] lg:mt-[-70px] 2xl:mt-[-120px]   scale-y-[-1]">
        {theme ? (
          <img className="w-full" src={curveLight} alt=""></img>
        ) : (
          <img className="w-full" src={curveDark} alt=""></img>
        )}
      </div>
    </div>
  );
};

export default HowPapWorks;
