import React, { useEffect } from "react";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import Dashboard from "../../pages/webapp/Dashboard";
import Notification from "../../pages/webapp/Notification";
import PapRewards from "../../pages/webapp/PapRewards";
import { Route, Routes } from "react-router-dom";
import Projects from "../../pages/webapp/Projects";
import Settings from "../../pages/webapp/Settings";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { auth, db } from "../../firebase/firebaseConfig";
import {
  collection,
  getDocs,
  getDoc,
  orderBy,
  query,
  where,
  doc,
} from "firebase/firestore";

import { setData, setLoading } from "../../redux/UserSlice";

const MainContainer = ({ theme }) => {
  // fetching all slices
  const dispatch = useDispatch();
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const fetchUser = async () => {
    dispatch(setLoading(true));

    const docRef = doc(db, "users", currentUser.uid);
    try {
      const querySnapshot = await getDoc(docRef);
      // Handle the data or dispatch an action here

      dispatch(setData(querySnapshot.data()));
      dispatch(setLoading(false));
    } catch (error) {
      // Handle the error or show an error message to the user
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);
  return (
    <div
      className={
        theme
          ? "bg-[white] relative  h-full min-h-screen"
          : "bg-[#F4F4F7] relative  h-full min-h-screen"
      }
    >
      <AppHeader theme={theme} />
      {/* main contents */}
      <div className=" max-w-[1200px] mx-[24px]  xl:mx-auto   mt-[-240px] z-20 relative">
        <Routes>
          <Route path="/" element={<Dashboard theme={theme} />} />
          <Route path="notification" element={<Notification theme={theme} />} />
          {/* <Route path="/pap-rewards" element={<PapRewards />} /> */}
          <Route path="projects/*" element={<Projects theme={theme} />} />
          <Route path="settings/*" element={<Settings theme={theme} />} />
        </Routes>
        {/*  */}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>

      <AppFooter />
    </div>
  );
};

export default MainContainer;
