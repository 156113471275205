import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateTheme } from "../../redux/ThemeSlice";
import { useTranslation } from "react-i18next";

const PreferenceNav = ({ label, active, setMode, setThemeType }) => {
  const { t } = useTranslation();
  return (
    <div
      onClick={() => {
        if (label == t("settings.theme.light")) {
          setMode(t("settings.theme.light"));
          setThemeType("light");
          // dispatch(updateTheme(true));
          // localStorage.setItem("theme", true);
        } else if (label == t("settings.theme.dark")) {
          setMode(t("settings.theme.dark"));
          setThemeType("dark");
          // dispatch(updateTheme(false));
          // localStorage.setItem("theme", false);
        } else {
          // localStorage.setItem("theme", true);
          setMode(t("settings.theme.default"));
          setThemeType("default");
        }
      }}
      className={
        active
          ? "px-[24px] py-[15px] border-b-[2px] border-[#0B7026] text-[#137C42]"
          : " px-[24px] py-[15px] text-[#A2A6AA] cursor-pointer"
      }
    >
      <p className="text-base font-medium">{label}</p>
    </div>
  );
};

export default PreferenceNav;
