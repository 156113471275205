import React from "react";
// assests
import pic from "../../assests/website/web-gallery.png";
import bar from "../../assests/website/project-bar.png";
import circle from "../../assests/website/circle.svg";
import dot from "../../assests/website/rec-dot.svg";
import gradient from "../../assests/website/gradient-black.svg";
import coins from "../../assests/webapp/coins-small.svg";

import treeplanting1 from "../../assests/website/treeplanting/1.jpg";
import treeplanting2 from "../../assests/website/treeplanting/2.jpg";

import oceanplastics1 from "../../assests/website/oceanplastics/1.jpg";
import oceanplastics2 from "../../assests/website/oceanplastics/2.jpg";

import cleanenergy1 from "../../assests/website/cleanenergy/1.jpg";
import cleanenergy2 from "../../assests/website/cleanenergy/2.jpg";

import housing1 from "../../assests/website/housing/1.jpg";
import housing2 from "../../assests/website/housing/2.jpg";

import image1 from "../../assests/website/firstsiximages/cookinstove.jpg";
import image2 from "../../assests/website/firstsiximages/image-1.jpg";
import image3 from "../../assests/website/firstsiximages/micro-hydro.jpg";
import image4 from "../../assests/website/firstsiximages/portable-power.jpg";
import image5 from "../../assests/website/firstsiximages/rural-solar.jpg";
import image6 from "../../assests/website/firstsiximages/wind-energy.jpg";

// imports
import WebsiteButton from "./WebsiteButton";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { addCommas } from "separate-comma-numbers";
import { useTranslation } from "react-i18next";

const ProjectComp = ({
  index,
  theme,
  title,
  logo,
  id,
  description,
  total_coins,
  project_goal,
  location,
  gallery,
  category,
}) => {
  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = JSON.parse(localStorage.getItem("language"));

  const getImageByCategory = () => {
    // console.log(title);
    const sixImages = {
      EVChaja: image2,
      "Ethanol Cookstove for sustainable cooking": image1,
      "Rural Solar  Energy Project": image5,
      "Portable solar powered irrigation systems ": image4,
      HuluBeje: image3,
      "Mpepu Micro-grid Project ": image6,
    };
    if (sixImages[title] !== undefined) {
      return sixImages[title];
    }
    if (category === "Tree planting") {
      return (Number(`${index}`) + 10) % 2 === 0
        ? treeplanting1
        : treeplanting2;
    }
    if (category === "Ocean plastics") {
      return (Number(`${index}`) + 10) % 2 === 0
        ? oceanplastics1
        : oceanplastics2;
    }
    if (category === "Clean Energy") {
      return (Number(`${index}`) + 10) % 2 === 0 ? cleanenergy1 : cleanenergy2;
    }
    if (category === "Housing") {
      return (Number(`${index}`) + 10) % 2 === 0 ? housing1 : housing2;
    }
    return treeplanting1;
  };

  return (
    <div className={theme ? "text-[#1A1C1E]" : " text-white"}>
      {/* image  */}
      <div className=" relative mb-[45px] w-full">
        <div className="absolute w-[63px] md:w-[79px] left-[-20px] top-[-10px] sm:top-[-30px]">
          <img src={circle} alt=""></img>
        </div>
        <div className="absolute w-[72px] md:w-[92px] bottom-[-20px] right-[-15px] z-20">
          <img src={dot} alt=""></img>
        </div>
        <div className=" w-full">
          {
            <img
              className="object-cover w-full h-[350px]"
              src={getImageByCategory()}
              alt=""
            ></img>
          }
          {/* {logo !== "" ? (
            <img
              className="object-cover w-full h-[350px]"
              src={logo}
              alt=""
            ></img>
          ) : gallery.length > 0 ? (
            <img
              className="object-cover w-full h-[350px]"
              src={gallery[0]}
              alt=""
            ></img>
          ) : (
            // <div className="bg-[gray] w-full h-[350px]"></div>
            <img
              className="object-cover w-full h-[350px]"
              src={getImageByCategory()}
              alt=""
            ></img>
          )} */}
        </div>
        {/* tag */}
        <div className=" absolute top-[30px] right-[30px] p-[5px] w-fit bg-[#2CD85D] rounded-[8px] text-white font-bold text-[15px] leading-[22px]">
          <p>{location}</p>
        </div>

        {/* project name */}
        <div className=" absolute bottom-[20%] left-[5%] z-20">
          <div className="flex items-center gap-[8px]">
            <p className="text-white text-[9px] sm:text-[11px] font-bold ">
              {t("projectDetails.projectTitle")}
            </p>
            <div className="bg-[white] w-[60px] h-[1px]"></div>
          </div>
          <p className="text-white text-[22px] sm:text-[24px] font-semibold leading-[27px] mb-[16px]">
            {title.length <= 50 ? title : `${title.substring(0, 50)}...`}
          </p>
        </div>

        {/* gradient */}
        <div className=" absolute bottom-0  w-full ">
          <img className=" w-full" src={gradient} alt=""></img>
        </div>
      </div>

      <div className="">
        {/* paragrapghs */}
        <p className="  h-[120px] sm:h-[74px]    text-start text-sm  leading-[28px] md:text-base font-normal  mb-[5px]">
          {description.length <= 110
            ? description
            : lang?.value === "ch"
            ? `${description.substring(0, 60)}...`
            : `${description.substring(0, 110)}...`}
        </p>

        <p className=" text-start text-[18px] font-bold md:leading-[40px] mb-[16px]">
          {t("projectDetails.projectGoal")}:{addCommas(project_goal)}
        </p>

        {/* image */}
        <div className="max-w-[288px] sm:w-[288px] mx-0 mb-[16px]">
          {/* up */}
          <div
            className={
              theme
                ? "relative border-[2px] sm:border-[3px] border-[black] sm:py-[5px] mb-[3px] text-sm sm:text-base font-bold rounded-[25px] flex items-center justify-center w-full"
                : "relative border-[2px] sm:border-[3px] border-[white] sm:py-[5px] mb-[3px] text-sm sm:text-base font-bold rounded-[25px] flex items-center justify-center w-full"
            }
          >
            <p className="relative z-20">{addCommas(total_coins)}</p>
            {/* image */}
            <div className="relative z-20">
              <img src={coins} alt=""></img>
            </div>
            {/* green back */}
            <div
              // prettier-ignore
              className={`absolute bg-[#58BA59] h-full left-0 rounded-[25px] w-[${(total_coins / project_goal) * 100}%]`}
              style={{ width: `${(total_coins / project_goal) * 100}%` }}
            ></div>
          </div>

          {/* down */}
          <p className="text-xs sm:text-base font-semibold text-[#888]">
            {Number((total_coins / project_goal) * 100).toFixed(2)}%
          </p>
        </div>

        {/* button */}
        <div
          onClick={() => {
            navigate(`/project-details/${id}`);
            moveToTop();
          }}
          className="mx-0 max-w-[288px] md:w-[288px] cursor-pointer"
        >
          <WebsiteButton label={t("projectDetails.view")} />
        </div>
      </div>
    </div>
  );
};

export default ProjectComp;
