import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";

const WebNavCompChildren = ({ label, close, theme, chilDren }) => {
  const [link, setLink] = useState();
  const active = false;
  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };
  const navigate = useNavigate();

  // useEffect(() => {}, [link]);
  return (
    <div>
      <div
        className={
          active
            ? theme
              ? "webnav-bg-white curor-pointer rounded-[8px] px-[16px] py-[12px] text-[#1A1C1E]"
              : "webnav-bg curor-pointer rounded-[8px] px-[16px] py-[12px] text-[white] "
            : theme
            ? "text-[#1A1C1E] px-[16px] cursor-pointer py-[12px] "
            : "px-[16px] cursor-pointer py-[12px] text-[#7C7C7C]"
        }
      >
        <p className="text-base font-medium">{label}</p>
      </div>
      {/* children */}
      {chilDren.map((data) => (
        <NavLink to={data.link}>
          {({ isActive }) => (
            <div
              onClick={() => {
                close(false);
                moveToTop();
              }}
              className={
                isActive
                  ? theme
                    ? "webnav-bg-white curor-pointer rounded-[8px] ml-[32px] px-[16px] py-[12px] text-[#1A1C1E]"
                    : "webnav-bg curor-pointer rounded-[8px] px-[16px] ml-[32px] py-[12px] text-[white] "
                  : theme
                  ? "text-[#1A1C1E] px-[16px] cursor-pointer ml-[32px] py-[12px] "
                  : "px-[16px] cursor-pointer py-[12px] ml-[32px] text-[#7C7C7C]"
              }
            >
              <p className="text-base font-medium">{data.name}</p>
            </div>
          )}
        </NavLink>
      ))}
    </div>
  );
};

export default WebNavCompChildren;
