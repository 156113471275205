// import config from "../config";
const { GoogleGenerativeAI } = require("@google/generative-ai");

// Access your API key as an environment variable (see "Set up your API key" above)
const genAI = new GoogleGenerativeAI("AIzaSyA_-5UPcoL6wsPZITxGCkhkGjKc7_QAFj4");

export const geminiAiSearch = async (prompt) => {
  // For text-only input, use the gemini-pro model
  const model = genAI.getGenerativeModel({ model: "gemini-pro" }); //gemini-1.5-pro-latest

  // const prompt = "Write a story about a magic backpack.";

  const result = await model.generateContent(`${prompt}`); // return answer in html
  const response = result.response;
  const text = response.text();
  // convert text to html using github markdown API

  const githubAccessToken =
    "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";
  const githubMarkdownAPiURL = "https://api.github.com/markdown";
  const githubMarkdownAPiHeaders = {
    Accept: "application/vnd.github+json",
    Authorization: `Bearer ${githubAccessToken}`,
    "X-GitHub-Api-Version": "2022-11-28",
  };
  const githubMarkdownAPiBody = JSON.stringify({ text: text });

  const resp = await fetch(githubMarkdownAPiURL, {
    headers: githubMarkdownAPiHeaders,
    body: githubMarkdownAPiBody,
    method: "POST",
  });
  const markdownToHTML = await resp.text();
  // console.log("markdown response", markdownToHTML)
  // console.log("gemini response", text);
  return markdownToHTML;
};
