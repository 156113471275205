import React from "react";
import LeafHeader from "./LeafHeader";

const PrivacyPolComp = ({ header, words }) => {
  return (
    <div className="">
      {/* header */}
      <LeafHeader label={header} NoCenter={true} />

      <p className="text-sm md:text-lg font-normal leading-[28px] md:leading-[33px] mt-[11px] md:mt-[9]">
        {words}
      </p>
    </div>
  );
};

export default PrivacyPolComp;
