import React, { useEffect } from "react";
import image from "../../assests/website/cleanenergy/1.jpg";
import SearchVideoComp from "./SearchVideoComp";

const AllVideos = ({ theme, items }) => {
  useEffect(() => {
    localStorage.setItem("mount", "true");
  }, []);
  return (
    <div
      className={
        theme
          ? " text-[#1a1c1e] grid grid-cols-12 mb-[100px]"
          : "text-white grid grid-cols-12 mb-[100px]"
      }
    >
      <div className=" col-span-12 md:col-span-9 lg:col-span-6  grid gap-[30px]  w-full ">
        {/* videos comp */}
        {items.map((item, index) => (
          <SearchVideoComp image={image} theme={theme} item={item} />
        ))}

        {/* videos comp */}
        {/* <SearchVideoComp image={image} theme={theme} /> */}
      </div>
      <div className="col-span-0 md:col-span-3 lg:col-span-6 "></div>
    </div>
  );
};

export default AllVideos;
