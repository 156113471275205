import React from "react";

const CircleNoCurrent = ({ current }) => {
  return (
    <div
      className={
        current
          ? "circle-current bg-[#137C42] h-[11px] w-[59px] rounded-[923px] "
          : " bg-[#B1C6BA] h-[11px] rounded-[24px] w-[11px]"
      }
    ></div>
  );
};

export default CircleNoCurrent;
