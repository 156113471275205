import React from "react";

const AdsResultComp = ({ result, theme }) => {
  return (
    <div className="flex flex-col gap-[5px] max-w-[600px] " style={{"margin-bottom": "20px"}}>
      {/* link */}
      <div className=" flex items-center gap-[12px]">
        <span className="ast">AD</span>
        <div>
          <p className="text-[14px] font-normal"></p>
          <p className="text-[12px] leading-[15px] font-normal">
            <a class="m_ o_ styleable-visurl a" data-lines="1" data-truncate="0" data-nb="1" data-set-target="1" href={result.link}>{result.displayUrl}</a>
          </p>
        </div>
      </div>

      {/* name */}
      <p
        className={
          theme
            ? "text-[#1a0dab] text-[20px] leading-[24px] font-medium result-comp"
            : "text-[20px] leading-[24px] font-medium text-[#8ab4f8] result-comp"
        }
      >
        <a class="p_ styleable-title a" data-nb="0" data-set-target="1" href={result.link}>{result.title}</a>
      </p>

      {/* details */}
      <p className="text-[14px] leading-[22px] font-normal">{result.snippet}</p>
    </div>
  );
};

export default AdsResultComp;
