import React, { useState } from "react";

const ButtonToggle = ({ setValue, value }) => {
  const [state, setState] = useState(false);
  return (
    <div
      className={
        value
          ? "border-2 border-[#0B7026] p-[4px] rounded-[18px] w-[40px] cursor-pointer"
          : "border-2 border-[#ACB5BB] p-[4px] rounded-[18px] w-[40px] cursor-pointer"
      }
      onClick={() => {
        value ? setValue(false) : setValue(true);
      }}
    >
      {/* circle */}
      <div
        className={
          value
            ? "circle-transition2 bg-[#0B7026] w-[16px] h-[16px] rounded-[100%]"
            : "circle-transition bg-[#ACB5BB] w-[16px] h-[16px] rounded-[100%]"
        }
      ></div>
    </div>
  );
};

export default ButtonToggle;
