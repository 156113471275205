import React, { useEffect } from "react";
import image from "../../assests/website/cleanenergy/1.jpg";
import SearchNewsComp from "./SearchNewsComp";
import ResultComp from "./ResultComp";

const AllNews = ({ theme, items }) => {
  useEffect(() => {
    localStorage.setItem("mount", "true");
  }, []);
  // console.log("items", items);
  return (
    <div
      className={
        theme
          ? "text-[#1A1C1E] grid grid-cols-12 mb-[100px]"
          : "text-white grid grid-cols-12 mb-[100px]"
      }
    >
      {/* left */}
      <div className="col-span-12 md:col-span-9 lg:col-span-6 gap-[30px] grid">
        {/* news comp */}
        {items.map((item, index) => (
          <ResultComp theme={theme} result={item} />
        ))}
        {/* news comp */}
        {/* <SearchNewsComp theme={theme} image={image} /> */}
      </div>
      {/* right */}
      <div className="col-span-0 md:col-span-3 lg:col-span-6"></div>
    </div>
  );
};

export default AllNews;
